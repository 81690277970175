import axios from "../../../../services/axios";
import actionCreator from "../../../../utils/action-creator";
/**
 * Types
 */
export const actionTypes = {
  FETCH_CHART_DATA: "@app/admin/dashboardAdmin/FETCH_CHART_DATA",
  FETCH_CHART_DATA_SUCCESS: "@app/admin/dashboardAdmin/FETCH_CHART_DATA_SUCCESS",
  FETCH_CHART_DATA_ERROR: "@app/admin/dashboardAdmin/FETCH_CHART_DATA_ERROR",

  FETCH_STATISTICS_DATA: "@app/admin/dashboardAdmin/FETCH_STATISTICS_DATA",
  FETCH_STATISTICS_DATA_SUCCESS: "@app/admin/dashboardAdmin/FETCH_STATISTICS_DATA_SUCCESS",
  FETCH_STATISTICS_DATA_ERROR: "@app/admin/dashboardAdmin/FETCH_STATISTICS_DATA_ERROR",
  FETCH_RECENT_ORDERS_DATA: "@app/admin/dashboardAdmin/FETCH_RECENT_ORDERS_DATA",
  FETCH_RECENT_ORDERS_DATA_SUCCESS: "@app/admin/dashboardAdmin/FETCH_RECENT_ORDERS_DATA_SUCCESS",
  FETCH_RECENT_ORDERS_DATA_ERROR: "@app/admin/dashboardAdmin/FETCH_RECENT_ORDERS_DATA_ERROR",
  /////


  FETCH_TOPCATEGORIES_DATA: "@app/admin/dashboardAdmin/FETCH_TOPCATEGORIES_DATA",
  FETCH_TOPCATEGORIES_DATA_SUCCESS: "@app/admin/dashboardAdmin/FETCH_TOPCATEGORIES_DATA_SUCCESS",
  FETCH_TOPCATEGORIES_DATA_ERROR: "@app/admin/dashboardAdmin/FETCH_TOPCATEGORIES_DATA_ERROR",
};

/**
 * Initial state
 */
const initialState = {
  chartError: null,
  isChartLoading: false,
  chartData: [],

  areStatisticsLoading: false,
  statisticsData: {},
  statisticsError: null,

  recentOrdersData: [],
  recentOrdersError: null,
  areRecentOrdersLoading: false,
  topCategories: [],
};

/**
 * Selectors
 */
export const selectors = {
  getChartData: (state) => state.app.admin.dashboardAdmin.chartData,
  getIsChartLoading: (state) => state.app.admin.dashboardAdmin.isChartLoading,
  getChartError: (state) => state.app.admin.dashboardAdmin.chartError,
  getStatisticsData: (state) => state.app.admin.dashboardAdmin.statisticsData,
  getStatisticsError: (state) => state.app.admin.dashboardAdmin.statisticsError,
  getAreStatisticsLoading: (state) => state.app.admin.dashboardAdmin.areStatisticsLoading,
  getRecentOrdersData: (state) => state.app.admin.dashboardAdmin.recentOrdersData,
  getAreRecentOrdersLoading: (state) => state.app.admin.dashboardAdmin.areRecentOrdersLoading,
  getRecentOrdersError: (state) => state.app.admin.dashboardAdmin.recentOrdersError,
  getTopCategories: (state) => state.app.admin.dashboardAdmin.topCategories,
};

/**
 * Reducer
 */
const {
  FETCH_CHART_DATA,
  FETCH_CHART_DATA_ERROR,
  FETCH_CHART_DATA_SUCCESS,

  FETCH_RECENT_ORDERS_DATA,
  FETCH_RECENT_ORDERS_DATA_ERROR,
  FETCH_RECENT_ORDERS_DATA_SUCCESS,

  FETCH_STATISTICS_DATA,
  FETCH_STATISTICS_DATA_ERROR,
  FETCH_STATISTICS_DATA_SUCCESS,

  FETCH_TOPCATEGORIES_DATA,
  FETCH_TOPCATEGORIES_DATA_SUCCESS,
  FETCH_TOPCATEGORIES_DATA_ERROR,

} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //chart
    case FETCH_CHART_DATA:
      return {
        ...state,
        isChartLoading: true,
      };
    case FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        isChartLoading: false,
        chartError: null,
        chartData: payload,
      };
    case FETCH_CHART_DATA_ERROR:
      return {
        ...state,
        isChartLoading: false,
        chartError: payload,
      };

    // recent orders
    case FETCH_RECENT_ORDERS_DATA:
      return {
        ...state,
        areRecentOrdersLoading: true,
        recentOrdersError: null,
      };
    case FETCH_RECENT_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        areRecentOrdersLoading: false,
        recentOrdersData: payload,
      };
    case FETCH_RECENT_ORDERS_DATA_ERROR:
      return {
        ...state,
        areRecentOrdersLoading: false,
        recentOrdersError: payload,
      };

    // statistics
    case FETCH_STATISTICS_DATA:
      return {
        ...state,
        areStatisticsLoading: true,
        statisticsError: null,
      };
    case FETCH_STATISTICS_DATA_SUCCESS:
      return {
        ...state,
        areStatisticsLoading: false,
        statisticsData: payload,
      };
    case FETCH_STATISTICS_DATA_ERROR:
      return {
        ...state,
        areStatisticsLoading: false,
        statisticsError: payload,
      };
      case FETCH_TOPCATEGORIES_DATA:
        return {
          ...state,
          topCategories: payload,
        };
        ///////
        case FETCH_TOPCATEGORIES_DATA_SUCCESS:
          return {
            ...state,
            topCategories: payload,
          };
        case FETCH_TOPCATEGORIES_DATA_ERROR:
          return {
            ...state,
            topCategories: [],
          };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  fetchChartData: function (type,year) {
    return async (dispatch) => {
      
      try {
        dispatch(actionCreator(FETCH_CHART_DATA));
        const response = await axios.get(`/sales/${type}ly/?${type}=${year}`);

        dispatch(actionCreator(FETCH_CHART_DATA_SUCCESS, response));
      } catch (err) {
        dispatch(actionCreator(FETCH_CHART_DATA_ERROR, err));
      }
    };
  },
  
  fetchRecentOrdersData: function () {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(FETCH_RECENT_ORDERS_DATA));
        const data = [
          {
            first_name: "Labinot",
            last_name: "Ahmeti",
            order_id: "453",
            location: "Muharrem Fejza, Mati 1, Royal B1, Prishtine",
            products: 12,
          },
          {
            first_name: "Endrit",
            last_name: "Bojku",
            order_id: "454",
            location: "Muharrem Fejza, Mati 1, Royal B1, Prishtine",
            products: 4,
          },
        ];
        setTimeout(() => {
          dispatch(actionCreator(FETCH_RECENT_ORDERS_DATA_SUCCESS, data));
        }, 200);
      } catch (err) {
        dispatch(actionCreator(FETCH_RECENT_ORDERS_DATA_ERROR, err.message));
      }
    };
  },
  fetchStatisticsData: function () {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(FETCH_RECENT_ORDERS_DATA));

       const response = await axios.get('/sales/daily/')

        dispatch(actionCreator(FETCH_STATISTICS_DATA_SUCCESS, response));
      } catch (err) {
        dispatch(actionCreator(FETCH_STATISTICS_DATA_ERROR, err.message));
      }
    };
  },
  fetchTopCategoriesData: function () {
    return async (dispatch) => {
      try {
        const response = await axios.get('/sales/top-categories/')
        dispatch(actionCreator(FETCH_TOPCATEGORIES_DATA_SUCCESS, response))
        
      } catch (err) {
        dispatch(actionCreator(FETCH_TOPCATEGORIES_DATA_ERROR, err.message))
      }
    };
  }
};
