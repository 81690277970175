import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from "../../../../../components/common/alerts/Toast";

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/categories/adminAddSubCategoryThunk/REQUEST",
    SUCCESS: "@app/admin/categories/adminAddSubCategoryThunk/SUCCESS",
    ERROR: "@app/admin/categories/adminAddSubCategoryThunk/ERROR",
    SET_SUBCATEGORIES: "@app/admin/categories/adminAddSubCategory/SET_SUBCATEGORIES",
    SET_CATEGORY_ID: "@app/admin/categories/adminAddSubCategory/SET_CATEGORY_ID",
    RESET: "@app/admin/categories/adminAddSubCategory/RESET"
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    subcategories: [],
    categoryId: -1,
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.categories.adminAddSubcategory.isLoading,
    getErrorMessage: state => state.app.admin.categories.adminAddSubcategory.errorMessage,
    getSubcategories: state => state.app.admin.categories.adminAddSubcategory.subcategories
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, SET_SUBCATEGORIES, SET_CATEGORY_ID, RESET } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        case SET_SUBCATEGORIES:
            return {
                ...state,
                subcategories: payload
            }
        case SET_CATEGORY_ID:
            return {
                ...state,
                categoryId: payload
            }
        case RESET:
            return initialState;
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    add: function (name) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));
                const { categoryId, subcategories } = getState().app.admin.categories.adminAddSubcategory;

                const res = await axios.put(`/shop/category/${categoryId}/`, { subcategory: [{ name }] });
                dispatch(actionCreator(SUCCESS));
                dispatch(actionCreator(SET_SUBCATEGORIES, [...res.subcategory]));
            } catch (err) {
                let message = "";
                let msgData = err?.response?.data

                if (msgData) {
                    message = msgData?.subcategory[0]?.message[0];
                }

                dispatch(actionCreator(ERROR, message || err.message));
                toast.error(message || err.message);
            };
        }
    },
    delete: function (id) {
        return async (dispatch, getState) => {
            try {
                const { subcategories } = getState().app.admin.categories.adminAddSubcategory;

                // dispatch(actionCreator(REQUEST));
                dispatch(actionCreator(SET_SUBCATEGORIES, subcategories.filter(el => el.id !== id)));

                await axios.delete(`/shop/subcategory/${id}/`);
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.errorMessage));
                toast.error(err.message);
            }
        }
    },
    setSubCategories: (payload) => actionCreator(SET_SUBCATEGORIES, payload),
    setCategoryId: id => actionCreator(SET_CATEGORY_ID, id),
    reset: () => actionCreator(RESET),
};

