import { Route, Redirect, useHistory } from "react-router-dom";

import { auth } from "../services/auth";
import { LOGIN_PATH } from "./constants";

import { getRole } from "./utility-functions";

const NotValidRole = () => {
  const history = useHistory();
  history.push("/not-found");
  return <span></span>
};


function AdminProtectedRoute({
  component: Component,
  path_if_no_auth = LOGIN_PATH,
  ...rest
}) {
  const canAccess = getRole(auth.getDecodedJWT()?.role) === "admin";

  return (
    <Route
      {...rest}
      render={(props) => {
        if (canAccess)
          return <Component {...props} />

        if (!auth.getDecodedJWT())
          return <Redirect to={{ pathname: path_if_no_auth, state: { from: props.location } }} />

        return <NotValidRole />
      }}
    />
  );
}

export default AdminProtectedRoute;
