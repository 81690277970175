import React from "react";
import CookieConsent from "react-cookie-consent";
import "./Cookies.scss";
import { useHistory } from "react-router-dom";

const Cookies = () => {
  const showCookies = localStorage.getItem("cookieConsent") !== "accepted";
  const history = useHistory();
  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
  };

  const handleMore = () => {
    history.push("/termat-dhe-kushtet");
  };

  return (
    <div className={`cookie-banner ${showCookies ? "popup-animation" : ""}`}>
      <CookieConsent
        // debug={true}
        enableDeclineButton
        style={{ backgroundColor: "red" }}
        onAccept={handleAccept}
        onDecline={handleMore}
        buttonText="Kuptoj!"
        declineButtonText="Mê shumë!"
        expires={90}
        buttonWrapperClasses="button_style"
      >
        <span className="cookie_text">
          Mirësevini në faqen tonë! Ne përdorim cookies për të përmirësuar
          përvojën tuaj të shfletimit dhe për t'ju ofruar përmbajtje dhe reklama
          të personalizuara. Duke vazhduar të përdorni faqen tonë, ju jepni
          konsentin tuaj për përdorimin e cookie-ve në përputhje me Politikën
          Tonë të Privatësisë
        </span>
      </CookieConsent>
    </div>
  );
};

export default Cookies;
