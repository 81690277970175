import { NavLink } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import { useState } from "react";

const NavLinkItem = ({
  to,
  text,
  currentPath,
  isActive,
  onClick,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClickItem = () => {
    if (onClick) {
      onClick(to);
      return;
    }
  };

  return (
    <div className={`NavLinkItem ${isActive && "NavLinkItem--focus"}`}>
      {props.children ? (
        props.children
      ) : (
          <div
            onMouseLeave={() => setIsHovered(false)}
            onMouseEnter={() => setIsHovered(true)}
            className="NavLinkItem__linkContainer"
            style={{ height: "100%" }}
          >
            <NavLink
              to={to}
              onClick={onClickItem}
              className={`NavLinkItem__text ${
                (isActive || isHovered) && "NavLinkItem__text--active"
                }`}
            >
              {text}
            </NavLink>

            {isActive || isHovered ? (
              <Zoom triggerOnce>
                <div
                  style={{
                    height: 2,
                    width: "100%",
                    background: "red",
                    marginTop: -2,
                  }}
                />
              </Zoom>
            ) : (
                <div style={{ height: 2, width: "100%", marginTop: -2 }} />
              )}
          </div>
        )}
    </div>
  );
};

export default NavLinkItem;
