import React from "react";

export const TOKEN_NAME = "@spv_online-order:token";
export const ROLE = "role";
export const CART_STORAGE_KEY = "@spv:products-cart";

// #public paths
export const HOME_PATH = "/";
export const PRODUCTS_PATH = "/produktet";
export const PRODUCTS_PATH_CATEGORY = "/produktet/:category";
export const PRODUCTS_PATH_SUB_CATEGORY = "/produktet/:category/:subcategory";
export const PRODUCT_DETAILS_PATH = "/produkt/:id";
export const CONTACT_PATH = "/kontakti";
export const ABOUT_US_PATH = "/per-ne";
export const NOT_FOUND_PATH = "/not-found";
export const PAYMENT_PATH = "/pagesa";
export const TERMS_AND_CONDITIONS_PATH = "/termat-dhe-kushtet"
export const SALES_PATH = "/zbritjet"
export const ADMIN_OFFER_DETAIL_PATH = "/:slug"
export const public_routes = [
    HOME_PATH,
    PRODUCTS_PATH,
    PRODUCTS_PATH_CATEGORY,
    PRODUCT_DETAILS_PATH,
    PRODUCTS_PATH_SUB_CATEGORY,
    CONTACT_PATH,
    ABOUT_US_PATH,
    NOT_FOUND_PATH,
    PAYMENT_PATH,
    TERMS_AND_CONDITIONS_PATH,
    SALES_PATH,
    ADMIN_OFFER_DETAIL_PATH 
];

/**
 * User protected route
 */
export const USER_BASE_PATH = "/user";
export const USER_DASHBOARD_PATH = "/user/pasqyra";
export const USER_ORDERS_PATH = "/user/porosite";
export const USER_ORDERS_PATH_URL = "/user/porosite/:paymentStatus?";
export const USER_PAYMENTS_PATH = "/user/pagesat";
export const USER_WISH_LIST_PATH = "/user/lista-e-deshirave";
export const USER_ADDRESSES_PATH = "/user/adresat";
export const USER_SETTINGS_PATH = "/user/preferencat";
export const user_routes = [
    USER_BASE_PATH,
    USER_DASHBOARD_PATH,
    USER_ORDERS_PATH,
    USER_PAYMENTS_PATH,
    USER_WISH_LIST_PATH,
    USER_ADDRESSES_PATH,
    USER_ORDERS_PATH_URL,
    USER_SETTINGS_PATH,
];

// auth routes
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGOT_PASSWORD_PATH = "/auth/forgot-password";
export const RESET_PASSWORD_PATH = "/auth/password_reset/";
export const auth_routes = [LOGIN_PATH, REGISTER_PATH, RESET_PASSWORD_PATH, FORGOT_PASSWORD_PATH];

/**
 * admin #protected-paths
 */
export const ADMIN_BASE_PATH = "/admin";
export const ADMIN_DASHBOARD_PATH = "/admin/informacione";
export const ADMIN_ORDERS_PATH = "/admin/porosite";
export const ADMIN_ORDER_DETAILS_PATH = "/admin/porosia/:order_id";
export const ADMIN_CUSTOMERS_PATH = "/admin/klientet";
export const ADMIN_PRODUCTS_PATH = "/admin/produktet";
export const ADMIN_CATEGORIES_PATH = "/admin/kategorite";
export const ADMIN_EDIT_CATEGORY_PATH = "/admin/kategori/:id";
export const ADMIN_ADD_CATEGORY_PATH = "/admin/kategorite/shto-kategori";
export const ADMIN_OFFERS_PATH = "/admin/offers";
export const ADMIN_ADD_OFFERS_PATH = "/admin/offers/add"
export const ADMIN_ADD_PRODUCT_PATH = "/admin/produktet/shto-produkt";
export const ADMIN_SETTINGS_PATH = "/admin/profili";
export const ADMIN_ALL_STAFF_PATH = "/admin/allStaff";
export const ADMIN_ADD_STAFF_PATH = "/admin/addStaff";
export const ADMIN_EDIT_STAFF_PATH = "/admin/editStaff/:id";
export const ADMIN_PRODUCT_DETAILS = "/admin/produktet/:product_id"
export const ADMIN_BANNER_PATH = "/admin/banner"
export const ADMIN_ADD_BANNER_PATH = "/admin/banner/add-banner"
export const ADMIN_EDIT_OFFER = "/admin/offers/:offerId"

export const admin_routes = [
    ADMIN_DASHBOARD_PATH,
    ADMIN_ORDERS_PATH,
    ADMIN_CUSTOMERS_PATH,
    ADMIN_PRODUCTS_PATH,
    ADMIN_CATEGORIES_PATH,
    ADMIN_OFFERS_PATH,
    ADMIN_ADD_OFFERS_PATH,
    ADMIN_ADD_PRODUCT_PATH,
    ADMIN_ORDER_DETAILS_PATH,
    ADMIN_BASE_PATH,
    ADMIN_SETTINGS_PATH,
    ADMIN_PRODUCT_DETAILS,
    ADMIN_ADD_CATEGORY_PATH,
    ADMIN_EDIT_CATEGORY_PATH,
    ADMIN_ALL_STAFF_PATH,
    ADMIN_ADD_STAFF_PATH,
    ADMIN_EDIT_STAFF_PATH,
    ADMIN_BANNER_PATH,
    ADMIN_ADD_BANNER_PATH,
    ADMIN_EDIT_OFFER
];

export const PICKER_ORDERS_PATH = "/picker/porosite";
export const PICKER_ORDER_DETAILS_PATH = "/picker/porosia/:order_id";
export const PICKER_SETTINGS_PATH = "/picker/profili";

export const picker_routes = [
    PICKER_ORDERS_PATH,
    PICKER_ORDER_DETAILS_PATH,
    PICKER_SETTINGS_PATH,
];

export const COURIER_ORDERS_PATH = "/courier/porosite";
export const COURIER_ORDER_DETAILS_PATH = "/courier/porosia/:order_id";
export const COURIER_SETTINGS_PATH = "/courier/profili";

export const courier_routes = [
    COURIER_ORDERS_PATH,
    COURIER_ORDER_DETAILS_PATH,
    COURIER_SETTINGS_PATH,
];

export const dashboardStatisticTypes = {
    ordersPending: "ordersPending",
    ordersCancel: "ordersCancel",
    ordersProcess: "ordersProcess",
    todayIncome: "todayIncome",
};

/**
 * payment methods
 */
export const payment_methods = {
    online: "online",
    installments: "pos",
    cash: "cash",
};


export const order_statuses = {
    accepted: "Pranuar",
    confirmed: "Konfirmuar",
    packed: "Paketuar",
    being_sent: "Duke u derguar",
    completed: "Perfunduar",
    canceled: "Anuluar",
};

export const order_statuses_array = [
    {label: "Pranuar", value: "Pranuar"},
    {label: "Paketuar", value: "Paketuar"},
    {label: "Duke u derguar", value: "Duke u derguar"},
    {label: "Perfunduar", value: "Perfunduar"},
    {label: "Anuluar", value: "Anuluar"},

];

export const user_order_statuses = {
    coming: "Duke ardhur",
    accepted: "E pranuar",
    sent: "E Derguar",
};

export const url_actions = {
    addToWishList: 'add-to-wishlist',
}

export const month_of_years = [
    {value: "January", label: "Janar"},
    {value: "February", label: "Shkurt"},
    {value: "March", label: "Mars"},
    {value: "April", label: "Prill"},
    {value: "May", label: "Maj"},
    {value: "June", label: "Qershor"},
    {value: "July", label: "Korrik"},
    {value: "August", label: "Gusht"},
    {value: "September", label: "Shtator"},
    {value: "October", label: "Tetor"},
    {value: "November", label: "Nentor"},
    {value: "December", label: "Dhjetor"},
];

export const past_five_years = Array.from({ length: 5 }, (_, index) => {
    const currentYear = new Date().getFullYear();
    return { value: currentYear - index, label: currentYear - index };
  });

export const sort_options = [
    {value: 'description', label: "Produktet (A-Z)"},
    {value: '-description', label: "Produktet(Z-A)"},
    {value: '-price',label: "Çmimi (I lartë > I ultë"},
    {value: 'price', label: "Çmimi (I ultë < I lartë"}
]
