import './style.scss';
import Input from '../common/Inputs/Input';
import TextError from '../common/TextError';
import Button from '../common/Button';

const RegisterUI = ({
    handleSubmit,
    onClickLogo,
    isLoading,
    onChangeInput,
    errors,
    onGoToLogin,
    onClickTerms,
    displayLogo = true
}) => {
    return (
        <div className="registerPage__card">
            {displayLogo && <h2 onClick={onClickLogo} className="registerPage__card__supervivaText">
                Super Viva
            </h2>}
            <form onSubmit={handleSubmit}>
                <div className="registerPage__card__row">
                    <div className="m-top-18">
                        <label className="label">Emri</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            name="firstName"
                            onChange={(e) => onChangeInput("firstName", e.target.value)}
                            type="text"
                            required
                        />
                        {errors?.firstName && (
                            <TextError
                                message={errors.firstName?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>
                    <div className="m-top-18">
                        <label className="label">Mbiemri</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            name="lastName"
                            onChange={(e) => onChangeInput("lastName", e.target.value)}
                            type="text"
                            required
                        />
                        {errors?.lastName && (
                            <TextError
                                message={errors.lastName?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>
                </div>

                <div className="registerPage__card__row">
                    <div className="m-top-16">
                        <label className="label">Email adresa</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            type="email"
                            name="email"
                            onChange={(e) => onChangeInput("email", e.target.value)}
                            required
                        />
                        {errors?.email && (
                            <TextError
                                message={errors.email?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>

                    <div className="m-top-16">
                        <label className="label">Numri i telefonit</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            name="phoneNumber"
                            onChange={(e) => onChangeInput("phoneNumber", e.target.value)}
                            required
                        />
                        {errors?.phoneNumber && (
                            <TextError
                                message={errors.phoneNumber?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>
                </div>
                <div className="registerPage__card__row">
                    <div className="m-top-16">
                        <label className="label">Fjalekalimi</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            type="password"
                            name="password"
                            onChange={(e) => onChangeInput("password", e.target.value)}
                            minLength={8}
                            required
                        />
                        {errors?.password && (
                            <TextError
                                message={errors.password?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>
                    <div className="m-top-16">
                        <label className="label">Konfirmo fjalekalimin</label>
                        <Input
                            disabled={isLoading}
                            style={{ width: 200, marginTop: 6 }}
                            type="password"
                            minLength={8}
                            name="confirmPassword"
                            onChange={(e) =>
                                onChangeInput("confirmPassword", e.target.value)
                            }
                            required
                        />
                        {errors?.confirmPassword && (
                            <TextError
                                message={errors.confirmPassword?.message}
                                className="m-top-3 m-left-8"
                            />
                        )}
                    </div>
                </div>

                <div className="registerPage__card__columnCenter m-top-20">
                    <Button
                        style={{ width: 200, maxWidth: '100%', fontSize: 12 }}
                        type="submit"
                        disabled={isLoading}
                        text="Regjistrohu"
                        isLoading={isLoading}
                    />
                    <div className='registerPage__card__bottom__texts'>
                        <h3
                            className="registerPage__card__terms__and__conditions"
                            onClick={onClickTerms}
                        >
                            Termet dhe Kushtet
                        </h3>

                        <h3 className="registerText m-top-15" onClick={onGoToLogin}>
                            kthehu te Kyqja
                        </h3>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RegisterUI;