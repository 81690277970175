import axios from "../../../../../services/axios";
import actionCreator from "../../../../../utils/action-creator";
import timeout from "../../../../../utils/async-timeout";

/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@app/user/settings/changePasswordThunk/REQUEST",
  SUCCESS: "@app/user/settings/changePasswordThunk/SUCCESS",
  ERROR: "@app/user/settings/changePasswordThunk/ERROR",
  RESET: "@app/user/settings/changePasswordThunk/RESET",
};
/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  status: 0,
};

/**
 * selectors
 */
export const selectors = {
  getIsLoading: (state) => state.app.user.settings.changePassword.isLoading,
  getError: (state) => state.app.user.settings.changePassword.errorMessage,
  getStatus: (state) => state.app.user.settings.changePassword.status,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 200,
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  requestChange: function ({ oldPassword, newPassword }) {
    return async (dispatch) => {
      dispatch(actionCreator(REQUEST));

      try {
        axios
          .put("/auth/me/change-password/", {
            old_password: oldPassword,
            new_password: newPassword,
          })
          .then((res) => {
            dispatch(actionCreator(SUCCESS));
          })
          .catch((err) => {
            if (err?.response?.data?.old_password) {
              dispatch(actionCreator(ERROR, err?.response?.data?.old_password[0]));
            }else if (err?.response?.data?.new_password) {
              dispatch(actionCreator(ERROR, err?.response?.data?.new_password[0]));

            }else{
              dispatch(actionCreator(ERROR, err.response.data.non_field_errors));
            }
          });
      } catch (err) {
        dispatch(actionCreator(ERROR, err.message));
      }
    };
  },
  reset: () => actionCreator(RESET),
};
