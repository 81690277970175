import actionCreator from "../../../../../utils/action-creator";
import { ADMIN_PRODUCTS_PATH } from "../../../../../utils/constants";
import * as toast from "../../../../../components/common/alerts/Toast";
import axios from "../../../../../services/axios";
import {actions as productActions} from './adminProductDetailsThunk'

const EDIT_PRODUCT_SUCCESS =
  "@app/admin/adminEditProductThunk/EDIT_PRODUCT_SUCCESS";
const EDIT_PRODUCT_START =
  "@app/admin/adminEditProductThunk/EDIT_PRODUCT_START";
const EDIT_PRODUCT_ERROR =
  "@app/admin/adminEditProductThunk/EDIT_PRODUCT_ERROR";

const initialState = {
  isLoading: false,
  errorMessage: null,
  status: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        status: 200,
      };
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        status: action.payload.errorStatus,
      };
    case EDIT_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        status: 0,
      };

    default:
      return state;
  }
};

export default reducer;

export const getEditProductState = (state) =>
  state.app.admin.products.adminEditProduct;

/**
 *
 *
 *
 * ACTIONS
 *
 *
 *
 */

const editProductSuccess = () => actionCreator(EDIT_PRODUCT_SUCCESS);
const editProductError = (errorMessage, errorStatus) =>
  actionCreator(EDIT_PRODUCT_ERROR, { errorMessage, errorStatus });
const editProductStart = () => actionCreator(EDIT_PRODUCT_START);

export const editProduct = (productData) => {
  return async (dispatch) => {
    dispatch(editProductStart());
    let data = new FormData();

    productData?.productImages?.forEach(image => {
      data.append('thumbnail', image);
    });
    data.append("category",productData?.category);
    data.append("description", productData?.description);
    data.append("price", productData?.price);
    data.append("quantity", productData?.quantity);
    productData.discount_percentage !== 0 &&
      data.append("discount_percentage", productData?.discount_percentage);

    data.append("unit", productData?.unit);
    data.append("subcategory_id",productData?.subcategory_id);
    data.append("longDescription", productData?.longDescription);
    data.append("available_status", productData?.status);


    await axios({
      method: "put",
      url: `/shop/product/${productData?.product_id}/`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.successClickableMessage(
          `'${productData.description}' u editua me sukses!`,
          null,
          ADMIN_PRODUCTS_PATH
        );
        dispatch(editProductSuccess());
        dispatch(productActions.request(productData?.product_id));
      })
      .catch((error) => {
        dispatch(editProductError(error));
        toast.error(error?.response?.statusText, error.message);
      });
  };
};
