import { Route, Redirect, useHistory } from "react-router-dom";

import { auth } from "../services/auth";
import { LOGIN_PATH, NOT_FOUND_PATH } from "./constants";
import { getRole } from "./utility-functions";


const NotValidRole = () => {
  const history = useHistory();
  history.push(NOT_FOUND_PATH);
  return <span></span>
};

function UserProtectedRoute({ component: Component, ...rest }) {
  const canAccess = getRole(auth.getDecodedJWT()?.role) === "customer";

  return (
    <Route
      {...rest}
      render={(props) => {
        if (canAccess) {
          return <Component {...props} />
        }
        if (!auth.getDecodedJWT())
          return <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />

        return <NotValidRole />
      }}
    />
  );
}

export default UserProtectedRoute;
