import actionCreator from "../../../../utils/action-creator";
import timeout from '../../../../utils/async-timeout';
/**
 * Types
 */
export const actionTypes = {
    FETCH_USER_DASHBOARD_DATA: '@app/user/userDashboardThunk/FETCH_USER_DASHBOARD_DATA',
    FETCH_USER_DASHBOARD_DATA_SUCCESS: '@app/user/userDashboardThunk/FETCH_USER_DASHBOARD_DATA_SUCCESS',
    FETCH_USER_DASHBOARD_DATA_ERROR: '@app/user/userDashboardThunk/FETCH_USER_DASHBOARD_DATA_ERROR',
}
/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    dashboardData: {},
};

/**
 * Selectors
 */
export const selectors = {
    getIsLoading: state => state.app.user.userDashboard.isLoading,
    getErrorMessage: state => state.app.user.userDashboard.errorMessage,
    getDashboardData: state => state.app.user.userDashboard.dashboardData
};

/**
 * Reducer
 */
const { FETCH_USER_DASHBOARD_DATA, FETCH_USER_DASHBOARD_DATA_ERROR, FETCH_USER_DASHBOARD_DATA_SUCCESS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_DASHBOARD_DATA:
            return {
                ...state,
                isLoading: true,
                errorMessage: ''
            };
        case FETCH_USER_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dashboardData: payload
            };
        case FETCH_USER_DASHBOARD_DATA_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    fetchUserOrders: function () {
        return async dispatch => {
            try {
                dispatch(actionCreator(FETCH_USER_DASHBOARD_DATA));
                const data = require('../../../../data/user-dashboard').default;
                await timeout(500);
                dispatch(actionCreator(FETCH_USER_DASHBOARD_DATA_SUCCESS, {...data}));
            }
            catch (err) {
                dispatch(actionCreator(FETCH_USER_DASHBOARD_DATA_ERROR, err));
            }
        };
    }
};