import actionCreator from "../../../../../utils/action-creator";
import { CART_STORAGE_KEY } from "../../../../../utils/constants";
import { getCartFromStorage, setCartInStorage } from "../../../../../services/cart-service";

/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/cart/userCartThunk/REQUEST",
    SUCCESS: "@app/user/cart/userCartThunk/SUCCESS",
    ERROR: "@app/user/cart/userCartThunk/ERROR",

    TOGGLE_CART: "@app/user/cart/userCart/Thunk",
};

/**
 * Initial state
 */
const initialState = {
    cart: {
        products: [],
        totalPrice: 0,
        totalDiscount: 0,
    },
    isCartVisible: false,
};

/**
 * selectors
 */
export const selectors = {
    getCart: state => state.app.user.cart.userCart.cart,
    getIsLoading: state => state.app.user.cart.userCart.isLoading,
    getIsVisible: state => state.app.user.cart.userCart.isCartVisible,
    getTotalProducts: state => {
        let count = 0;
        state.app.user.cart.userCart.cart?.products?.forEach(el => {
            count += parseInt(el?.quantityInCart);
        });
        return count;
    }
};

/**
 * Reducer
 */
const {
    REQUEST,
    ERROR,
    SUCCESS,
    TOGGLE_CART
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_CART:
            return {
                ...state,
                isCartVisible: payload
            }
        case REQUEST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
            };
        case SUCCESS:
            return {
                ...state,
                cart: payload || initialState.cart,
                isLoading: false,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const cart = getCartFromStorage();

                if (cart && typeof cart === "object") {
                    return dispatch(actionCreator(SUCCESS, cart));
                }

                setCartInStorage(initialState.cart);
                dispatch(actionCreator(SUCCESS, initialState.cart));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message))
            }
        };
    },
    resetCart: function () {
        return dispatch => {
            try {
                dispatch(actionCreator(SUCCESS, initialState.cart));
                setCartInStorage(initialState.cart);
            } catch (err) {
                // console.log({ err });
            }
        };
    },
    toggleCartVisibility: payload => actionCreator(TOGGLE_CART, payload),
};
