import "./style.scss";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function LinkDashboard({ isActive, text, onClick, to, currentPath }) {
  const click = () => {
    if (onClick) {
      onClick(to);
      return;
    }
  };

  return (
    <div className="LinkDashboard">
      <NavLink
        to={to}
        onClick={click}
        className={`LinkDashboard__text ${
          isActive && "LinkDashboard__text--active"
        }`}
      >
        {text}
      </NavLink>
      {isActive && <div className="LinkDashboard__circle" />}
    </div>
  );
}

LinkDashboard.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

export default LinkDashboard;
