import actionCreator from "../../../utils/action-creator";
import timeout from "../../../utils/async-timeout";
import axios from "../../../services/axios";
import { auth } from "../../../services/auth";
import { getRole } from "../../../utils/utility-functions";
import * as toast from "../../../components/common/alerts/Toast";

import { actionTypes as loginTypes } from "./loginThunk";
import { actionTypes as authUserTypes, actions as authenticatedActions } from "./authenticatedUserThunk";

/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@auth/registerThunk/REQUEST",
  ERROR: "@auth/registerThunk/ERROR",
  RESET_ERROR: "@auth/registerThunk/RESET_ERROR",
  SET_REGISTER_STATUS: "@auth/registerThunk/SET_REGISTER_STATUS",
};

/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  registerStatus: 0,
};
/**
 * Reducer
 */
const { ERROR, REQUEST, RESET_ERROR, SET_REGISTER_STATUS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        registerStatus: 0,
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case SET_REGISTER_STATUS:
      return {
        ...state,
        isLoading: false,
        registerStatus: payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: "",
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Selectors
 */
export const selectors = {
  getIsRegisterLoading: (state) => state.auth.register.isLoading,
  getHasRegisterError: (state) => state.auth.register.errorMessage,
  getRegisterStatus: (state) => state.auth.register.registerStatus,
};

/**
 * Actions
 */
export const actions = {
  requestRegister: function ({
    firstName,
    lastName,
    email,
    phoneNumber,
    confirmPassword,
    password,
  }) {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(REQUEST));
        const registerResponse = await axios.post("/auth/register/", {
          password,
          password2: confirmPassword,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          email,
        });

        const loginResponse = await axios.post("/auth/login/", {
          email: registerResponse.email,
          password: password,
        });

        const { jwt_token } = loginResponse;
        auth.setToken(jwt_token);

        dispatch(authenticatedActions.setRole(getRole(loginResponse?.group)));
        dispatch(registerSuccess(200));

        dispatch(authenticatedActions.request());
      } catch (err) {
        let message = "";
        let messageTitle = "";

        const errData = err?.response?.data;
        if (errData) {
          Object.entries(errData).forEach(([key, value], i) => {
            message = value[0];
            messageTitle = key;
          });
        }
        dispatch(registerError(messageTitle + message));
        toast.error(messageTitle, message);
        dispatch(actionCreator(SET_REGISTER_STATUS, 404));
      }
    };
  },
  resetError: function () {
    return actionCreator(RESET_ERROR, "");
  },
  resetRegisterStatus: function () {
    return actionCreator(SET_REGISTER_STATUS, 0);
  },
};
/**
 * actions - helpers
 */
export const registerError = (error) => actionCreator(ERROR, error);
export const registerSuccess = (status) => actionCreator(SET_REGISTER_STATUS, status);
