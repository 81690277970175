import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";
/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/products/userHomeProductsThunk/REQUEST",
    SUCCESS: "@app/user/products/userHomeProductsThunk/SUCCESS",
    ERROR: "@app/user/products/userHomeProductsThunk/ERROR",
};
/**
 * Initial state
 */
const initialState = {
    errorMessage: null,
    isLoading: false,
    data: {
        latest_products: [],
        household_equipment: []
    },
};

/**
 * Selectors
 */
export const selectors = {
    getData: state => state.app.user.products.userHomeProducts.data,
    getIsLoading: state => state.app.user.products.userHomeProducts.isLoading,
    getErrorMessage: state => state.app.user.products.userHomeProducts.errorMessage,
};


/**
 * Reducer
 */
const {
    ERROR,
    REQUEST,
    SUCCESS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                errorMessage: null,
                isLoading: true,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        case SUCCESS:
            return {
                ...state,
                errorMessage: null,
                isLoading: false,
                data: payload
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response = await axios.get('/customer/home-products/');

                dispatch(actionCreator(SUCCESS, {
                    latest_products: response.latest_products.map(x => ({
                        ...x,
                        thumbnail: addBaseUrlToThumbnails(x.thumbnail)
                    })),
                    household_equipment: response.household_equipment.map(x => ({
                        ...x,
                        thumbnail: addBaseUrlToThumbnails(x.thumbnail)
                    })),
                }));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message));
            }
        };
    },
};
