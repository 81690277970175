import {GrFormClose} from "react-icons/gr";
import {LazyLoadImage} from "react-lazy-load-image-component";
import PlusMinusQuantity from "../PlusMinusQuantity";

export const ProductCard = ({style,thumbnail,description,decrementQuantity,incrementQuantity,onChangeQuantity,quantityInCart,onDelete})=>{
    return(
        <div className="productInCartContainer" style={style}>
            <div className="productInCartContainer__row">
                <div className="productInCartContainer__left">
                    <LazyLoadImage alt="product" src={thumbnail && thumbnail[0]?.thumbnail} />
                </div>

                <div className="productInCartContainer__right">
                    <h2 className="productInCartContainer__right__name">{description}</h2>

                    <div className="productInCartContainer__right__rowInputs m-top-8">
                        <PlusMinusQuantity
                            bold
                            style={{ width: 70 }}
                            onClickMinus={decrementQuantity}
                            onClickPlus={incrementQuantity}
                            onChangeQuantity={onChangeQuantity}
                            quantity={quantityInCart}
                        />
                    </div>
                </div>
            </div>
            <div className="productInCartContainer__xContainer">
                <button className="noStyleButton" onClick={onDelete}>
                    <GrFormClose className="productInCartContainer__xContainer__icon" />
                </button>
            </div>
        </div>
    )
}
export default ProductCard;
