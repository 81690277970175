import './style.scss';
/**
 * libraries
 */
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

/**
 * icons - assets
 */
import { CgClose } from 'react-icons/cg';
/**
 * components
 */
import RegisterUI from '../../auth/RegisterUI';

/**
 * utils - services
 */
import { RegisterSchema } from '../../../utils/validationSchemas';
import { Fade } from 'react-awesome-reveal';


const RegisterModal = ({
    isModalVisible,
    onCloseModal,
    isLoading,
    onGoToLogin,
    onSuccessfullySubmit,
}) => {

    const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
        reValidateMode: "onChange",
        mode: "onSubmit",
        resolver: yupResolver(RegisterSchema),
    });

    useEffect(() => {
        isModalVisible && registerInputs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    const registerInputs = () => {
        register({ name: "firstName" });
        register({ name: "lastName" });
        register({ name: "password" });
        register({ name: "confirmPassword" });
        register({ name: "email" });
        register({ name: "phoneNumber" });
    };

    const onChangeInput = (key, value) => {
        errors[key] && clearErrors(key);
        return setValue(key, value);
    };

    const handleRegisterSubmit = values => {
        onSuccessfullySubmit(values);
    };

    return (
        <Modal
            style={{
                overlay: {
                    zIndex: 999,
                    maxWidth: '100%'
                },
                content: {
                    maxWidth: '100%'
                }
            }}
            className="registerModal"
            isOpen={isModalVisible}
            shouldCloseOnEsc
            ariaHideApp={false}
            onRequestClose={onCloseModal}
        >
            <Fade className="authModalFade" triggerOnce duration={500}>
                <div className="registerModal__content">
                    <div className="registerModal__content__closeContainer">
                        <button onClick={onCloseModal} className="noStyleButton p-10">
                            <CgClose className="registerModal__content__closeContainer__icon" />
                        </button>
                    </div>
                    <RegisterUI
                        errors={errors}
                        handleSubmit={handleSubmit(handleRegisterSubmit)}
                        isLoading={isLoading}
                        onChangeInput={onChangeInput}
                        onClickLogo={() => { }}
                        onGoToLogin={onGoToLogin}
                    />
                </div>
            </Fade>
        </Modal>
    );
};

export default RegisterModal;