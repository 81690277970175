import {TOKEN_NAME, ROLE, CART_STORAGE_KEY} from "../utils/constants";
import jwt_decode from "jwt-decode";

export const auth = {
  getToken: () => localStorage.getItem(TOKEN_NAME),
  logOut: () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(CART_STORAGE_KEY);
  },
  setToken: (token) => localStorage.setItem(TOKEN_NAME, token),
  removeToken: () => localStorage.removeItem(TOKEN_NAME),
  setValue: (key, value) => localStorage.setItem(key, value),
  getDecodedJWT: () => {
    try {
      const token = localStorage.getItem(TOKEN_NAME);
      return token && jwt_decode(token);
    }
    catch (err) {
      return null;
    }
  },
};
