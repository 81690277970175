import actionCreator from "../../../../../utils/action-creator";
import { getCartFromStorage, setCartInStorage, getTotalPriceOfCart } from "../../../../../services/cart-service";
import * as toast from "../../../../../components/common/alerts/Toast";
import { actions as cartActions } from "./userCartThunk";
import { toast as Toast } from "react-toastify";

/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/cart/userAddToCartThunk/REQUEST",
    SUCCESS: "@app/user/cart/userAddToCartThunk/SUCCESS",
    ERROR: "@app/user/cart/userAddToCartThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
};

/**
 * selectors
 */
export const selectors = {
    getList: state => state.app.user.cart.userCartProducts.list,
    getTotalPrice: state => state.app.user.cart.userCartProducts.totalPrice,
    getTotalDiscount: state => state.app.user.cart.userCartProducts.totalDiscount,
};

/**
 * Reducer
 */
const {
    REQUEST,
    ERROR,
    SUCCESS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
            };
        case SUCCESS:
            return {
                ...state,
                products: payload?.products,
                totalDiscount: payload?.totalDiscount,
                totalPrice: payload?.totalPrice,
                isLoading: false,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };

        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (product, quantity) {
        return async (dispatch, getState) => {
            try {
                let cart = getCartFromStorage();
                let initialCart = getState().app.user.cart.userCart.cart;

                if (!cart) {
                    setCartInStorage(initialCart);
                    cart = { ...initialCart };
                }

                const foundProduct = cart?.products?.find(el => el.product_id === product.product_id);
                let products = cart?.products;

                if (foundProduct) { // if product already exists in cart
                    if (foundProduct?.quantityInCart + quantity > product?.quantity) {
                        toast.warning("Sasia e kerkuar nuk gjendet ne stok", `sasia totale ne stok e ketij produkti eshte : ${product?.quantity}`, { position: Toast.POSITION.BOTTOM_LEFT });
                        dispatch(cartActions.toggleCartVisibility(true));
                        return dispatch(ERROR, "Sasia e kerkuar nuk gjendet ne stok");
                    }
                    const productsPayload = products.map(el => el.product_id === product.product_id ? { ...el, quantityInCart: el.quantityInCart + quantity } : el);
                    const cartPayload = { ...cart, products: productsPayload };
                    setCartInStorage({ ...cartPayload, totalPrice: getTotalPriceOfCart(cartPayload) })
                } else {
                    if (product?.quantity < quantity) {
                        toast.warning("Sasia e kerkuar nuk gjendet ne stok", `sasia totale ne stok e ketij produkti eshte : ${product?.quantity}`, { position: Toast.POSITION.BOTTOM_LEFT });
                        return dispatch(ERROR, "Sasia e kerkuar nuk gjendet ne stok");
                    }
                    products.unshift({ ...product, quantityInCart: quantity });
                    const cartPayload = { ...cart, products };
                    setCartInStorage({ ...cartPayload, totalPrice: getTotalPriceOfCart(cartPayload) })
                }
                toast.successClickableMessage(`Produkti '${product.description} u shtua ne shporte!'`, null, null, null, { autoClose: 1500, position: Toast.POSITION.BOTTOM_LEFT });

                dispatch(cartActions.request());
                dispatch(cartActions.toggleCartVisibility(true));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message));
            }
        };
    },
    changeQuantity: function (product, quantity) {
        return (dispatch) => {
            try {
                const cart = getCartFromStorage();
                const foundProduct = cart.products.find(el => el.product_id === product.product_id);

                if (quantity > foundProduct.quantity) {
                    return toast.warning(`sasia totale ne stok e ketij produkti eshte : ${product?.quantity}`, null, { position: Toast.POSITION.BOTTOM_LEFT });
                }
                let products = cart.products.map(el => {
                    if (el.product_id === product.product_id)
                        return { ...el, quantityInCart: quantity }
                    return el
                });
                setCartInStorage({ ...cart, products });
                dispatch(cartActions.request());
            } catch (err) {

            }
        };
    }
};
