import './style.scss';
/**
 * libraries
 */
import { withRouter, useHistory } from 'react-router-dom';

/**
 * assets
 */
import logo from '../assets/images/logo_dashboard.png';

/**
 * icons
 */
import { VscMenu, VscChromeClose } from 'react-icons/vsc';
import { FaUserCircle } from 'react-icons/fa';
import { RiLogoutCircleRLine } from 'react-icons/ri';

/**
 * components
 */
import LinkDashboard from '../components/admin-components/LinkDashboard';

/**
 * utils
 */
import {
  ADMIN_DASHBOARD_PATH,
  ADMIN_PRODUCTS_PATH,
  ADMIN_CATEGORIES_PATH,
  ADMIN_CUSTOMERS_PATH,
  ADMIN_ORDERS_PATH,
  ADMIN_OFFERS_PATH,
  HOME_PATH,
  ADMIN_ADD_PRODUCT_PATH,
  ADMIN_ORDER_DETAILS_PATH,
  ADMIN_BASE_PATH,
  ADMIN_SETTINGS_PATH,
  ADMIN_PRODUCT_DETAILS,
  ADMIN_ADD_CATEGORY_PATH,
  ADMIN_EDIT_CATEGORY_PATH,
  ADMIN_ALL_STAFF_PATH,
  PICKER_ORDERS_PATH,
  PICKER_ORDER_DETAILS_PATH,
  COURIER_ORDERS_PATH,
  COURIER_ORDER_DETAILS_PATH,
  PICKER_SETTINGS_PATH,
  COURIER_SETTINGS_PATH,
  ADMIN_BANNER_PATH,
} from '../utils/constants';
import { useDispatch } from 'react-redux';
import {
  actions as logoutActions,
  selectors as logoutSelectors,
} from '../redux/thunk/auth/logoutThunk';
import { actions as authActions } from '../redux/thunk/auth/authenticatedUserThunk';

import { useTransition, animated } from 'react-spring';
import { useEffect, useState } from 'react';
import { auth } from '../services/auth';
import { useSelector } from 'react-redux';
import { selectors } from '../redux/thunk/auth/authenticatedUserThunk';
import { getRole } from '../utils/utility-functions';

const adminNavLinks = [
  {
    to: ADMIN_DASHBOARD_PATH,
    text: 'Informacione',
    activePaths: [ADMIN_DASHBOARD_PATH, ADMIN_BASE_PATH],
  },
  {
    to: ADMIN_PRODUCTS_PATH,
    text: 'Produktet',
    activePaths: [
      ADMIN_PRODUCTS_PATH,
      ADMIN_ADD_PRODUCT_PATH,
      ADMIN_PRODUCT_DETAILS,
    ],
  },
  {
    to: ADMIN_CATEGORIES_PATH,
    text: 'Kategorite',
    activePaths: [
      ADMIN_CATEGORIES_PATH,
      ADMIN_ADD_CATEGORY_PATH,
      ADMIN_EDIT_CATEGORY_PATH,
    ],
  },
  {
    to: ADMIN_CUSTOMERS_PATH,
    text: 'Klientet',
    activePaths: [ADMIN_CUSTOMERS_PATH],
  },
  {
    to: ADMIN_ALL_STAFF_PATH,
    text: 'Stafi',
    activePaths: [ADMIN_ALL_STAFF_PATH],
  },
  {
    to: ADMIN_ORDERS_PATH,
    text: 'Porosite',
    activePaths: [ADMIN_ORDERS_PATH, ADMIN_ORDER_DETAILS_PATH],
  },
  {
    to: ADMIN_OFFERS_PATH,
    text: 'Ofertat',
    activePaths: [ADMIN_OFFERS_PATH],
  },
  {
    to: ADMIN_BANNER_PATH,
    text: 'Banner',
    activePaths: [ADMIN_BANNER_PATH],
  },
];

const superAdminNavLinks = [
  {
    to: ADMIN_DASHBOARD_PATH,
    text: 'Informacione',
    activePaths: [ADMIN_DASHBOARD_PATH, ADMIN_BASE_PATH],
  },
  {
    to: ADMIN_PRODUCTS_PATH,
    text: 'Produktet',
    activePaths: [
      ADMIN_PRODUCTS_PATH,
      ADMIN_ADD_PRODUCT_PATH,
      ADMIN_PRODUCT_DETAILS,
    ],
  },
  {
    to: ADMIN_CATEGORIES_PATH,
    text: 'Kategorite',
    activePaths: [
      ADMIN_CATEGORIES_PATH,
      ADMIN_ADD_CATEGORY_PATH,
      ADMIN_EDIT_CATEGORY_PATH,
    ],
  },
  {
    to: ADMIN_CUSTOMERS_PATH,
    text: 'Klientet',
    activePaths: [ADMIN_CUSTOMERS_PATH],
  },
  {
    to: ADMIN_ALL_STAFF_PATH,
    text: 'Stafi',
    activePaths: [ADMIN_ALL_STAFF_PATH],
  },
  {
    to: ADMIN_ORDERS_PATH,
    text: 'Porosite',
    activePaths: [ADMIN_ORDERS_PATH, ADMIN_ORDER_DETAILS_PATH],
  },
  {
    to: ADMIN_OFFERS_PATH,
    text: 'Ofertat',
    activePaths: [ADMIN_OFFERS_PATH],
  },
  {
    to: ADMIN_BANNER_PATH,
    text: 'Banner',
    activePaths: [ADMIN_BANNER_PATH],
  },
];

const pickerNavLinks = [
  {
    to: PICKER_ORDERS_PATH,
    text: 'Porosite',
    activePaths: [PICKER_ORDERS_PATH, PICKER_ORDER_DETAILS_PATH],
  },
];

const courierNavLinks = [
  {
    to: COURIER_ORDERS_PATH,
    text: 'Porosite',
    activePaths: [COURIER_ORDERS_PATH, COURIER_ORDER_DETAILS_PATH],
  },
];

function AdminLayout(props) {
  const history = useHistory();
  const [admin, setAdmin] = useState({});
  const [isMenuToggle, setMenuToggle] = useState(false);
  const currentPath = props.match.path;
  const adminState = useSelector(selectors.getUser);
  const token = auth.getToken();
  const role = getRole(auth.getDecodedJWT()?.role);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logoutActions.setLoading(true));
    auth.logOut();
    history.push(HOME_PATH, { loggedOut: true });
    dispatch(logoutActions.setLoading(false));
    dispatch(authActions.resetAllRedux());
  };

  useEffect(() => {
    setAdmin(adminState);
  }, [adminState]);

  const username = auth.getDecodedJWT()?.username;

  const checkIsLinkActive = (activePaths) => {
    let isActive = false;

    activePaths.forEach((item) => {
      if (item === currentPath) {
        isActive = true;
      }
    });

    return isActive;
  };

  const _renderLinks = () => {
    let navLinks = [];
    if (role === 'admin') {
      navLinks = [...adminNavLinks];
    }
    if (role === 'picker') {
      navLinks = [...pickerNavLinks];
    }
    if (role === 'courier') {
      navLinks = [...courierNavLinks];
    }
    if (username === 'superadmin') {
      navLinks = [...superAdminNavLinks];
    }

    return navLinks.map((item, i) => (
      <LinkDashboard
        onClick={(to) => isMenuToggle && setMenuToggle(false)}
        key={item.to + item.text + i}
        to={item.to}
        isActive={checkIsLinkActive(item.activePaths)}
        text={item.text}
      />
    ));
  };

  const onClickLogo = () => {
    history.push(HOME_PATH);
  };

  const onClickProfile = (e) => {
    e.preventDefault();
    setMenuToggle(false);
    setTimeout(() => {
      if (role === 'admin') {
        history.push(ADMIN_SETTINGS_PATH);
      }
      if (role === 'picker') {
        history.push(PICKER_SETTINGS_PATH);
      }
      if (role === 'courier') {
        history.push(COURIER_SETTINGS_PATH);
      }
    }, [500]);
  };

  const transitions1 = useTransition(isMenuToggle, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  return (
    <div className="adminLayout">
      <div className="adminLayout__leftDashboard">
        <div style={{ width: '100%' }}>
          <img
            onClick={onClickLogo}
            alt="super viva"
            src={logo}
            style={{ height: 33, cursor: 'pointer' }}
          />
        </div>

        <div className="adminLayout__leftDashboard__navLinkContainers">
          {_renderLinks()}
        </div>
        <div className="admin_logout">
          <div
            onClick={onClickProfile}
            className="adminLayout__leftDashboard__userCredentials"
          >
            <FaUserCircle className="adminLayout__leftDashboard__userCredentials__icon" />
            <h3 className="adminLayout__leftDashboard__userCredentials__text">
              {admin?.first_name} {admin?.last_name}
            </h3>
          </div>
          <div
            onClick={onLogout}
            className="adminLayout__leftDashboard__userCredentials"
          >
            <RiLogoutCircleRLine className="adminLayout__leftDashboard__userCredentials__icon" />
            <h3 className="adminLayout__leftDashboard__userCredentials__text">
              Shkyqu
            </h3>
          </div>
        </div>
      </div>

      {/* should display only in mobile width */}
      <div
        className={`adminLayout__leftDashboardMobile ${
          isMenuToggle && 'adminLayout__leftDashboardMobile--visible'
        }`}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <img
            onClick={onClickLogo}
            alt="super viva"
            src={logo}
            style={{ height: 33, cursor: 'pointer' }}
          />

          <button
            className="noStyleButton"
            onClick={() => setMenuToggle((prevState) => !prevState)}
          >
            <VscChromeClose
              style={{ fontSize: 27, cursor: 'pointer', color: 'white' }}
              className={`adminLayout__rightContent__menuMobileContainer__icon`}
            />
          </button>
        </div>

        <div className="adminLayout__leftDashboard__navLinkContainers">
          {_renderLinks()}
        </div>

        <div
          onClick={onClickProfile}
          className="adminLayout__leftDashboard__userCredentials"
        >
          <FaUserCircle className="adminLayout__leftDashboard__userCredentials__icon" />
          <h3 className="adminLayout__leftDashboard__userCredentials__text">
            {admin?.first_name} {admin?.last_name}
          </h3>
        </div>
      </div>

      <div
        className="adminLayout__rightContent"
        onClick={(e) => {
          e.stopPropagation();
          setMenuToggle(false);
        }}
      >
        <div className="adminLayout__rightContent__menuMobileContainer">
          {transitions1.map(({ item, key, props }) =>
            !item ? (
              <animated.div key={key} style={props}>
                <button
                  className="noStyleButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuToggle((prevState) => !prevState);
                  }}
                >
                  <VscMenu
                    className={`adminLayout__rightContent__menuMobileContainer__icon`}
                    style={{ fontSize: 27, cursor: 'pointer' }}
                  />
                </button>
              </animated.div>
            ) : (
              <animated.div key={key} style={props}>
                <button
                  className="noStyleButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuToggle((prevState) => !prevState);
                  }}
                >
                  <VscChromeClose
                    style={{ fontSize: 27, cursor: 'pointer' }}
                    className={`adminLayout__rightContent__menuMobileContainer__icon`}
                  />
                </button>
              </animated.div>
            )
          )}
        </div>
        {props.children}
        {isMenuToggle && <div className="adminLayout__rightContent__overlay" />}
      </div>
    </div>
  );
}

export default withRouter(AdminLayout);
