import actionCreator from "../../../utils/action-creator";
import axios from "../../../services/axios";
import { auth } from "../../../services/auth";
import { getRole } from "../../../utils/utility-functions";
import * as toast from "../../../components/common/alerts/Toast";


import { actions as authenticatedUserActions, actionTypes as authTypes } from "./authenticatedUserThunk";
/**
 * Types
 */
export const actionTypes = {
  REQUEST_LOGIN: "@auth/loginThunk/REQUEST_LOGIN",
  LOGIN_SUCCESS: "@auth/loginThunk/LOGIN_SUCCESS",
  LOGIN_ERROR: "@auth/loginThunk/LOGIN_ERROR",
  RESET_ERROR: "@auth/loginThunk/RESET_ERROR",
  SET_ROLE: "@auth/loginThunk/SET_ROLE",
  RESET_LOGIN_STATUS: "@auth/loginThunk/RESET_LOGIN_STATUS",
  RESET_STATE: "@auth/loginThunk/RESET_STATE",
};
/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  loginStatus: 0,
  role: "",
};

/**
 * selectors
 */
export const selectors = {
  getRole: (state) => state.auth.login.role,
};

/**
 * Reducer
 */
const {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SET_ROLE,
  REQUEST_LOGIN,
  RESET_ERROR,
  RESET_LOGIN_STATUS,
  RESET_STATE,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        loginStatus: 0,
        role: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginStatus: payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: "",
      };
    case SET_ROLE:
      return {
        ...state,
        role: payload,
      };
    case RESET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: 0,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  requestLogin: function (email, password) {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(REQUEST_LOGIN));

        const response = await axios.post("/auth/login/", { email, password });
        const { jwt_token } = response;
        auth.setToken(jwt_token);
        dispatch(authenticatedUserActions.setRole(getRole(response?.group)));
        dispatch(loginSuccess(200));

        dispatch(authenticatedUserActions.request());
      } catch (err) {
        dispatch(loginError(err.response.data.non_field_errors || err.message));
        toast.error("Login error", err.response.data.non_field_errors || err.message);
      }
    };
  },
  resetError: function () {
    return actionCreator(RESET_ERROR);
  },
  resetLoginStatus: function () {
    return actionCreator(RESET_LOGIN_STATUS);
  },
  setRole: (role) => actionCreator(SET_ROLE, role),
};
/**
 * actions - helpers
 */
export const loginError = (message) => actionCreator(LOGIN_ERROR, message);
export const loginSuccess = (status) => actionCreator(LOGIN_SUCCESS, status);
/**
 * Selectors
 */
export const getLoginError = (state) => state.auth.login.errorMessage;
export const getLoginRole = (state) => state.auth.login.role;
export const getLoginLoading = (state) => state.auth.login.isLoading;
export const getLoginStatus = (state) => state.auth.login.loginStatus;
