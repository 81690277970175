import "./style.scss";
import { Fade } from "react-awesome-reveal";

const TextError = ({ message, className }) => (
  <Fade triggerOnce>
    <h2 className={`error-text ${className}`}>{message}</h2>
  </Fade>
);

export default TextError;
