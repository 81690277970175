import { euroFormatter } from './number-formatters';
import { format } from 'date-fns';

/**
 * @param {Array} roles => ["SuperAdmin","Admin","Picker"]
 *
 * return => a role string, based on roles array;
 */
const getRole = (roles) => {
  if (!roles) {
    return "";
  }
  if (roles.includes("admin") || roles.includes("Admin")) {
    return "admin";
  }
  if (roles.includes("picker") || roles.includes("Picker")) {
    return "picker";
  }
  if (roles.includes("courier") || roles.includes("Courier")) {
    return "courier";
  }
  if (roles.includes("customer") || roles.includes("Customer")) {
    return "customer";
  }
  return ""
};

/**
 * @param {array} thumbnails => [ { thumbnail : '/img.jpg' } ]
 *
 * return => thumbnail array with modified image url, we add base url because image url comme with only endpoint
 */
const addBaseUrlToThumbnails = (thumbnails) => {
  return thumbnails.map(item => ({
    ...item,
    thumbnail: item.thumbnail
  }));
};


/**
 * @param {double} price
 * @param {double} discount
 * @param {boolean} shouldFormat => whether if you want to return price formatted like ex. 10.00€
 *
 * return => calculated price based on discount
 */
const getDiscountedPrice = (price, discount, shouldFormat = true) => {
  let discountedPrice = 0;
  discountedPrice = price - (discount / 100 * price);
  return shouldFormat ? euroFormatter(discountedPrice) : discountedPrice;
};


/**
 * @param {string} date => date string in any format
 * @param {string} f  => date-fns format type
 *
 * return => formatted date with date-fns library
 */
const getFormattedDate = (date, f = "PP - HH:mm") => {
  return format(new Date(date), f);
}


/**
 * @param {Array} products
 *
 * return => total count of products based on quantity
 */
const getProductsCount = (products) => {
  let count = 0;
  products.forEach(el => count += el.quantity);
  return count;
}

export { getRole, addBaseUrlToThumbnails, getDiscountedPrice, getFormattedDate, getProductsCount };
