import axios from "../../../../../services/axios";
import actionCreator from "../../../../../utils/action-creator";
import {actions as authUserActions} from '../../../auth/authenticatedUserThunk'
/**
 * Types
 */
export const actionTypes = {
  REQUEST_EDIT: "@app/user/settings/editProfileThunk/REQUEST_EDIT",
  REQUEST_EDIT_SUCCESS:
    "@app/user/settings/editProfileThunk/REQUEST_EDIT_SUCCESS",
  REQUEST_EDIT_ERROR: "@app/user/settings/editProfileThunk/REQUEST_EDIT_ERROR",
  RESET: "@app/user/settings/editProfileThunk/RESET",
};
/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  status: 0,
};
/**
 * Reducer
 */
const {
  RESET,
  REQUEST_EDIT,
  REQUEST_EDIT_SUCCESS,
  REQUEST_EDIT_ERROR,
} = actionTypes;

export const getUserChangeDataState = (state) =>
  state.app.user.settings.editProfile;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_EDIT:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case REQUEST_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 200,
      };
    case REQUEST_EDIT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  requestChange: function (first_name, last_name, phone) {
    return async (dispatch) => {
      dispatch(actionCreator(REQUEST_EDIT));

      try {
        axios
          .put("/auth/me/", {
            first_name,
            last_name,
            profile: { phone },
          })
          .then((res) => {
            dispatch(actionCreator(REQUEST_EDIT_SUCCESS));
            dispatch(authUserActions.request());
          })
          .catch((err) => {
            if (err?.response?.data?.first_name) {
              dispatch(
                actionCreator(
                  REQUEST_EDIT_ERROR,
                  err?.response?.data?.first_name[0]
                )
              );
            } else if (err?.response?.data?.last_name) {
              dispatch(
                actionCreator(
                  REQUEST_EDIT_ERROR,
                  err?.response?.data?.last_name[0]
                )
              );
            } else if (err?.response?.data?.profile) {
              // dispatch(actionCreator(REQUEST_EDIT_ERROR, err?.response?.data?.profile[0]));
            } else {
              dispatch(actionCreator(REQUEST_EDIT_ERROR, "Diqka Shkoi Keq"));
            }
          });
      } catch (err) {
        dispatch(actionCreator(REQUEST_EDIT_ERROR, err.message));
      }
    };
  },
  reset: () => actionCreator(RESET),
};
