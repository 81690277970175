import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const ADMIN_START_DELETE_STAFF = "@admin/staff/ADMIN_START_DELETE_STAFF";
const ADMIN_ERROR_DELETE_STAFF = "@admin/staff/ADMIN_ERROR_DELETE_STAFF";
const ADMIN_SUCCESS_DELETE_STAFF = "@admin/staff/ADMIN_SUCCESS_DELETE_STAFF";
const ADMIN_RESET_DELETE_STAFF = "@admin/staff/ADMIN_RESET_DELETE_STAFF";

const initialState = {
  loading: false,
  error: null,
  status: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_DELETE_STAFF:
      return {
        loading: true,
        error: null,
        status: null,
      };
    case ADMIN_ERROR_DELETE_STAFF:
      return {
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ADMIN_SUCCESS_DELETE_STAFF:
      return {
        loading: false,
        error: null,
        status: 200,
      };
    case ADMIN_RESET_DELETE_STAFF:
      return {
        loading: false,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};

export default reducer;

export const getDeleteStaffState = (state) =>
  state.app.admin.staff.deleteAdminStaff;

export const deleteStaff = (id) => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_START_DELETE_STAFF));

    await axios
      .delete(`/shop/staff-user/${id}/`)
      .then((res) => {
        dispatch(actionCreator(ADMIN_SUCCESS_DELETE_STAFF, res.results));
      })
      .catch((err) => {
        dispatch(
          actionCreator(ADMIN_ERROR_DELETE_STAFF, {
            status: 404,
            error: "could not delete staff",
          })
        );
      });
  };
};

export const resetDeleteStaffState = () => {
  return (dispatch) => {
    dispatch(actionCreator(ADMIN_RESET_DELETE_STAFF));
  };
};
