/* eslint-disable react-hooks/exhaustive-deps */
import './style.scss';
/**
 * libraries
 */
import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Slide, Fade } from 'react-awesome-reveal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {getTransportPrice} from "../../../utils/getTransportPrice";
/**
 * components
 */
import Button from '../../common/Button';
import ProductInCart from './ProductInCart';

/**
 * icons
 */
import { VscChromeClose } from 'react-icons/vsc';

/**
 * redux
 */
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as userCartActions,
  selectors as userCartSelectors,
} from '../../../redux/thunk/app/user/cart/userCartThunk';

/**
 * utils
 */
import { PAYMENT_PATH } from '../../../utils/constants';
import { auth } from '../../../services/auth';
import { getRole } from '../../../utils/utility-functions';
import * as toast from '../../common/alerts/Toast';

const WrapperCart = (props) => {
  return document.body.clientWidth <= 450 ? (
    props.children
  ) : (
    <Slide duration={400} direction="right">
      {props.children}
    </Slide>
  );
};

const Cart = ({ style, ...restProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isCartVisible = useSelector(userCartSelectors.getIsVisible);
  const totalProducts = useSelector(userCartSelectors.getTotalProducts);
  const { products, totalPrice, totalDiscount } = useSelector(
    userCartSelectors.getCart
  );

  const { pathname } = restProps.location;

  useEffect(() => {
    if (isCartVisible) {
      document.body.style.overflowY = 'hidden';
      return;
    }
    document.body.style.overflowY = 'auto';
  }, [isCartVisible, products]);

  const toggleVisibility = () => {
    dispatch(userCartActions.toggleCartVisibility(false));
  };

  const _renderProducts = () => {
    return products?.map((item, index) => (
      <CSSTransition key={item.id} timeout={500} classNames="productInCart">
        <ProductInCart
          {...item}
          style={{
            borderBottom:
              index < products.length - 1 ? '1px solid #DBDBDB' : 'none',
          }}
        />
      </CSSTransition>
    ));
  };

  const onClickGoToPayment = () => {
    toggleVisibility();

    if (
      getRole(auth.getDecodedJWT()?.role) === 'admin' ||
      getRole(auth.getDecodedJWT()?.role) === 'picker' ||
      getRole(auth.getDecodedJWT()?.role) === 'courier'
    ) {
      return toast.warning('Vetem perdoruesi i thjesht mund te  porosi!');
    }

    pathname !== PAYMENT_PATH && history.push(PAYMENT_PATH);
  };

  return (
    <div
      className="cartContainer modal"
      style={{ ...style, display: isCartVisible ? 'flex' : 'none' }}
    >
      <Fade style={{ display: 'flex', flex: 1 }} delay={300} duration={300}>
        <div className="cartContainer__left" onClick={toggleVisibility} />
      </Fade>
      <WrapperCart>
        <div className="cartContainer__right">
          <div className="cartContainer__right__header">
            <h3 className="cartContainer__right__header__text">
              Shporta ime
              <span className="cartContainer__right__header__text--bold">
                {' '}
                ({totalProducts} produkte)
              </span>
            </h3>
            <button className="noStyleButton" onClick={toggleVisibility}>
              <VscChromeClose className="cartContainer__right__header__icon" />
            </button>
          </div>
          {/* <div className="cartContainer__right__header">
            <h3 className="cartContainer__right__header__text">
              Shporta ime
              <span className="cartContainer__right__header__text--bold">
                {' '}
                ({totalProducts} produkte)
              </span>
            </h3>
            <button className="noStyleButton" onClick={toggleVisibility}>
              <VscChromeClose className="cartContainer__right__header__icon" />
            </button>
          </div> */}

          <TransitionGroup className="cartContainer__right__body">
            {_renderProducts()}
          </TransitionGroup>

          <div className="cartContainer__right__bottom">
            <div
              className="p-horizontal-20"
              style={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div className="horizontalLine" />
            </div>

            <div className="cartContainer__right__bottom__row">
              <h3 className="cartContainer__right__bottom__row__text">
                Transporti
              </h3>
              <h3 className="cartContainer__right__bottom__row__text">
                {Number(getTransportPrice(totalPrice)).toFixed(2)}€
              </h3>
            </div>
            <div className="cartContainer__right__bottom__row">
              <h3 className="cartContainer__right__bottom__row__text">
                Totali i zbritjes
              </h3>
              <h3 className="cartContainer__right__bottom__row__text">
                {Number(totalDiscount).toFixed(2)}€
              </h3>
            </div>

            <div className="cartContainer__right__bottom__row">
              <h3
                style={{ fontSize: 18 }}
                className="cartContainer__right__bottom__row__text"
              >
                Total
              </h3>
              <h3
                style={{ fontSize: 18 }}
                className="cartContainer__right__bottom__row__text"
              >
                {Number(totalPrice+getTransportPrice(totalPrice)).toFixed(2)}€
              </h3>
            </div>

            <div className="horizontalLine m-top-14" />

            <div className="cartContainer__right__bottom__rowBottom">
              <h3 className="cartContainer__right__bottom__rowBottom__text">
                Keni kod te zbritjes?
              </h3>
              <Button
                disabled={products.length < 1}
                text="Vazhdo te Pagesa"
                paddingHorizontal={16}
                radius={8}
                onClick={onClickGoToPayment}
                style={
                  products.length < 1 && { opacity: 0.3, cursor: 'not-allowed' }
                }
                className="m-top-20"
              />
            </div>
          </div>
        </div>
      </WrapperCart>
    </div>
  );
};

export default withRouter(Cart);
