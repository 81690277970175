import actionCreator from "../../../../../utils/action-creator";
import { actionTypes as userAddressesTypes } from "./userAddressesThunk";
import axios from "../../../../../services/axios";
/**
 * Types
 */
export const actionTypes = {
  FETCH_SELECTED_ADDRESS_BY_ID: "@app/user/addresses/userEditAddressThunk/FETCH_SELECTED_ADDRESS_BY_ID",
  EDIT_ADDRESS: "@app/user/addresses/userEditAddressThunk/EDIT_ADDRESS",
  EDIT_ADDRESS_SUCCESS: "@app/user/addresses/userEditAddressThunk/EDIT_ADDRESS_SUCCESS",
  EDIT_ADDRESS_ERROR: "@app/user/addresses/userEditAddressThunk/EDIT_ADDRESS_ERROR",
  SET_STATUS: "@app/user/addresses/userEditAddressThunk/SET_STATUS",
  SET_MODAL_VISIBILITY: "@app/user/addresses/userEditAddressThunk/SET_MODAL_VISIBILITY",
  SET_HIGHLIGHTED_ID_ADDRESS: "@app/user/addresses/userEditAddressThunk/SET_HIGHLIGHTED_ID_ADDRESS",
};

/**
 * Initial state
 */
const initialState = {
  addressesSelected: null,
  isLoading: false,
  errorMessage: "",
  status: 0,
  modalVisibility: false,
  highlightedAddressId: null,
};

/**
 * Selectors
 */
export const selectors = {
  getIsLoading: (state) => state.app.user.addresses.userEditAddress.isLoading,
  getErrorMessage: (state) => state.app.user.addresses.userEditAddress.errorMessage,
  getStatus: (state) => state.app.user.addresses.userEditAddress.status,
  getModalVisibility: (state) => state.app.user.addresses.userEditAddress.modalVisibility,
  getAddressSelected: (state) => state.app.user.addresses.userEditAddress.addressesSelected,
  getHighlightedId: (state) => state.app.user.addresses.userEditAddress.highlightedAddressId,
};

/**
 * Reducer
 */
const {
  EDIT_ADDRESS,
  EDIT_ADDRESS_ERROR,
  EDIT_ADDRESS_SUCCESS,
  SET_MODAL_VISIBILITY,
  SET_STATUS,
  FETCH_SELECTED_ADDRESS_BY_ID,
  SET_HIGHLIGHTED_ID_ADDRESS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SELECTED_ADDRESS_BY_ID:
      return {
        ...state,
        addressesSelected: payload,
      };
    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case EDIT_ADDRESS:
      return {
        ...state,
        isLoading: true,
        modalVisibility: false,
        errorMessage: "",
      };
    case EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 200,
        highlightedAddressId: state.addressesSelected.id,
      };
    case EDIT_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404,
        modalVisibility: true,
      };
    case SET_HIGHLIGHTED_ID_ADDRESS:
      return {
        ...state,
        highlightedAddressId: payload,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  setAddressSelected: function (id) {
    return (dispatch, getState) => {
      try {
        const { list } = getState().app.user.addresses.userAddresses;
        const selectedAddress = list.find((item) => item.id === id);
        if (selectedAddress) {
          dispatch(actionCreator(FETCH_SELECTED_ADDRESS_BY_ID, selectedAddress));
          return;
        }
      } catch (err) {
        // console.log("error set Address selected", err);
      }
    };
  },
  editAddress: function (address) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(EDIT_ADDRESS));
        const { list } = getState().app.user.addresses.userAddresses;

        const response = await axios.put(`/customer/address/${address.id}/`, {
          ...address,
          city: address.city.value || address.city
        });

        if (response) {
          const newList = list.map((el) => el.id === response.id ? response : el);

          dispatch(actionCreator(userAddressesTypes.FETCH_USER_ADDRESSES_SUCCESS, [
            ...newList,
          ]));
          dispatch(actionCreator(EDIT_ADDRESS_SUCCESS));
          return;
        }

        dispatch(EDIT_ADDRESS_ERROR, "Something went wrong!");
      } catch (err) {
        dispatch(actionCreator(EDIT_ADDRESS_ERROR, err.message));
      }
    };
  },
  resetAddressSelected: () => actionCreator(FETCH_SELECTED_ADDRESS_BY_ID, null),
  setModalVisibility: (visibility) => actionCreator(SET_MODAL_VISIBILITY, visibility),
  setStatus: (status) => actionCreator(SET_STATUS, status),
  setHighlightedId: (id) => actionCreator(SET_HIGHLIGHTED_ID_ADDRESS, id),
};
