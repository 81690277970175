import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { lazy } from "react";

import {
  admin_routes,
  ADMIN_ALL_STAFF_PATH,
  ADMIN_ORDERS_PATH,
  ADMIN_CUSTOMERS_PATH,
  ADMIN_BASE_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_PRODUCTS_PATH,
  ADMIN_CATEGORIES_PATH,
  ADMIN_ADD_CATEGORY_PATH,
  ADMIN_EDIT_CATEGORY_PATH,
  ADMIN_OFFERS_PATH,
  ADMIN_ADD_OFFERS_PATH,
  ADMIN_ADD_PRODUCT_PATH,
  ADMIN_PRODUCT_DETAILS,
  ADMIN_ORDER_DETAILS_PATH,
  ADMIN_SETTINGS_PATH,
  ADMIN_ADD_STAFF_PATH,
  ADMIN_EDIT_STAFF_PATH,
  ADMIN_BANNER_PATH,
  ADMIN_ADD_BANNER_PATH,
  ADMIN_EDIT_OFFER
} from "../utils/constants";

import AdminLayout from "../layouts/AdminLayout";
import AdminProtectedRoute from "../utils/AdminProtectedRoute";

const Dashboard = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Dashboard")
);
const DashboardProducts = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Products")
);
const Customers = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Customers")
);
const Orders = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Orders")
);
const Offers = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Offers")
);
const EditOffer = lazy(() => 
  import("./../pages/admin-pages/Offers/EditOffer")
)
const AddOffers = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Offers/AddOffers")
);
const Categories = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Categories")
);
const AddProduct = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/AddProduct")
);
const OrderDetails = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Orders/OrderDetails"
  )
);
const AdminSettings = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Settings/AdminSettings"
  )
);
const AdminProductDetails = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Products/ProductDetails/ProductDetailsPage"
  )
);
const AddCategory = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Categories/AddCategory/AddCategoryPage"
  )
);
const Staff = lazy(() =>
  import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Staff/Staff")
);

const AddStaff = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Staff/Sections/AddStaff/AddStaff"
  )
);

const EditStaff = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Staff/Sections/EditStaff/EditStaff"
  )
);

const Banner = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Banner"
  )
);
const AddBanner = lazy(() =>
  import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Banner/AddBanner"
  )
);

function AdminRoutes() {
  return (
    <Route
      exact
      path={admin_routes}
      render={(props) => {
        const { location } = props;
        return (
          <AdminLayout>
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={location.key}
                timeout={{ enter: 300, exit: 300 }}
                classNames={"fade"}
              >
                <section className="route-section">
                  <Switch location={location}>
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ORDERS_PATH}
                      component={Orders}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_CUSTOMERS_PATH}
                      component={Customers}
                    />
                    <AdminProtectedRoute
                      exact
                      path={[ADMIN_BASE_PATH, ADMIN_DASHBOARD_PATH]}
                      component={Dashboard}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_PRODUCTS_PATH}
                      component={DashboardProducts}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_CATEGORIES_PATH}
                      component={Categories}
                    />
                    <AdminProtectedRoute
                      exact
                      path={[ADMIN_ADD_CATEGORY_PATH, ADMIN_EDIT_CATEGORY_PATH]}
                      component={AddCategory}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_OFFERS_PATH}
                      component={Offers}
                    />
                        <AdminProtectedRoute
                      exact
                      path={ADMIN_ADD_OFFERS_PATH}
                      component={AddOffers}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ADD_PRODUCT_PATH}
                      component={AddProduct}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_PRODUCT_DETAILS}
                      component={AdminProductDetails}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ORDER_DETAILS_PATH}
                      component={OrderDetails}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_SETTINGS_PATH}
                      component={AdminSettings}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ALL_STAFF_PATH}
                      component={Staff}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ADD_STAFF_PATH}
                      component={AddStaff}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_EDIT_STAFF_PATH}
                      component={EditStaff}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_BANNER_PATH}
                      component={Banner}
                    />
                    <AdminProtectedRoute
                      exact
                      path={ADMIN_ADD_BANNER_PATH}
                      component={AddBanner}
                    />
                       <AdminProtectedRoute
                      exact
                      path={ADMIN_EDIT_OFFER}
                      component={EditOffer}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </AdminLayout>
        );
      }}
    />
  );
}
export default AdminRoutes;
