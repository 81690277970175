import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { lazy } from "react";

import {
    picker_routes,
    PICKER_ORDERS_PATH,
    PICKER_ORDER_DETAILS_PATH,
    PICKER_SETTINGS_PATH,
} from "../utils/constants";

import AdminLayout from "../layouts/AdminLayout";
import PickerProtectedRoute from "../utils/PickerProtectedRoute";

const Orders = lazy(() =>
    import(/* webpackChunkName:"admin" */ "./../pages/admin-pages/Orders")
);
const OrderDetails = lazy(() =>
    import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Orders/OrderDetails"
    )
);
const AdminSettings = lazy(() =>
    import(
    /* webpackChunkName:"admin" */ "./../pages/admin-pages/Settings/AdminSettings"
    )
);

function PickerRoutes() {
    return (
        <Route
            exact
            path={picker_routes}
            render={(props) => {
                const { location } = props;
                return (
                    <AdminLayout>
                        <TransitionGroup className="transition-group">
                            <CSSTransition
                                key={location.key}
                                timeout={{ enter: 300, exit: 300 }}
                                classNames={"fade"}
                            >
                                <section className="route-section">
                                    <Switch location={location}>
                                        <PickerProtectedRoute
                                            exact
                                            path={PICKER_ORDERS_PATH}
                                            component={Orders}
                                        />
                                        <PickerProtectedRoute
                                            exact
                                            path={PICKER_ORDER_DETAILS_PATH}
                                            component={OrderDetails}
                                        />
                                        <PickerProtectedRoute
                                            exact
                                            path={PICKER_SETTINGS_PATH}
                                            component={AdminSettings}
                                        />
                                    </Switch>
                                </section>
                            </CSSTransition>
                        </TransitionGroup>
                    </AdminLayout>
                );
            }}
        />
    );
}
export default PickerRoutes;
