import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import {
  public_routes,
  HOME_PATH,
  PRODUCTS_PATH,
  PRODUCTS_PATH_CATEGORY,
  PRODUCTS_PATH_SUB_CATEGORY,
  PRODUCT_DETAILS_PATH,
  ABOUT_US_PATH,
  CONTACT_PATH,
  PAYMENT_PATH,
  NOT_FOUND_PATH,
  TERMS_AND_CONDITIONS_PATH,
  SALES_PATH,
  ADMIN_OFFER_DETAIL_PATH,
} from "../utils/constants";
import MainLayout from "../layouts/MainLayout";

/**
 * Pages import
 */
const AboutUs = lazy(() =>
  import(/* webpackChunkName:"public" */ "./../pages/user-pages/public/AboutUs")
);
const Contact = lazy(() =>
  import(/* webpackChunkName:"public" */ "./../pages/user-pages/public/Contact")
);
const Payment = lazy(() =>
  import(/* webpackChunkName:"public" */ "./../pages/user-pages/Payment")
);
const Home = lazy(() =>
  import(/* webpackChunkName:"public" */ "./../pages/user-pages/public/Home")
); //lazy(() => import('./pages/Home'));
const Products = lazy(() =>
  import(
    /* webpackChunkName:"public" */ "./../pages/user-pages/public/Products"
  )
);
const ProductDetails = lazy(() =>
  import(
    /* webpackChunkName:"public" */ "./../pages/user-pages/public/ProductDetails"
  )
);
const NotFound = lazy(() => import("./../pages/common/NotFound"));
const TermsAndConditions = lazy(() =>
  import("./../pages/common/TermsAndConditions")
);
const Sales = lazy(() =>
  import(
    /* webpackChunkName:"public" */ "./../pages/user-pages/public/Products/sale"
  )
);
const OfferDetails = lazy(() =>
  import("../components/admin-components/OfferDetails/index")
);

function PublicRoutes() {
  return (
    <Route
      exact
      path={public_routes}
      render={(props) => {
        const { location } = props;

        return (
          <MainLayout>
            <Switch location={location}>
              <Route exact path={HOME_PATH} component={Home} />
              <Route
                exact
                path={[
                  PRODUCTS_PATH,
                  PRODUCTS_PATH_CATEGORY,
                  PRODUCTS_PATH_SUB_CATEGORY,
                ]}
                component={Products}
              />
              <Route
                exact
                path={PRODUCT_DETAILS_PATH}
                component={ProductDetails}
              />
              <Route exact path={ABOUT_US_PATH} component={AboutUs} />
              <Route exact path={CONTACT_PATH} component={Contact} />
              <Route exact path={PAYMENT_PATH} component={Payment} />
              <Route exact path={NOT_FOUND_PATH} component={NotFound} />
              <Route
                exact
                path={TERMS_AND_CONDITIONS_PATH}
                component={TermsAndConditions}
              />
              <Route exact path={SALES_PATH} component={Sales} />
              <Route
                exact
                path={ADMIN_OFFER_DETAIL_PATH}
                component={OfferDetails}
              />
            </Switch>
          </MainLayout>
        );
      }}
    />
  );
}

export default PublicRoutes;
