import "./style.scss";
import PropTypes from "prop-types";
import { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const Input = ({
  style,
  disabled,
  className,
  value,
  autoComplete = "random",
  placeholder = "Shkruaj këtu",
  id,
  onChange,
  required,
  type,
  ref,
  name,
  renderRight,
  ...restProps
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div
      className={`inputContainer ${disabled && "inputDisabled"} ${className}`}
      style={{ ...style }}
    >
      <input
        id={id}
        required={required}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        type={
          type === "password" ? (passwordVisible ? "text" : "password") : type
        }
        className="inputContainer__input"
        ref={ref}
        name={name}
        // autoComplete={autoComplete}
        {...restProps}
      />
      {renderRight && renderRight()}
      {type === "password" && (
        <button
          className="noStyleButton inputContainer__eyeButton"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setPasswordVisible((visible) => !visible);
          }}
        >
          {passwordVisible ? (
            <BsFillEyeSlashFill className="inputContainer__eyeButton__icon" />
          ) : (
              <BsFillEyeFill className="inputContainer__eyeButton__icon" />
            )}
        </button>
      )}
    </div>
  );
};

Input.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  id: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  ref: PropTypes.object,
  name: PropTypes.string,
};

Input.defaultProps = {
  autoComplete: "random",
  placeholder: "Shkruaj këtu",
};

export default Input;
