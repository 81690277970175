import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";
import Axios from "axios";
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";
import { format } from 'date-fns';
/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@app/admin/banner/adminBannerThunk/REQUEST",
  SUCCESS: "@app/admin/banner/adminBannerThunk/SUCCESS",
  ERROR: "@app/admin/banner/adminBannerThunk/ERROR",
  SET_MORE_LOADING: "@app/admin/banner/adminBannerThunk/SET_MORE_LOADING",
  SET_LINKS_NULL: "@app/admin/banner/banner/SET_LINKS_NULL",
};

/**
 * Initial state
 */
const initialState = {
  errorMessage: null,
  isLoading: false,
  hasMoreLoading: false,
  images: [],
  data: {
    count: 0,
    results: [],
    links: {
      next: null,
      previous: null
    },
    total_pages: 0
  },
};

export const selectors = {
  getData: state => state.app.admin.banner.adminBanner.data,
  getIsLoading: state => state.app.admin.banner.adminBanner.isLoading,
  getErrorMessage: state => state.app.admin.banner.adminBanner.errorMessage,
  getMoreLoading: state => state.app.admin.banner.adminBanner.hasMoreLoading,
  getImages: state => state.app.admin.banner.adminBanner.images,
};

/**
 * Reducer
 */
const {
  ERROR,
  REQUEST,
  SUCCESS,
  SET_MORE_LOADING,
  SET_LINKS_NULL
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MORE_LOADING:
      return {
        ...state,
        hasMoreLoading: payload,
      };
    case REQUEST:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        images: payload.response,
        hasMoreLoading: false
      };
    case SET_LINKS_NULL:
      return {
        ...state,
        data: {
          ...state.data,
          links: {
            ...state.data.links,
            next: null
          }
        }
      }
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  request: function () {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(REQUEST));
        const response = await axios.get(`/shop/banner/`);
        dispatch(actionCreator(SUCCESS, {response}))
      } catch (err) {
        if (err?.response?.data?.detail === "Invalid page.") {
          dispatch(actionCreator(SET_LINKS_NULL));
        }
        dispatch(actionCreator(ERROR, err.message));
      }
    };
  },
  searchBanner: function (value) {
    return async (dispatch, getState) => {

    };
  },
};
/**
 * function - helpers
 */
// const fetchProductsListBegin = () => actionCreator(REQUEST);
// const fetchProductsListError = (err) => actionCreator(ERROR, err);
// const fetchProductsListSuccess = (data) => actionCreator(SUCCESS, data);

// const setPagesNumber = (number) => actionCreator(SET_PRODUCTS_PAGES_NUMBER, number);
// const setPagesOffset = (offset) => actionCreator(SET_PRODUCTS_PAGE_OFFSET, offset);
/**
 * Selectors
 */
// export const getProductsList = (state) => state.app.admin.products.adminProducts.productsList;
// export const getProductsListError = (state) => state.app.admin.products.adminProducts.error;
// export const getProductsListLoading = (state) => state.app.admin.products.adminProducts.loading;
// export const getProductsPageOffset = (state) => state.app.admin.products.adminProducts.pageOffset;
// export const getProductsPagesNumber = (state) => state.app.admin.products.adminProducts.pages;
