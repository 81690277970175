import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const ADMIN_START_EDIT_STAFF = "@admin/staff/ADMIN_START_EDIT_STAFF";
const ADMIN_ERROR_EDIT_STAFF = "@admin/staff/ADMIN_ERROR_EDIT_STAFF";
const ADMIN_SUCCESS_EDIT_STAFF = "@admin/staff/ADMIN_SUCCESS_EDIT_STAFF";
const ADMIN_RESET_EDIT_STAFF = "@admin/staff/ADMIN_RESET_EDIT_STAFF";

const initialState = {
  loading: false,
  error: null,
  status: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_EDIT_STAFF:
      return {
        loading: true,
        error: null,
        status: null,
      };
    case ADMIN_ERROR_EDIT_STAFF:
      return {
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ADMIN_SUCCESS_EDIT_STAFF:
      return {
        loading: false,
        error: null,
        status: 200,
      };
    case ADMIN_RESET_EDIT_STAFF:
      return {
        loading: false,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};

export default reducer;

export const getEditStaffState = (state) =>
  state.app.admin.staff.editAdminStaff;

export const editStaff = (staffData, id) => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_START_EDIT_STAFF));

    const data = {
      first_name: staffData?.first_name,
      last_name: staffData?.last_name,
      email: staffData?.email,
      group: staffData?.group,
      password: staffData?.password,
      password2: staffData?.password2,
      phone_number: staffData?.phone_number,
    };

    await axios
      .put(`/shop/staff-user/${id}/`, data)
      .then((res) => {
        dispatch(actionCreator(ADMIN_SUCCESS_EDIT_STAFF));
      })
      .catch((err) => {
        dispatch(
          actionCreator(ADMIN_ERROR_EDIT_STAFF, {
            status: 404,
            error: "could not edit staff",
          })
        );
      });
  };
};

export const resetEditStaffState = () => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_RESET_EDIT_STAFF));
  };
};
