import actionCreator from "../../../utils/action-creator";
/**
 * Types
 */
export const actionTypes = {
    SET_MODAL_VISIBLE: "@auth/authModalThunk/SET_MODAL_VISIBLE",
};
/**
 * Initial state
 */
const initialState = {
    modalVisible: false,
};

/**
 * selectors
 */
export const selectors = {
    getModalVisible: state => state.auth.authModal.modalVisible
};

/**
 * Reducer
 */
const { SET_MODAL_VISIBLE } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_MODAL_VISIBLE:
            return {
                ...state,
                modalVisible: payload
            }
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    setModalVisible: (visible) => actionCreator(SET_MODAL_VISIBLE, visible)
};
