import { CART_STORAGE_KEY } from "../utils/constants";
import { getDiscountedPrice } from "../utils/utility-functions";

/**
 *  @para no params
 *
 * return => cart as object from local storage
 */
const getCartFromStorage = () => {
    let cart;
    try {
        cart = JSON.parse(localStorage.getItem(CART_STORAGE_KEY));
    } catch (err) {
        // console.log("err", err);
    } finally {
        return cart
    }
};

/**
 *
 * @param {Object} cart
 *
 * void => 1.set cart in local storage, calculate total price, calculate price in each product
 */
const setCartInStorage = cart => {
    const totalPrice = getTotalPriceOfCart(cart);
    const productsWithTotal = getProductsWithTotalPrice(cart.products);
    let cartPayload = { ...cart, products: productsWithTotal, totalPrice };

    const stringifiedCart = JSON.stringify(cartPayload);
    localStorage.setItem(CART_STORAGE_KEY, stringifiedCart);
};


/**
 * @param {Object} cartObject
 *
 * return => total price of cart with discounted price calculated
 */
const getTotalPriceOfCart = (cartObject = getCartFromStorage()) => {
    const cart = cartObject;
    let totalPrice = 0;

    cart?.products.forEach(el => {
        if (el.discount_percentage) {
            totalPrice += el?.quantityInCart * getDiscountedPrice(el?.price, el?.discount_percentage, false);
        }
        else {
            totalPrice += el?.quantityInCart * el.price;
        }
    });

    return totalPrice;
}

/**
 *
 * @param {Array} products
 *
 * return => a new array with existed products, and adds a total field with calculated price based on discount
 */
const getProductsWithTotalPrice = (products) => {
    let modifiedProducts = [];

    modifiedProducts = products.map(el => {
        let total = 0;

        if (el.discount_percentage) {
            total += el?.quantityInCart * getDiscountedPrice(el?.price, el?.discount_percentage, false)
        } else {
            total += el?.quantityInCart * el?.price;
        }

        return { ...el, total };
    });

    return modifiedProducts;
};

export { getCartFromStorage, setCartInStorage, getTotalPriceOfCart, getProductsWithTotalPrice };
