import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import { format } from 'date-fns';

/**
 * Types
 */
export const actionTypes = {
    FETCH_USER_ORDERS: '@app/user/orders/userOrdersThunk/FETCH_USER_ORDERS',
    FETCH_USER_ORDERS_SUCCESS: '@app/user/orders/userOrdersThunk/FETCH_USER_ORDERS_SUCCESS',
    FETCH_USER_ORDERS_ERROR: '@app/user/orders/userOrdersThunk/FETCH_USER_ORDERS_ERROR',
}
/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    data: {
        links: {
            next: null,
            previous: null
        },
        count: 0,
        total_pages: 1,
        results: []
    },
    errorMessage: ''
};

/**
 * Selectors
 */
export const selectors = {
    getIsLoading: state => state.app.user.orders.userOrders.isLoading,
    getErrorMessage: state => state.app.user.orders.userOrders.errorMessage,
    getData: state => state.app.user.orders.userOrders.data
};

/**
 * Reducer
 */
const { FETCH_USER_ORDERS, FETCH_USER_ORDERS_ERROR, FETCH_USER_ORDERS_SUCCESS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_ORDERS:
            return {
                ...state,
                isLoading: true,
                errorMessage: ''
            };
        case FETCH_USER_ORDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload
            };
        case FETCH_USER_ORDERS_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query) {
        return async (dispatch, getState) => {
            try {
                if (query?.page <= 1) {
                    dispatch(actionCreator(FETCH_USER_ORDERS));
                }
                const response = await axios.get(`/customer/orders/?${new URLSearchParams(query)}`);

                const results = response.results.map(x => ({
                    ...x,
                    products: x.products.map(el => ({
                        ...el,
                        ...el.product,
                        thumbnail: el.product?.thumbnail[0] ? el.product.thumbnail[0]?.thumbnail : null
                    })),
                    created_at: format(new Date(x.created_at), "d MMM y, HH:mm")
                }));

                if (query?.page > 1) {
                    const previousList = getState().app.user.orders.userOrders.data.results;

                    dispatch(actionCreator(FETCH_USER_ORDERS_SUCCESS, {
                        ...response,
                        results: [
                            ...previousList,
                            ...results
                        ],
                    }));
                    return;
                }

                dispatch(actionCreator(FETCH_USER_ORDERS_SUCCESS, {
                    ...response,
                    results,
                }));
            }
            catch (err) {
                // console.log({ err });
                // debugger;
                dispatch(actionCreator(FETCH_USER_ORDERS_ERROR, err));
            }
        };
    }
};



