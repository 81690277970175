import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import { euroFormatter } from "../../../../../utils/number-formatters";
import { getFormattedDate, getProductsCount } from "../../../../../utils/utility-functions";

/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@app/dashboard/orders/adminOrdersThunk/REQUEST",
  SUCCESS: "@app/dashboard/orders/adminOrdersThunk/SUCCESS",
  FAILURE: "@app/dashboard/orders/adminOrdersThunk/FAILURE",
};

/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: '',
  data: {
    count: 0,
    results: [],
    links: {
      next: null,
      previous: null
    },
    total_pages: 0
  },
};

/**
 * selectors
 */
export const selectors = {
  getIsLoading: state => state.app.admin.orders.adminOrders.isLoading,
  getErrorMessage: state => state.app.admin.orders.adminOrders.errorMessage,
  getData: state => state.app.admin.orders.adminOrders.data,
};

/**
 * Reducer
 */
const {
  REQUEST,
  SUCCESS,
  FAILURE,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  request: function (query) {
    return async (dispatch, getState) => {
      try {
        if (query?.page === 1) {
          dispatch(actionCreator(REQUEST));
        }

        const res = await axios.get(`/shop/orders/?${new URLSearchParams(query)}`);
        const response = {
          ...res,
          results: res.results.map(el => ({
            ...el,
            total_amount: euroFormatter(el.total_amount),
            created_at: getFormattedDate(el.created_at),
            product_count: getProductsCount(el.product),
            customer_fullname: `${el.customer?.first_name || el?.shipping_address?.first_name} ${el.customer?.last_name || el?.shipping_address.last_name}`,
            address: el.shipping_address.address,
          }))
        }

        if (query?.page > 1) {
          const { results } = getState().app.admin.orders.adminOrders.data;
          dispatch(actionCreator(SUCCESS, {
            ...response,
            results: [...results, ...response.results]
          }));
          return;
        }

        dispatch(actionCreator(SUCCESS, response));
      } catch (err) {
        dispatch(actionCreator(FAILURE, err.message));
      }
    }
  },
};