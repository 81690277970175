import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from '../../../../../components/common/alerts/Toast';
import { USER_WISH_LIST_PATH } from "../../../../../utils/constants";

/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/wish-list/addWishListThunk/REQUEST",
    SUCCESS: "@app/user/wish-list/addWishListThunk/SUCCESS",
    ERROR: "@app/user/wish-list/addWishListThunk/ERROR",

    SET_PRODUCT_SELECTED: "@app/user/wish-list/addWishListThunk/SET_PRODUCT_SELECTED",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    productSelected: null
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.user.wishList.addWishList.isLoading,
    getProductSelected: state => state.app.user.wishList.addWishList.productSelected,
};

/**
 * Reducer
 */
const {
    REQUEST,
    ERROR,
    SUCCESS,
    SET_PRODUCT_SELECTED
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRODUCT_SELECTED:
            return {
                ...state,
                productSelected: payload
            };
        case REQUEST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };

        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function (product_id) {
        return async (dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));

                const response = await axios.post(`/customer/wishlist/${product_id}/`);
                toast.successClickableMessage("Produkti u shtua ne listen e deshirave", null, USER_WISH_LIST_PATH);
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                if (err?.response?.data?.message) {
                    toast.successClickableMessage("Ky produkt tashme ekziston ne listen e deshirave!", null, USER_WISH_LIST_PATH)
                }
                else {
                    toast.error(err?.response?.data?.message || err.message)
                }
                dispatch(actionCreator(ERROR, err?.response?.data || err.message));
            }
        };
    },
    setProductSelected: product => actionCreator(SET_PRODUCT_SELECTED, product),
    resetProduct: () => actionCreator(SET_PRODUCT_SELECTED, null),
};