import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const FETCH_PICKERS_ADMIN_SUCCESS = "@admin/orders/FETCH_PICKERS_ADMIN_SUCCESS";
const FETCH_PICKERS_ADMIN_ERROR = "@admin/orders/FETCH_PICKERS_ADMIN_ERROR";
const FETCH_PICKERS_ADMIN_START = "@admin/orders/FETCH_PICKERS_ADMIN_START";

const initialState = {
    loading: false,
    error: null,
    status: null,
    pickers: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PICKERS_ADMIN_START:
            return {
                loading: true,
                error: null,
                status: null,
                pickers: null
            }
        case FETCH_PICKERS_ADMIN_SUCCESS:
            return {
                loading: false,
                error: null,
                status: null,
                pickers: action.payload
            }
        case FETCH_PICKERS_ADMIN_ERROR:
            return {
                loading: false,
                error: action.payload,
                status: null,
                pickers: null
            }

        default:
            return state;
    }
}

export default reducer;

export const getPickersState = state => state.app.admin.orders.pickers;

export const fetchPickers = () => {
    return async dispatch => {
        dispatch(actionCreator(FETCH_PICKERS_ADMIN_START));

        await axios.get("/shop/pickers/").then(res => {
            dispatch(actionCreator(FETCH_PICKERS_ADMIN_SUCCESS, res))
        }).catch(err => {
            dispatch(actionCreator(FETCH_PICKERS_ADMIN_ERROR, err))
        })
    }
}