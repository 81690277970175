import './style.scss';
import Modal from "react-modal";
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CgClose } from 'react-icons/cg';

/**
 * Components
 */
import LoginUI from '../../auth/LoginUI';

/**
 * utils
 */
import { LoginSchema } from '../../../utils/validationSchemas';
import { actions as loginActions } from '../../../redux/thunk/auth/loginThunk';
import { useDispatch } from 'react-redux';
import { Fade } from 'react-awesome-reveal';



const LoginModal = ({
    modalStyle,
    isModalVisible,
    onCloseModal,
    errorMessage,
    isLoading,
    onClickRegister,
    onSuccessfullySubmit,
}) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
        reValidateMode: "onChange",
        mode: "onSubmit",
        resolver: yupResolver(LoginSchema),
    });

    /**
     * Effects
     */
    useEffect(() => {
        if (isModalVisible) {
            registerInputs();
        }
    }, [isModalVisible]);

    const registerInputs = () => {
        register({ name: "name" });
        register({ name: "password" });
    };

    const onChangeInput = (key, value) => {
        errors[key] && clearErrors(key);
        errorMessage && dispatch(loginActions.resetError());
        return setValue(key, value);
    };

    const handleLoginSubmit = (values) => {
        if (errors.name || errors.password) {
            return;
        }
        onSuccessfullySubmit(values);
    };

    return (
        <Modal
            className="loginModal"
            style={{
                overlay: {
                    zIndex: 999,
                    maxWidth: '100%'
                },
                content: {
                    maxWidth: '100%'
                }
            }}
            isOpen={isModalVisible}
            shouldCloseOnEsc
            ariaHideApp={false}
            onRequestClose={onCloseModal}
        >
            <Fade className="authModalFade" triggerOnce duration={500}>
                <div className="loginModal__content">
                    <div className="loginModal__content__closeContainer">
                        <button onClick={onCloseModal} className="noStyleButton p-10">
                            <CgClose className="loginModal__content__closeContainer__icon" />
                        </button>
                    </div>
                    <LoginUI
                        errors={errors}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        onChangeInput={onChangeInput}
                        onClickRegister={onClickRegister}
                        handleSubmit={handleSubmit(handleLoginSubmit)}
                        showGuesst={false}
                        showTerms={false}
                    />
                </div>
            </Fade>
        </Modal>
    );
};

export default LoginModal;