import "./style.scss";
/**
 * icons - assets
 */
import { BiPlus, BiMinus } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa";

const PlusMinusQuantity = ({
  quantity,
  onClickMinus,
  onClickPlus,
  onChangeQuantity,
  style,
  bold,
}) => (
  <div className="plusMinusQuantity" style={style}>
    {bold ? (
      <button
        className="noStyleButton"
        onClick={() => {
          onClickMinus();
          window.getSelection().removeAllRanges();
        }}
      >
        <FaMinus
          className="plusMinusQuantity__icon"
          style={{ fontSize: "small" }}
        />
      </button>
    ) : (
      <button
        className="noStyleButton"
        onClick={() => {
          onClickMinus();
          window.getSelection().removeAllRanges();
        }}
      >
        <BiMinus className="plusMinusQuantity__icon" />
      </button>
    )}

    <input
        onChange={(e)=>onChangeQuantity(e.target.value)}
      className={`plusMinusQuantity__quantity ${
        bold && "plusMinusQuantity__quantity--bold"
      }`}
        value={quantity}
    />
    {bold ? (
      <button
        onClick={() => {
          onClickPlus();
          window.getSelection().removeAllRanges();
        }}
        className="noStyleButton"
      >
        <FaPlus
          className="plusMinusQuantity__icon"
          style={{ fontSize: "small" }}
        />
      </button>
    ) : (
      <button
        className="noStyleButton"
        onClick={() => {
          onClickPlus();
          window.getSelection().removeAllRanges();
        }}
      >
        <BiPlus className="plusMinusQuantity__icon" />
      </button>
    )}
  </div>
);

export default PlusMinusQuantity;
