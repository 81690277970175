import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const ADMIN_START_GET_STAFF = "@admin/staff/ADMIN_START_GET_STAFF";
const ADMIN_ERROR_GET_STAFF = "@admin/staff/ADMIN_ERROR_GET_STAFF";
const ADMIN_SUCCESS_GET_STAFF = "@admin/staff/ADMIN_SUCCESS_GET_STAFF";

const initialState = {
  loading: false,
  error: null,
  status: null,
  staff: null,
  links: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_GET_STAFF:
      return {
        loading: true,
        error: null,
        status: null,
      };
    case ADMIN_ERROR_GET_STAFF:
      return {
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ADMIN_SUCCESS_GET_STAFF:
      return {
        loading: false,
        error: null,
        status: 200,
        staff: action.payload.staff,
        links: action.payload.links,
      };
    default:
      return state;
  }
};

export default reducer;

export const getAllStaffState = (state) => state.app.admin.staff.getAdminStaff;

export const getStaff = () => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_START_GET_STAFF));

    await axios
      .get(`/shop/staff-users/`)
      .then((res) => {
        dispatch(
          actionCreator(ADMIN_SUCCESS_GET_STAFF, {
            staff: res.results,
            links: res.links,
          })
        );
      })
      .catch((err) => {
        dispatch(
          actionCreator(ADMIN_ERROR_GET_STAFF, {
            status: 404,
            error: "could not get staff",
          })
        );
      });
  };
};
