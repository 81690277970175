import loader_gif from "../../assets/images/loader_gif.gif";
import React from 'react'

const Loader = ({ style, className, imageStyle }) => (
  <div
    className={className}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 100,
      ...style,
    }}
  >
    <img
      style={{ height: "100%", objectFit: "cover", ...imageStyle }}
      src={loader_gif}
      alt="loader gif"
    />
  </div>
);

export default React.memo(Loader);
