import actionCreator from "../../../../../utils/action-creator";
import { ADMIN_BANNER_PATH } from "../../../../../utils/constants";
import * as toast from "../../../../../components/common/alerts/Toast";
import axios from '../../../../../services/axios';
import { useHistory } from "react-router-dom";

export const actionTypes = {
  ADMIN_BANNER: "@app/admin/ADMIN_BANNER/ADMIN_BANNER",
  ADMIN_BANNER_ERROR: "@app/admin/ADMIN_BANNER/ADMIN_BANNER_ERROR",
  ADMIN_BANNER_SUCCESS: "@app/admin/ADMIN_BANNER/ADMIN_BANNER_SUCCESS",
  RESET_STATUS: "@app/admin/ADMIN_BANNER/RESET_STATUS",

  SET_BANNERS_LIST: "@app/admin/ADMIN_BANNER/SET_PRODUCTS_LIST",
};
/**
 * Initial state
 */
const initialState = {
  banners: [],
  isLoading: false,
  errorMessage: null,
  status: 0,
};

/**
 * selectors
 */
export const selectors = {
//   getIsLoadingAddProduct: (state) => state.app.admin.products.adminAddProduct.isLoading,
//   getErrorMessageAddProduct: (state) => state.app.admin.products.adminAddProduct.errorMessage,
//   getAddProductStatus: (state) => state.app.admin.products.adminAddProduct.status,
};

/**
 * Reducer
 */
const {
  ADMIN_BANNER,
  ADMIN_BANNER_SUCCESS,
  RESET_STATUS,
  ADMIN_BANNER_ERROR,
  SET_BANNERS_LIST,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADMIN_BANNER:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case ADMIN_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        status: 200,
      };
    case ADMIN_BANNER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404
      };
    case SET_BANNERS_LIST:
      return {
        ...state,
        banners: payload,
      };
    case RESET_STATUS:
      return {
        ...state,
        status: 0,
      };
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  addNewBanner: function (banner) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(ADMIN_BANNER));
        let data = new FormData();
         data.append('bannerimage', banner);
        const res = await axios({
          method: 'post',
          url: '/shop/banner/',
          data,
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        toast.successClickableMessage(`'${banner.name}' u shtua me sukses!`, null, ADMIN_BANNER_PATH);
        dispatch(actionCreator(ADMIN_BANNER_SUCCESS));
      } catch (error) {
        dispatch(actionCreator(ADMIN_BANNER_ERROR, error));
        toast.error(error?.response?.statusText, error.message);
      }
    };
  },
  resetStatus: function () {
    return actionCreator(RESET_STATUS);
  },
};
