import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from "../../../../../components/common/alerts/Toast";
import { actions as categoryDetailsActions } from "./adminCategoryDetailsThunk";

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/categories/adminEditCategoryThunk/REQUEST",
    SUCCESS: "@app/admin/categories/adminEditCategoryThunk/SUCCESS",
    ERROR: "@app/admin/categories/adminEditCategoryThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    status: 0,
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.categories.adminEditCategory.isLoading,
    getErrorMessage: state => state.app.admin.categories.adminEditCategory.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                status: 0
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                status: 200
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        case RESET_STATUS:
            return initialState;
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function ({ description, category, thumbnail, id }) {
        return async (dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                let data = new FormData();
                data.append('description', description);
                data.append('category', category);
                typeof thumbnail !== 'string' && data.append('thumbnail', thumbnail);

                const response = await axios({
                    method: 'put',
                    url: `/shop/category/${id}/`,
                    data,
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                toast.successClickableMessage("kategoria u ndryshua me suksess");
                dispatch(categoryDetailsActions.request(id, false));
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                let message = "";
                if (err?.response?.data) {
                    for (const [key, value] of Object.entries(err.response.data)) {
                        message = value;
                        break;
                    }
                }
                toast.error(message || err.message);
                dispatch(actionCreator(ERROR, message || err.message));
            };
        }
    },
    resetStatus: () => actionCreator(RESET_STATUS)
};

