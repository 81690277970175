import "./style.scss";
import { useHistory } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { RiErrorWarningFill, RiInformationLine } from "react-icons/ri";
import PropTypes from "prop-types";

const ClickableMessage = ({
  title,
  content,
  pushTo,
  onClick,
  errorOrSuccess,
  type
}) => {
  const history = useHistory();

  const messageType = {
    "error": {
      color: "#FF0033",
      icon: (
        <RiErrorWarningFill
          className="clickableMessage__checkIcon m-left-17"
          color="#FF0033"
        />
      )
    },
    "success": {
      color: "#2ba57b",
      icon: (
        <FaCheckCircle
          className="clickableMessage__checkIcon m-left-17"
          color="#2ba57b"
        />
      )
    },
    "warning": {
      color: "#FFCC00",
      icon: (
        <RiErrorWarningFill
          className="clickableMessage__checkIcon m-left-17"
          color="#FFCC00"
        />
      )
    }
  }

  const onClickMessage = () => {
    if (pushTo) {
      history.push(pushTo);
    }
    onClick && onClick();
  };

  return (
    <div onClick={onClickMessage} className="clickableMessage">
      <div
        className={`clickableMessage__leftLine ${errorOrSuccess === "error" && "clickableMessage__leftLine--error"}`}
        style={{ backgroundColor: messageType[type].color }}
      />
      {messageType[type]?.icon}
      <div className="clickableMessage__column m-left-17">
        <h2 style={{textTransform:'capitalize'}} className="clickableMessage__title">{title}</h2>
        {content && (
          <h2 className="clickableMessage__content m-top-8">{content}</h2>
        )}
      </div>
    </div>
  );
};

ClickableMessage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  pushTo: PropTypes.string,
  onClick: PropTypes.func,
  errorOrSuccess: PropTypes.bool,
  type: PropTypes.oneOf(["error", "success", "warning"])
};

export default ClickableMessage;
