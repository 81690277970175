import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";
/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/products/userProductsThunk/REQUEST",
    SUCCESS: "@app/user/products/userProductsThunk/SUCCESS",
    ERROR: "@app/user/products/userProductsThunk/ERROR",
    REQUEST_MORE: "@app/user/products/userProductsThunk/REQUEST_MORE",
};
/**
 * Initial state
 */

const initialState = {
    errorMessage: null,
    isLoading: false,
    data: {
        count: 0,
        results: [],
        total_pages: 0,
        links: {
            next: null,
            previous: null
        },
    },
};

export const selectors = {
    getData: state => state.app.user.products.userProducts.data,
    getIsLoading: state => state.app.user.products.userProducts.isLoading,
    getErrorMessage: state => state.app.user.products.userProducts.errorMessage,
};

/**
 * Reducer
 */
const {
    ERROR,
    REQUEST,
    SUCCESS,
    REQUEST_MORE
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                errorMessage: null,
                isLoading: true,
            };
        case REQUEST_MORE:
            return {
                ...state,
                errorMessage: null
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                data: { ...initialState.data }
            };
        case SUCCESS:
            return {
                ...state,
                errorMessage: null,
                isLoading: false,
                data: payload
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query) {
        if (!query.subcategory){
            delete query.subcategory;
        }
        return async (dispatch, getState) => {
            try {
                if (query?.page === 1) {
                    dispatch(actionCreator(REQUEST));
                } else {
                    dispatch(actionCreator(REQUEST_MORE));
                }
                const response = await axios.get(`/customer/products/?${new URLSearchParams(query)}`);

                if (query?.page > 1) {
                    const { results } = getState().app.user.products.userProducts.data;
                    dispatch(actionCreator(SUCCESS, {
                        ...response,
                        results: [...results, ...response.results.map(x => ({
                            ...x,
                            thumbnail: addBaseUrlToThumbnails(x.thumbnail)
                        }))]
                    }));
                    return;
                }

                dispatch(actionCreator(SUCCESS, {
                    ...response,
                    results: response.results.map(x => ({
                        ...x,
                        thumbnail: addBaseUrlToThumbnails(x.thumbnail)
                    }))
                }));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message));
            }
        };
    },
};
