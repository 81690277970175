import actionCreator from "../../../../../utils/action-creator";
import { getCartFromStorage, setCartInStorage, getTotalPriceOfCart } from "../../../../../services/cart-service";
import * as toast from "../../../../../components/common/alerts/Toast";
import { actions as cartActions } from "./userCartThunk";

/**
 * Types
 */
export const actionTypes = {};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
};

/**
 * selectors
 */
export const selectors = {};

/**
 * Reducer
 */
// const { } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (product_id) {
        return (dispatch) => {
            try {
                let cart = getCartFromStorage();
                let products = cart.products.filter(el => el.product_id !== product_id);
                setCartInStorage({ ...cart, products });
                dispatch(cartActions.request());
            } catch (err) {
                // console.log({ err })
            };
        }
    }
}; 