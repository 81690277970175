import { Route, useHistory } from "react-router-dom";
import { auth } from "../services/auth";
import { USER_BASE_PATH } from "./constants";

const Authorized = () => {
  const history = useHistory();
  history.push(USER_BASE_PATH);
  return <span></span>
};

function AuthRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !auth.getToken() || !auth.getDecodedJWT() ? <Component {...props} /> : <Authorized />
      }
      }
    />
  );
}

export default AuthRoute;
