import actionCreator from "../../../utils/action-creator";

/**
 * Types
 */
export const actionTypes = {
  SET_LOGOUT_LOADING: "@auth/logoutThunk/SET_LOGOUT_LOADING",
  RESET: "@auth/logoutThunk/RESET",
};
/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
};

/**
 * selectors
 */
export const selectors = {
  getIsLoading: (state) => state.auth.logout.isLoading,
};

/**
 * Reducer
 */
const {
  RESET,
  SET_LOGOUT_LOADING
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGOUT_LOADING:
      return {
        isLoading: payload
      }
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  reset: () => actionCreator(RESET),
  setLoading: loading => actionCreator(SET_LOGOUT_LOADING, loading)
};