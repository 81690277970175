import actionCreator from "../../../../../utils/action-creator";
import { actionTypes as addressesActionTypes } from "./userAddressesThunk";
import axios from "../../../../../services/axios";

/**
 * Types
 */
export const actionTypes = {
  DELETE_ADDRESS: "@app/user/addresses/userDeleteAddressThunk/DELETE_ADDRESS",
  DELETE_ADDRESS_SUCCESS: "@app/user/addresses/userDeleteAddressThunk/DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_ERROR: "@app/user/addresses/userDeleteAddressThunk/DELETE_ADDRESS_ERROR",
  SET_DELETE_MODAL: "@app/user/addresses/userDeleteAddressThunk/SET_DELETE_MODAL",
  SET_STATUS: "@app/user/addresses/userDeleteAddressThunk/SET_STATUS",
  SET_ID_TO_DELETE: "@app/user/addresses/userDeleteAddressThunk/SET_ID_TO_DELETE",
};

/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  isModalVisible: false,
  status: 0,
  addressIdSelected: "",
};

/**
 * Selectors
 */
export const selectors = {
  getIsLoading: (state) => state.app.user.addresses.userDeleteAddress.isLoading,
  getErrorMessage: (state) => state.app.user.addresses.userDeleteAddress.errorMessage,
  getStatus: (state) => state.app.user.addresses.userDeleteAddress.status,
  getModalVisibility: (state) => state.app.user.addresses.userDeleteAddress.isModalVisible,
  getAddressIdSelected: (state) => state.app.user.addresses.userDeleteAddress.addressIdSelected,
};

/**
 * Reducer
 */
const {
  DELETE_ADDRESS,
  DELETE_ADDRESS_ERROR,
  DELETE_ADDRESS_SUCCESS,
  SET_DELETE_MODAL,
  SET_STATUS,
  SET_ID_TO_DELETE,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DELETE_MODAL:
      return {
        ...state,
        isModalVisible: payload,
      };
    case SET_ID_TO_DELETE:
      return {
        ...state,
        addressIdSelected: payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        isModalVisible: false,
      };
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: 200,
      };
    case DELETE_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  deleteAddress: function () {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(DELETE_ADDRESS));

        const {
          addressIdSelected,
        } = getState().app.user.addresses.userDeleteAddress;
        const { list } = getState().app.user.addresses.userAddresses;

        await axios.delete(`/customer/address/${addressIdSelected}/`, { deleted: true });

        const newList = list.filter((item) => item.id !== addressIdSelected);
        dispatch(
          actionCreator(addressesActionTypes.FETCH_USER_ADDRESSES_SUCCESS, [
            ...newList,
          ])
        );
        dispatch(actionCreator(DELETE_ADDRESS_SUCCESS));
      } catch (err) {
        dispatch(actionCreator(DELETE_ADDRESS_ERROR, err));
      }
    };
  },
  setModalVisibility: (payload) => actionCreator(SET_DELETE_MODAL, payload),
  setIdToDelete: (id) => actionCreator(SET_ID_TO_DELETE, id),
  setDeleteStatus: (status) => actionCreator(SET_STATUS, status),
};