import "./style.scss";
/**
 * libraries
 */
import PropTypes from "prop-types";
import { withRouter, useHistory } from "react-router-dom";

/**
 * assets - icons
 */
// import Logo from "../../../assets/images/spv_logo.png";
import Logo from "../../../assets/images/superVivaLogo.svg";
import { RiShoppingBasket2Fill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";

/**
 * components
 */
import NavLinkItem from "./NavLinkItem";

/**
 * redux
 */
import { useSelector, useDispatch } from "react-redux";
import { selectors as authenticatedUserSelectors } from "../../../redux/thunk/auth/authenticatedUserThunk";
import {
  actions as userCartActions,
  selectors as userCartSelectors,
} from "../../../redux/thunk/app/user/cart/userCartThunk";

/**
 * constants - data
 */
import {
  PRODUCTS_PATH,
  ABOUT_US_PATH,
  CONTACT_PATH,
  HOME_PATH,
  USER_BASE_PATH,
  ADMIN_BASE_PATH,
  LOGIN_PATH,
  ADMIN_ORDERS_PATH,
  PICKER_ORDERS_PATH,
  COURIER_ORDERS_PATH,
} from "../../../utils/constants";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { actions as logoutActions } from "../../../redux/thunk/auth/logoutThunk";
import { actions as authActions } from "../../../redux/thunk/auth/authenticatedUserThunk";
import { auth } from "../../../services/auth";
import { getRole } from "../../../utils/utility-functions";

const Header = ({ email, phoneNumber, phoneNumber2, ...restProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const productsInCart = useSelector(userCartSelectors.getTotalProducts);

  const currentPath = restProps.location.pathname;
  const isUserActive = currentPath.split("/")[1] === "user";

  const decodedJWT = auth.getDecodedJWT();
  const username = useSelector(authenticatedUserSelectors.getUserName);
  const role = getRole(decodedJWT?.role);

  const onLogout = () => {
    dispatch(logoutActions.setLoading(true));
    auth.logOut();
    history.push(HOME_PATH, { loggedOut: true });
    dispatch(logoutActions.setLoading(false));
    dispatch(authActions.resetAllRedux());
  };

  const onClickLogo = () => {
    if (restProps.location.pathname !== HOME_PATH) {
      history.push(HOME_PATH);
      return;
    }
    window.location.reload();
  };

  const onOpenCart = () => {
    dispatch(userCartActions.toggleCartVisibility(true));
  };

  const onClickUserIcon = () => {
    if (!decodedJWT) {
      history.push(LOGIN_PATH);
      return;
    }

    if (role === "admin") {
      history.push(ADMIN_BASE_PATH);
      return;
    }
    if (role === "picker") {
      history.push(PICKER_ORDERS_PATH);
      return;
    }
    if (role === "courier") {
      history.push(COURIER_ORDERS_PATH);
      return;
    }
    if (role === "customer") {
      history.push(USER_BASE_PATH);
      return;
    }
  };

  return (
    <div className="header">
      <div className={"fixed_header"}>
        <div className="header__info">
          <div className="header__info__left">
            <a href={`mailto:${email}`} className="header__info__left__email">
              {email}
            </a>
          </div>
          <div className="header__info__right">
            <h3 className="header__info__right__contact">
              <a target={"_blank"} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </h3>
          </div>
        </div>

        <div className="header__main">
          <div className="header__main__left">
            <button className="noStyleButton" onClick={onClickLogo}>
              <img
                className="noStyleButton__img"
                src={Logo}
                alt="superviva-logo"
              />
            </button>
          </div>

          <div className="header__main__right">
            <ul>
              <NavLinkItem
                to={HOME_PATH}
                text="Ballina"
                currentPath={currentPath}
                isActive={currentPath.split("/")[1] === ""}
              />
              <NavLinkItem
                to={PRODUCTS_PATH}
                text="Produktet"
                currentPath={currentPath}
                isActive={
                  currentPath.split("/")[1] === "produktet" ||
                  currentPath.split("/")[1] === "produkt"
                }
              />
              <NavLinkItem
                to={ABOUT_US_PATH}
                text="Per ne"
                currentPath={currentPath}
                isActive={currentPath === ABOUT_US_PATH}
              />
              <NavLinkItem
                to={CONTACT_PATH}
                text="Kontakt"
                currentPath={currentPath}
                isActive={currentPath === CONTACT_PATH}
              />

              <div className="NavLinkItem">
                <h3 className="NavLinkItem__text">|</h3>
              </div>

              <NavLinkItem currentPath="">
                <button className="noStyleButton" onClick={onOpenCart}>
                  <RiShoppingBasket2Fill
                    className={`NavLinkItem__icon ${
                      productsInCart > 0 && "NavLinkItem__icon--red"
                    }`}
                  />
                </button>
                {productsInCart > 0 && (
                  <div
                    onClick={onOpenCart}
                    className="header__main__right__productsNumber"
                  >
                    <h3 className="header__main__right__productsNumber__number">
                      {productsInCart}
                    </h3>
                  </div>
                )}
                <button className="noStyleButton" onClick={onOpenCart}>
                  <h3 className="NavLinkItem__text">Shporta ime</h3>
                </button>
              </NavLinkItem>

              <NavLinkItem currentPath={currentPath}>
                <FaUserCircle
                  onClick={onClickUserIcon}
                  className={`NavLinkItem__icon ${
                    isUserActive && "NavLinkItem__icon--red"
                  }`}
                />
                <h3
                  onClick={onClickUserIcon}
                  className={`NavLinkItem__text ${
                    isUserActive && "NavLinkItem__text--red"
                  }`}
                >
                  {decodedJWT ? username : "Login"}
                </h3>
              </NavLinkItem>
              {decodedJWT && (
                <NavLinkItem currentPath={currentPath}>
                  <RiLogoutCircleRLine
                    onClick={onLogout}
                    className={`NavLinkItem__icon ${
                      isUserActive && "NavLinkItem__icon--red"
                    }`}
                  />
                  <h3
                    onClick={onLogout}
                    className={`NavLinkItem__text ${
                      isUserActive && "NavLinkItem__text--red"
                    }`}
                  >
                    {decodedJWT ? "Log Out" : ""}
                  </h3>
                </NavLinkItem>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneNumber2: PropTypes.string,
};

Header.defaultProps = {
  email: "info@super-viva.com",
  phoneNumber: "+383 38 77 88 00",
  phoneNumber2: "",
};

export default withRouter(Header);
