import "./style.scss";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Loader from "../Loader";
import { Fade } from "react-awesome-reveal";

const ModalLoading = ({ isOpen }) => {
  return (
    <Modal style={{ overlay: { zIndex: 999 } }} isOpen={isOpen} className="modalLoading" ariaHideApp={false}>
      <Fade duration={200}>
        <div className="modalLoading__content">
          <Loader style={{ height: 160 }} />
        </div>
      </Fade>
    </Modal>
  );
};

ModalLoading.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ModalLoading;
