import "./style.scss";
import { withRouter, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

/**
 * components
 */
import Header from "../components/common/Header";
import HeaderMobile from "../components/common/Header/HeaderMobile";
import FooterMobile from "../components/common/Footer/FooterMobile";
import * as toast from "../components/common/alerts/Toast";
import ModalLoading from "../components/common/modals/ModalLoading";
import LoginModal from "../components/common/modals/LoginModal";
import RegisterModal from "../components/common/modals/RegisterModal";

/**
 * utils
 */
import { PAYMENT_PATH, url_actions } from "../utils/constants";
import { useQuery } from "../utils/hooks";
import { getRole } from "../utils/utility-functions";

/**
 * redux
 */
import { useSelector, useDispatch } from "react-redux";
import { selectors as authenticatedUserSelectors, actions as authenticatedUserActions } from "../redux/thunk/auth/authenticatedUserThunk";
import { actions as loginActions, getLoginLoading, getLoginStatus, getLoginError } from "../redux/thunk/auth/loginThunk";
import { selectors as registerSelectors, actions as registerActions } from "../redux/thunk/auth/registerThunk";
import { selectors as authModalSelectors, actions as authModalActions, actions } from "../redux/thunk/auth/authModalThunk";
import { selectors as addWishListSelectors, actions as addWishListActions } from "../redux/thunk/app/user/wish-list/addWishListThunk";
/**
 * Services
 */
import { auth } from "../services/auth";


function MainLayout(props) {
  const { path } = props.match;
  const user = useSelector(authenticatedUserSelectors.getUser);
  const userRole = getRole(auth.getDecodedJWT()?.role);

  /**
   * local state
   */
  const [loginVisible, setLoginVisible] = useState(false);
  const [registerVisible, setRegisterVisible] = useState(false);

  /**
   * Selectors
   */
  const loginLoading = useSelector(getLoginLoading);
  const loginStatus = useSelector(getLoginStatus);
  const loginError = useSelector(getLoginError);
  const registerLoading = useSelector(registerSelectors.getIsRegisterLoading);
  const registerStatus = useSelector(registerSelectors.getRegisterStatus);

  const productForWishListSelected = useSelector(addWishListSelectors.getProductSelected);
  const authModalVisible = useSelector(authModalSelectors.getModalVisible);

  /**
   * hooks
   */
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();

  useEffect(() => {
    if (auth.getDecodedJWT() && !user) {
      dispatch(authenticatedUserActions.request());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (loginStatus === 200 && (loginVisible || registerVisible)) {
      registerVisible && setRegisterVisible(false);
      loginVisible && setLoginVisible(false);
      dispatch(loginActions.resetLoginStatus());

      if (userRole === "admin" || userRole === "picker" || userRole === "courier") {
        return toast.warning("Vetem perdoruesi i thjesht mund te shtoje produkte ne listen e deshirave!");
      }

      if (productForWishListSelected) {
        dispatch(addWishListActions.request(productForWishListSelected?.product_id));
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (registerStatus === 200 && (loginVisible || registerVisible)) {
      if (productForWishListSelected) {
        dispatch(addWishListActions.request(productForWishListSelected?.product_id));
      }

      registerVisible && setRegisterVisible(false);
      loginVisible && setLoginVisible(false);

      dispatch(registerActions.resetRegisterStatus());
    }
  }, [registerStatus]);

  useEffect(() => {
    if (authModalVisible) {
      setLoginVisible(true);
      dispatch(authModalActions.setModalVisible(false));
    }
  }, [authModalVisible]);


  // useEffect(() => {
  //   if (query) {
  //     if (query.get("action") === url_actions.addToWishList) {
  //       debugger;

  //       if (getRole(auth.getDecodedJWT()?.role) === "admin") {
  //         toast.error("Perdoruesi admin nuk mund te shtoje produkte ne listen e deshirave!");
  //       } else {
  //         const product_id = query.get("product_id");
  //         dispatch(addWishListActions.request(product_id));
  //       }
  //       query.delete("action");
  //       query.delete("product_id");
  //       history.replace(props.location.url);
  //     }
  //   };
  // }, [dispatch, history, props.location.url, query]);

  // const renderFooterMobile = () => {
  //   if (path === PAYMENT_PATH) return;
  //   return <FooterMobile />;
  // };

  const addWishListLoading = useSelector(addWishListSelectors.getIsLoading)

  const onOpenLogin = () => {
    registerVisible && setRegisterVisible(false);
    setLoginVisible(true);

  };

  const onOpenRegister = () => {
    setLoginVisible(false);
    setRegisterVisible(true);
  };

  const onRequestLogin = payload => {
    const { name, password } = payload;
    dispatch(loginActions.requestLogin(name, password));
  };
  const onRequestRegister = payload => {
    dispatch(registerActions.requestRegister(payload));
  }


  return (
    <div className="mainLayout">
      <ModalLoading isOpen={addWishListLoading} />

      <LoginModal
        isModalVisible={loginVisible}
        onCloseModal={() => setLoginVisible(p => !p)}
        errorMessage={loginError}
        isLoading={loginLoading}
        onClickRegister={onOpenRegister}
        onSuccessfullySubmit={onRequestLogin}
      />

      <RegisterModal
        isModalVisible={registerVisible}
        onCloseModal={() => setRegisterVisible(false)}
        errorMessage=""
        isLoading={registerLoading}
        onGoToLogin={onOpenLogin}
        onSuccessfullySubmit={onRequestRegister}
      />
      <Header />
      <HeaderMobile />
      {props.children}
      {/* {renderFooterMobile()} */}
    </div>
  );
}

export default withRouter(MainLayout);
