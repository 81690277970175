import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const ADMIN_START_ADD_STAFF = "@admin/staff/ADMIN_START_ADD_STAFF";
const ADMIN_ERROR_ADD_STAFF = "@admin/staff/ADMIN_ERROR_ADD_STAFF";
const ADMIN_SUCCESS_ADD_STAFF = "@admin/staff/ADMIN_SUCCESS_ADD_STAFF";
const ADMIN_RESET_ADD_STAFF = "@admin/staff/ADMIN_RESET_ADD_STAFF";

const initialState = {
  loading: false,
  error: null,
  status: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_ADD_STAFF:
      return {
        loading: true,
        error: null,
        status: null,
      };
    case ADMIN_ERROR_ADD_STAFF:
      return {
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ADMIN_SUCCESS_ADD_STAFF:
      return {
        loading: false,
        error: null,
        status: 200,
      };
    case ADMIN_RESET_ADD_STAFF:
      return {
        loading: false,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};

export default reducer;

export const getAddStaffState = (state) => state.app.admin.staff.addAdminStaff;

export const addStaff = (staffData) => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_START_ADD_STAFF));

    const data = {
      first_name: staffData?.first_name,
      last_name: staffData?.last_name,
      email: staffData?.email,
      group: staffData?.group,
      password: staffData?.password,
      password2: staffData?.password2,
      phone_number: staffData?.phone_number,
    };

    await axios
      .post("/shop/staff-users/", data)
      .then((res) => {
        dispatch(actionCreator(ADMIN_SUCCESS_ADD_STAFF));
      })
      .catch((err) => {
        if (err.response?.data?.non_field_errors[0]) {
          dispatch(
            actionCreator(ADMIN_ERROR_ADD_STAFF, {
              status: 404,
              error: err.response?.data?.non_field_errors[0],
            })
          );
        }else{
          dispatch(
            actionCreator(ADMIN_ERROR_ADD_STAFF, {
              status: 404,
              error: "Diqka shkoi keq",
            })
          );
        }
       
      });
  };
};

export const resetAddStaffState = () => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_RESET_ADD_STAFF));
  };
};
