import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { auth_routes, LOGIN_PATH, REGISTER_PATH, FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH } from "../utils/constants";

import AuthLayout from "../layouts/AuthLayout";
import AuthRoute from "../utils/AuthRoute";

const Login = lazy(() => import(/* webpackChunkName:"auth" */"./../pages/auth-pages/Login"));
const Register = lazy(() => import(/* webpackChunkName:"auth" */"./../pages/auth-pages/Register"));
const ResetPassword = lazy(() => import(/* webpackChunkName:"auth" */"./../pages/auth-pages/ResetPassword"));
const ForgotPassword = lazy(() => import(/* webpackChunkName:"auth" */"./../pages/auth-pages/ForgotPassword"));


function AuthRoutes() {
    return (
        <Route
            exact
            path={auth_routes}
            render={(props) => {
                const { location } = props;

                return (
                    <AuthLayout>
                        <TransitionGroup className="transition-group">
                            <CSSTransition
                                key={location.key}
                                timeout={{ enter: 300, exit: 300 }}
                                classNames={"fade"}
                            >
                                <section className="route-section">
                                    <Switch location={location}>
                                        <AuthRoute
                                            exact
                                            path={LOGIN_PATH}
                                            component={Login}
                                        />
                                        <AuthRoute
                                            exact
                                            path={REGISTER_PATH}
                                            component={Register}
                                        />
                                        <AuthRoute
                                            exact
                                            path={FORGOT_PASSWORD_PATH}
                                            component={ForgotPassword}
                                        />
                                        <AuthRoute
                                            exact
                                            path={RESET_PASSWORD_PATH}
                                            component={ResetPassword}
                                        />
                                    </Switch>
                                </section>
                            </CSSTransition>
                        </TransitionGroup>
                    </AuthLayout>
                );
            }}
        />
    )
};

export default AuthRoutes;