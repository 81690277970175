import './style.scss';
import Input from '../common/Inputs/Input';
import TextError from '../common/TextError';
import Button from '../common/Button';
import { useHistory } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH } from '../../utils/constants';

const LoginUI = ({
    onClickLogo,
    handleSubmit,
    errors,
    onChangeInput,
    errorMessage,
    isLoading,
    onClickRegister,
    displayLogo = true,
    style,
    onClickTerms,
    onClickGuesst,
    showGuesst = true,
    showTerms = true
}) => {
    const history = useHistory();

    const onForgotPassword = () => history.push(FORGOT_PASSWORD_PATH);

    return (
        <div className="loginPage__card" style={style}>
            {displayLogo && <h2 onClick={onClickLogo} className="loginPage__card__supervivaText">Super Viva</h2>}

            <form onSubmit={handleSubmit}>
                <label className="loginPage__card__label m-top-18">Email</label>
                <Input
                    style={{ width: "100%", marginTop: 6 }}
                    name="email"
                    type="text"
                    onChange={(e) => onChangeInput("name", e.target.value)}
                    required
                />
                {errors?.name && (
                    <TextError
                        message={errors.name.message}
                        className="m-top-3 m-left-8"
                    />
                )}

                <label className="loginPage__card__label m-top-16">Password</label>
                <Input
                    name="password"
                    type="password"
                    onChange={(e) => onChangeInput("password", e.target.value)}
                    required
                    style={{ width: "100%", marginTop: 6 }}
                />
                {(errors?.password || errorMessage) && (
                    <TextError
                        message={errors?.password?.message || errorMessage}
                        className="m-top-3 m-left-8"
                    />
                )}
                <Button
                    disabled={isLoading}
                    style={{ width: "100%", marginTop: 20, fontSize: 12 }}
                    radius={10}
                    text="Kyqu"
                    isLoading={isLoading}
                    type="submit"
                />
            </form>
            <h3
                onClick={onForgotPassword}
                className="loginPage__card__forgotPassword m-top-15"
            >
                Keni harruar fjalekalimin?
            </h3>
            <div className={showGuesst ? 'loginPage__card__footer__secondtexts' : 'loginPage__card__footer__texts'}>
            {showGuesst &&
                  <h3
                  onClick={onClickGuesst}
                  className="loginPage__card__registerText m-top-10"
              >
                  Vazhdo si Guesst
              </h3>
            }
            {showGuesst && <p className='loginPage__card__icon'>|</p> }
            <h3
                onClick={onClickRegister}
                className="loginPage__card__registerText m-top-10"
            >
                Regjistrohu
            </h3>
            </div>
             <h3
             className="loginPage__card__terms__and__conditions"
             onClick={onClickTerms}

              >
              Termet dhe Kushtet
              </h3> 

        </div>
    )
};

export default LoginUI;