import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/categories/adminCategoryDetailsThunk/REQUEST",
    SUCCESS: "@app/admin/categories/adminCategoryDetailsThunk/SUCCESS",
    ERROR: "@app/admin/categories/adminCategoryDetailsThunk/ERROR",
    RESET_STATUS: "@app/admin/categories/adminCategoryDetailsThunk/RESET_STATUS",
    RESET: "@app/admin/categories/adminCategoryDetailsThunk/RESET_STATUS",
};

/**
 * Initial state
 */
const initialState = {
    details: {},
    isLoading: false,
    errorMessage: '',
    status: 0,
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.categories.adminCategoryDetails.isLoading,
    getErrorMessage: state => state.app.admin.categories.adminCategoryDetails.errorMessage,
    getDetails: state => state.app.admin.categories.adminCategoryDetails.details,
    getStatus: state => state.app.admin.categories.adminCategoryDetails.status
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS, RESET } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                status: 0
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                details: payload,
                status: 200
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
                status: 404
            };
        case RESET_STATUS:
            return {
                ...state,
                status: 0
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function (id, displayLoading = true) {
        return async (dispatch) => {
            try {
                displayLoading && dispatch(actionCreator(REQUEST));
                const response = await axios.get(`/shop/category/${id}/`);
                dispatch(actionCreator(SUCCESS, response));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.response.data.detail));
            };
        }
    },
    setCategory: (category) => actionCreator(SUCCESS, category),
    resetStatus: () => actionCreator(RESET_STATUS),
    reset: () => actionCreator(RESET)
};