import "./style.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

const Button = ({
  onClick,
  text,
  disabled,
  radius = 8,
  className,
  paddingHorizontal = 19,
  isWhite = false,
  style,
  isLoading,
  width = "auto",
  isOffer= false,
  ...rest
}) => (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        borderRadius: radius,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        ...style,
        opacity: isLoading ? 0.5 : 1,
        width:width,
      }}
      className={!isOffer ? `commonButton ${isWhite && "commonButton--white"} ${className}` : 'commonButton_offer'}
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={17} style={{ color: "white" }} />
      ) : (
          text
        )}
    </button>
  );

export default Button;
