import { combineReducers } from "redux";
import { actionTypes as authTypes } from "./auth/authenticatedUserThunk";

/**
 * user reducers
 */
import cartPaymentReducer from "./app/user/cartPaymentThunk";
import categoriesReducer from "./app/user/userCategoriesThunk";

import userProductDetailsReducer from "./app/user/products/userProductDetailsThunk";
import userProductsReducer from "./app/user/products/userProductsThunk";
import userHomeProductsReducer from "./app/user/products/userHomeProductsThunk";

import userCartReducer from "./app/user/cart/userCartThunk";
import userDeleteCartReducer from "./app/user/cart/userDeleteCartThunk";

import userAddNewAddressReducer from "./app/user/addresses/userAddNewAddressThunk";
import userAddressesReducer from "./app/user/addresses/userAddressesThunk";
import userDeleteAddressReducer from "./app/user/addresses/userDeleteAddressThunk";
import userEditAddressReducer from "./app/user/addresses/userEditAddressThunk";

import userOrdersReducer from "./app/user/orders/userOrdersThunk";
import userOrdersHistoryReducer from "./app/user/orders/userOrdersHistoryThunk";

import userDashboardReducer from "./app/user/userDashboardThunk";

import editProfileReducer from "./app/user/settings/editProfileThunk";
import changePasswordReducer from "./app/user/settings/changePasswordThunk";

import addWishListReducer from "./app/user/wish-list/addWishListThunk";
import deleteWishListReducer from "./app/user/wish-list/deleteWishListThunk";
import getWishListReducer from "./app/user/wish-list/getWishListThunk";

/**
 * Admin reducers
 */
import adminCustomersReducer from "./app/admin/customers/adminCustomersThunk";

import adminProductsReducer from "./app/admin/products/adminProductsThunk";
import adminAddProductReducer from "./app/admin/products/adminAddProductThunk";
import adminProductDetailsReducer from "./app/admin/products/adminProductDetailsThunk";
import adminDeleteProductReducer from "./app/admin/products/adminDeleteProductThunk";
import adminEditProductReducer from "./app/admin/products/adminEditProductThunk";

import adminCategoriesReducer from "./app/admin/categories/adminCategoriesThunk";
import adminAddNewCategoryReducer from "./app/admin/categories/adminAddNewCategoryThunk";
import adminCategoryDetailsReducer from "./app/admin/categories/adminCategoryDetailsThunk";
import adminAddSubcategoryReducer from "./app/admin/categories/adminAddSubCategoryThunk";
import adminEditCategoryReducer from "./app/admin/categories/adminEditCategoryThunk";
import adminDeleteCategoryReducer from "./app/admin/categories/adminDeleteCategoryThunk";


import adminOrdersReducer from "./app/admin/orders/adminOrdersThunk";
import adminOrderDetailsReducer from "./app/admin/orders/adminOrderDetailsThunk";
import adminOrderPickersReducer from './app/admin/orders/adminPickers'
import adminOrderCouriersReducer from './app/admin/orders/adminCouriers'
import pickerOrdersReducer from './app/admin/orders/pickerOrders'
import courierOrdersReducer from './app/admin/orders/courierReducer'
import dashboardAdminReducer from "./app/admin/dashboardAdminThunk";

import adminAddStaffReducer from "./app/admin/staff/adminAddStaff";
import adminEditStaffReducer from "./app/admin/staff/adminEditStaff";
import adminFetchStaffReducer from "./app/admin/staff/adminFetchStaff";
import adminFetchStaffDetailsReducer from "./app/admin/staff/adminFetchStaffDetiails";
import adminDeleteStaffReducer from "./app/admin/staff/adminDeleteStaff";

/**
 * auth reducers
 */
import loginReducer from "./auth/loginThunk";
import registerReducer from "./auth/registerThunk";
import logoutReducer from "./auth/logoutThunk";
import authenticatedUserReducer from "./auth/authenticatedUserThunk";
import authModalReducer from "./auth/authModalThunk";

/**
 * banner reducers
 */
import adminAddBannerReducer from "./app/admin/banner/adminAddBannerThunk";
import adminBannerReducer from "./app/admin/banner/adminBannerThunk";
import adminDeleteReducer from "./app/admin/banner/adminDeleteBannerThunk";

/**
 * offer reducers
 */
import adminOfferReducer from "./app/admin/offers/adminOffersThunk"

const appReducer = combineReducers({
  app: combineReducers({
    user: combineReducers({
      categories: categoriesReducer,
      userDashboard: userDashboardReducer,
      cartPayment: cartPaymentReducer,
      wishList: combineReducers({
        addWishList: addWishListReducer,
        deleteWishList: deleteWishListReducer,
        getWishList: getWishListReducer,
      }),
      products: combineReducers({
        userProducts: userProductsReducer,
        userHomeProducts: userHomeProductsReducer,
        userProductDetails: userProductDetailsReducer,
      }),
      cart: combineReducers({
        userCart: userCartReducer,
        userDeleteCart: userDeleteCartReducer,
      }),
      settings: combineReducers({
        editProfile: editProfileReducer,
        changePassword: changePasswordReducer,
      }),
      addresses: combineReducers({
        userAddresses: userAddressesReducer,
        userAddNewAddress: userAddNewAddressReducer,
        userDeleteAddress: userDeleteAddressReducer,
        userEditAddress: userEditAddressReducer,
      }),
      orders: combineReducers({
        userOrders: userOrdersReducer,
        userOrdersHistory: userOrdersHistoryReducer,
      }),
    }),
    admin: combineReducers({
      dashboardAdmin: dashboardAdminReducer,
      customers: combineReducers({
        adminCustomers: adminCustomersReducer,
      }),
      products: combineReducers({
        adminProducts: adminProductsReducer,
        adminAddProduct: adminAddProductReducer,
        adminProductDetails: adminProductDetailsReducer,
        adminDeleteProduct: adminDeleteProductReducer,
        adminEditProduct: adminEditProductReducer,
      }),
      categories: combineReducers({
        adminCategories: adminCategoriesReducer,
        adminAddNewCategory: adminAddNewCategoryReducer,
        adminCategoryDetails: adminCategoryDetailsReducer,
        adminAddSubcategory: adminAddSubcategoryReducer,
        adminEditCategory: adminEditCategoryReducer,
        adminDeleteCategory: adminDeleteCategoryReducer,
      }),
      orders: combineReducers({
        adminOrders: adminOrdersReducer,
        adminOrderDetails: adminOrderDetailsReducer,
        pickers: adminOrderPickersReducer,
        couriers: adminOrderCouriersReducer,
        pickerOrders: pickerOrdersReducer,
        courierOrders: courierOrdersReducer
      }),
      staff: combineReducers({
        addAdminStaff: adminAddStaffReducer,
        editAdminStaff: adminEditStaffReducer,
        getAdminStaff: adminFetchStaffReducer,
        getAdminStaffDetails: adminFetchStaffDetailsReducer,
        deleteAdminStaff: adminDeleteStaffReducer,
      }),
      banner: combineReducers({
        adminAddBanner: adminAddBannerReducer,
        adminBanner: adminBannerReducer,
        adminDeleteBanner: adminDeleteReducer,
      }),
      offer: combineReducers({
        offers: adminOfferReducer,
      })
    }),
  }),
  auth: combineReducers({
    login: loginReducer,
    register: registerReducer,
    logout: logoutReducer,
    authenticatedUser: authenticatedUserReducer,
    authModal: authModalReducer,
  }),
});

const rootReducer = (state, action) => {
  if (action.type === authTypes.RESET_REDUX) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
