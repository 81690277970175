import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";

const PREFIX = "@app/admin/offers/adminOffersThunk/"

export const actionTypes = {
  CREATE: `${PREFIX}CREATE`,
  GET_ALL: `${PREFIX}GET_ALL`,
  GET_ALL_SUCCESS: `${PREFIX}GET_ALL_SUCCESS`,
  GET_ALL_SLUG : `${PREFIX}GET_ALL_SLUG`,
  GET_ALL_SLUG_SUCCESS: `${PREFIX}GET_ALL_SLUG_SUCCESS`,
  DELETE: `${PREFIX}DELETE`,
  DELETE_SUCCESS: `${PREFIX}DELETE_SUCCESS`,
  EDIT: `${PREFIX}EDIT`,
  EDIT_SUCCESS: `${PREFIX}EDIT_SUCCESS`,

};

const { CREATE, GET_ALL, GET_ALL_SUCCESS ,GET_ALL_SLUG,GET_ALL_SLUG_SUCCESS,DELETE,DELETE_SUCCESS,EDIT,EDIT_SUCCESS} = actionTypes;

const initialState = {
  data: {
    count: 0,
    results: [],
    links: {
      next: null,
      previous: null
    },
    slugProducts: [],
    total_pages: 0
  },
};

const Reducer = (state = initialState , {type , payload}) => {
  switch(type) {
    case EDIT_SUCCESS:
    const allResults = [...state.results];
    const offerIdx = allResults.findIndex((item) => item.slug === payload.offerId);
    allResults[offerIdx] = payload.formData
    return {
      ...state,
      data: {
        ...state.data,
        results: allResults
      }
    }
    case GET_ALL_SUCCESS: 
       return {
        ...state,
        data: {
          ...state.data,
          results: payload.data
        }
       }
       case GET_ALL_SLUG_SUCCESS:
        return {
          ...state,
          slugProducts: payload.data
        }
      case DELETE_SUCCESS:
        return{
          ...state,
          data:{
            ...state.data,
            results : state.data.results.filter((offer) => offer.id !== payload.offerId),
          },
        };
    default: 
    return {
      ...state
    }
  }
}

export default Reducer

export const actions = {
  create: function (query) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(CREATE));

        let data = new FormData();
        data.append('image', query.image);
        data.append('title', query.title);

        query.products.forEach((productId, index) => {
          data.append(`products`, productId);
        });

        data.append('slug', query.slug);
        data.append('description', query.description);

        await axios({
          method: 'post',
          url: '/shop/offers/',
          data,
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } catch (err) {
        return err;
      }
    };
  },

  getAll: function () {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(GET_ALL));
        const response = await axios({
          method: 'get',
          url: 'shop/offer/',

          headers: { 'Content-Type': 'multipart/form-data' }
        });
        

        dispatch(actionCreator(GET_ALL_SUCCESS, { data: response }));
      } catch (err) {
        return err;
      }
    };
  },

  getAllSlug: function (props) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(GET_ALL_SLUG));
        const response = await axios.get(`shop/offer-view/${props?.slug}/?search=${props?.search}&page=1&sort=description`);

        const { results } = getState().app.user.products.userProducts.data;
        dispatch(actionCreator(GET_ALL_SLUG_SUCCESS, {data:{
            ...response,
            results: [...results, ...response.results.map(x => ({
                ...x,
                thumbnail: addBaseUrlToThumbnails(x.thumbnail)
            }))]
        }}));
        return;
      } catch (err) {
        return err;
      }
    };
  },
  
  deleteOffer: function (offerId) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(DELETE));

        await axios.delete(`/shop/offers/${offerId}/`);

        dispatch(actionCreator(DELETE_SUCCESS, { offerId }));
      } catch (err) {
        return err;
      }
    };
  },

 
  editOffer: function (offerId, formData, id) {
  
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(EDIT));
  
        let data = new FormData();
        data.append('image', formData.image);
        data.append('title', formData.title);
  
        formData.products.forEach((productId, index) => {
          data.append(`products`, productId);
        });
  
        data.append('slug', formData.slug);
        data.append('description', formData.description);
  
        await axios({
          method: 'put',
          url: `/shop/offers/${id}`,
          data,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        dispatch(actionCreator(EDIT_SUCCESS, { offerId, formData }));
      } catch (err) {
        return err;
      }
    };
  }
  
};
