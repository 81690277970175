/**
 * assets
 */
import { useDispatch, useSelector } from "react-redux";
import user_logo from "../assets/images/user_logo.svg";

/**
 * components
 */
import Header from "../components/common/Header";
import HeaderMobile from "../components/common/Header/HeaderMobile";
import UserDashboardNavigator from "../components/user-components/UserDashboardNavigator";
import { selectors as UserSelectors } from '../redux/thunk/auth/authenticatedUserThunk'
import { selectors as UserOrdersSelectors, actions as OrderActions } from '../redux/thunk/app/user/orders/userOrdersThunk'
import { useEffect, useState } from "react";

function UserLayout(props) {

  const [ordersAmount, setOrdersAmount] = useState(0);
  const [user, setUser] = useState({});
  const userState = useSelector(UserSelectors.getUser);
  const userOrdersState = useSelector(UserOrdersSelectors.getData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(OrderActions.request());
  }, [])

  useEffect(() => {
    setUser(userState)
  }, [userState]);

  useEffect(() => {
    let pricesArray = [];
    userOrdersState?.results.map(item => {
      pricesArray.push(parseFloat(item.total_amount))
    })
    if (pricesArray.length > 0) {
      const total = pricesArray?.reduce((a, b) => a + b);
      setOrdersAmount(total);
    }
  }, [userOrdersState.results])

  return (
    <div className="userLayout">
      <div id="userLayout__header">
        <Header />
        <HeaderMobile />
        <div className="userLayout__header">
          <div className="userLayout__header__imageContainer">
            <img src={user_logo} alt="no" />
          </div>
          <h2 className="userLayout__header__userCredentials">{user?.first_name} {user?.last_name}</h2>
          {ordersAmount && ordersAmount > 0 && <h3 className="userLayout__header__totalAmount">{ordersAmount.toFixed(2)}€</h3>}
        </div>
        <UserDashboardNavigator />
      </div>
      {props.children}
    </div>
  );
}

export default UserLayout;
