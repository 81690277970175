/* eslint-disable react-hooks/exhaustive-deps */
/**
 * styles
 */
import './assets/scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';

/**
 * libraries
 */
import { useState, useEffect, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

/**
 * redux
 */
import { useDispatch, useSelector } from 'react-redux';
import { actions as authenticatedActions } from './redux/thunk/auth/authenticatedUserThunk';
import {
  actions as userCartActions,
  selectors as userCartSelectors,
} from './redux/thunk/app/user/cart/userCartThunk';

/**
 * components
 */
import Cart from './components/user-components/Cart';
import Loading from './pages/common/Loading';

/**
 * services
 */
import { auth } from './services/auth';

/**
 * Routes
 */
import PublicRoutes from './routes/PublicRoutes';
import UserRoutes from './routes/UserRoutes';
import AuthRoutes from './routes/AuthRoutes';
import AdminRoutes from './routes/AdminRoutes';
import PickerRoutes from './routes/PickerRoutes';
import CourierRoutes from './routes/CourierRoutes';

import {
  NOT_FOUND_PATH,
  CART_STORAGE_KEY,
  auth_routes,
  picker_routes,
  courier_routes,
  admin_routes,
  user_routes,
  public_routes,
} from './utils/constants';
import Cookies from './cookiesComponent/Cookies';
import ChatBot from './chatBot/ChatBot';
import {getTransportPrice} from "./utils/getTransportPrice";
import { getRole } from './utils/utility-functions';

function App(props) {
  const dispatch = useDispatch();
  const cartVisible = useSelector(userCartSelectors.getIsVisible);
  const [showCookie, setShowCookie] = useState(false);
  const [canAccessCustomer, setCanAccessCustomer] = useState(false);

  const canAccessAdmin = getRole(auth.getDecodedJWT()?.role) === 'admin';
  const canAccessPicker = getRole(auth.getDecodedJWT()?.role) === 'picker';
  const canAccessCourier = getRole(auth.getDecodedJWT()?.role) === 'courier';

  useEffect(() => {
    getTransportPrice()
    setTimeout(() => {
      setShowCookie(true);
    }, 2000);
  }, []);
  useEffect(() => {
    const decodedToken = auth.getDecodedJWT();
    if (getRole(auth.getDecodedJWT()?.role) === 'admin') {
      setCanAccessCustomer(true);
    } else {
      setCanAccessCustomer(false);
    }
    dispatch(userCartActions.request());
    if (decodedToken) {
      dispatch(authenticatedActions.request());
    }
    if (cartVisible) {
      document.body.style.overflowY = 'hidden';
    }

    window.addEventListener('storage', storageChanged);
    return () => {
      window.removeEventListener('storage', storageChanged);
    };
  }, [canAccessCustomer]);
  // fire when user logged out
  // useEffect(() => {
  //   if (logoutStatus === 200) {
  //     dispatch(userCartActions.request());
  //   }
  // }, [logoutStatus])

  useEffect(() => {
    // when cart Componnt in visible, scrolling is disabled
    if (cartVisible) {
      document.body.style.overflowY = 'hidden';
      return;
    }
    document.body.style.overflowY = 'auto';
  }, [cartVisible]);

  const storageChanged = (e) => {
    // if user changes cart manually in localstorage then we set previos cart in local storage
    // }
  };
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={auth_routes} component={AuthRoutes} />
          <Route exact path={admin_routes} component={AdminRoutes} />
          <Route exact path={picker_routes} component={PickerRoutes} />
          <Route exact path={courier_routes} component={CourierRoutes} />
          <Route exact path={user_routes} component={UserRoutes} />
          <Route exact path={public_routes} component={PublicRoutes} />
          <Redirect to={NOT_FOUND_PATH} />
        </Switch>
      </Suspense>
      {/*-----Cart-------*/}
      <ToastContainer className="toastContainerClass" />
      <Cart style={{ marginTop: window.scrollY }} />
      <Cookies />
      {canAccessAdmin || canAccessPicker || canAccessCourier ? null : (
        <ChatBot />
      )}
    </Router>
  );
}

export default App;
