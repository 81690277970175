import "./style.scss";
import { toast } from "react-toastify";

import { CgClose } from "react-icons/cg";
import ClickableMessage from "./ClickableMessage";

const closeButton = ({ closeToast }) => (
  <button
    className="noStyleButton"
    type="button"
    onClick={closeToast}
    style={{ paddingLeft: 10 }}
  >
    <CgClose className="toast-bodyClassName-success__body__closeButton" />
  </button>
);

const options = {
  position: toast.POSITION.BOTTOM_LEFT,
  newestOnTop: false,
  closeOnClick: true,
  draggable: true,
  autoClose: 3000,
  hideProgressBar: true,
};

const successOptions = {
  ...options,
  className: "toast-bodyClassName-success",
  bodyClassName: "toast-bodyClassName-success__body",
  closeButton,
  progressClassName: "toast-bodyClassName-success__body__progress",
};

const errorOptions = {
  ...successOptions,
  autoClose: 5000,
};

const warningOptions = {
  ...successOptions,
}

export function error(title, content) {
  toast.error(
    <ClickableMessage
      title={title}
      content={content}
      type="error"
    />,
    errorOptions
  );
}

export function success(content) {
  toast.success(content, {});
}

export function successClickableMessage(title, content, pushTo, onClick, options = {}) {
  toast.success(
    <ClickableMessage
      content={content}
      title={title}
      pushTo={pushTo}
      onClick={onClick}
      type="success"
    />,
    { ...successOptions, ...options }
  );
}

export function warning(title, content, options = {}) {
  toast.success(
    <ClickableMessage
      content={content}
      title={title}
      type="warning"
    />,
    { ...warningOptions, ...options }
  )
}
