import "./style.scss";

/**
 * libraries
 */
import { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useTransition, animated } from "react-spring";

/**
 * assets - Icons
 */
import Logo from "../../../assets/images/superVivaLogo.svg";
import { VscMenu, VscChromeClose } from "react-icons/vsc";
import { RiShoppingBasket2Fill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";

/**
 * components
 */
import NavLinkItem from "./NavLinkItem";

/**
 * redux
 */
import { useSelector, useDispatch } from "react-redux";
import { selectors as authenticatedUserSelectors } from "../../../redux/thunk/auth/authenticatedUserThunk";
import {
  selectors as userCartSelectors,
  actions as userCartActions,
} from "../../../redux/thunk/app/user/cart/userCartThunk";

/**
 * data - constants
 */
import {
  PRODUCTS_PATH,
  ABOUT_US_PATH,
  CONTACT_PATH,
  HOME_PATH,
  USER_BASE_PATH,
  ADMIN_BASE_PATH,
  ADMIN_ORDERS_PATH,
  LOGIN_PATH,
  PICKER_ORDERS_PATH,
  COURIER_ORDERS_PATH,
} from "../../../utils/constants";
import { auth } from "../../../services/auth";
import { getRole } from "../../../utils/utility-functions";

const HeaderMobile = ({
  email = "info@super-viva.com",
  phone_number = "+383 38 778 800",
  phone_number2 = "+383 44 509 600",
  ...restProps
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMenuToggle, setMenuToggle] = useState(false);

  const totalProducts = useSelector(userCartSelectors.getTotalProducts);

  const currentPath = restProps.location.pathname;
  const isUserActive = currentPath.split("/")[1] === "user";

  const username = useSelector(authenticatedUserSelectors.getUserName);
  const token = auth.getToken();
  const role = getRole(auth.getDecodedJWT()?.role);

  const onClickLogo = () => {
    setMenuToggle(false);
    if (restProps.location.pathname !== HOME_PATH) {
      history.push(HOME_PATH);
    }
    window.location.reload();
  };

  const transitions = useTransition(isMenuToggle, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const transitions1 = useTransition(isMenuToggle, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { position: "relative", opacity: 1 },
    leave: { position: "absolute", opacity: 0 },
  });

  const onClickNavLink = (to) => {
    history.push(to);
    setMenuToggle(false);
  };

  useEffect(() => {
    if (!isMenuToggle) {
      document.body.style.overflowY = "auto";
      return;
    }
    document.body.style.overflowY = "hidden";
  }, [isMenuToggle]);

  const onOpenCart = () => {
    setMenuToggle(false);
    dispatch(userCartActions.toggleCartVisibility(true));
  };

  const onClickUser = () => {
    setMenuToggle(false);

    if (!token) {
      history.push(LOGIN_PATH);
      return;
    }

    if (role === "admin") {
      history.push(ADMIN_BASE_PATH);
      return;
    }
    if (role === "picker") {
      history.push(PICKER_ORDERS_PATH);
      return;
    }
    if (role === "courier") {
      history.push(COURIER_ORDERS_PATH);
      return;
    }
    if (role === "customer") {
      history.push(USER_BASE_PATH);
      return;
    }
  };

  return (
    <div className="headerMobile">
      <div className="headerMobile__main">
        <div className="headerMobile__main__left">
          <button className="noStyleButton" onClick={onClickLogo}>
            <img style={{ width: "200px" }} src={Logo} alt="superviva-logo" />
          </button>
        </div>
        <div className="headerMobile__main__right">
          {transitions1.map(({ item, key, props }) =>
            !item ? (
              <animated.div key={key} style={props}>
                <button
                  className="noStyleButton"
                  onClick={() => setMenuToggle((prevState) => !prevState)}
                >
                  <VscMenu
                    className={`headerMobile__main__right__menuIcon--display`}
                    style={{ fontSize: 27, cursor: "pointer" }}
                  />
                </button>
              </animated.div>
            ) : (
              <animated.div key={key} style={props}>
                <button
                  className="noStyleButton"
                  onClick={() => setMenuToggle((prevState) => !prevState)}
                >
                  <VscChromeClose
                    style={{ fontSize: 27, cursor: "pointer" }}
                    className={`headerMobile__main__right__menuIconX--display`}
                  />
                </button>
              </animated.div>
            )
          )}
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div
                  key={key}
                  className={`headerMobile__main__right__navLinks`}
                  style={{ ...props, height: document.body.clientHeight - 126 }}
                >
                  <div className="headerMobile__main__right__navLinks__top">
                    <ul>
                      <NavLinkItem
                        onClick={onClickNavLink}
                        to={HOME_PATH}
                        text="Ballina"
                        currentPath={currentPath}
                        isActive={currentPath.split("/")[1] === ""}
                      />
                      <NavLinkItem
                        onClick={onClickNavLink}
                        to={PRODUCTS_PATH}
                        text="Produktet"
                        currentPath={currentPath}
                        isActive={currentPath.split("/")[1] === "products"}
                      />
                      <NavLinkItem
                        onClick={onClickNavLink}
                        to={ABOUT_US_PATH}
                        text="Per ne"
                        currentPath={currentPath}
                        isActive={currentPath === ABOUT_US_PATH}
                      />
                      <NavLinkItem
                        onClick={onClickNavLink}
                        to={CONTACT_PATH}
                        text="Kontakt"
                        currentPath={currentPath}
                        isActive={currentPath === CONTACT_PATH}
                      />
                      <NavLinkItem currentPath="">
                        <button className="noStyleButton" onClick={onOpenCart}>
                          <RiShoppingBasket2Fill
                            className={`NavLinkItem__icon ${
                              totalProducts > 0 && "NavLinkItem__icon--red"
                            }`}
                          />
                        </button>
                        {totalProducts > 0 && (
                          <button
                            className="noStyleButton"
                            onClick={onOpenCart}
                          >
                            <div className="headerMobile__main__right__navLinks__top__productsNumber">
                              <h3 className="headerMobile__main__right__navLinks__top__productsNumber__number">
                                {totalProducts}
                              </h3>
                            </div>
                          </button>
                        )}
                        <button className="noStyleButton" onClick={onOpenCart}>
                          <h3 className="NavLinkItem__text">Shporta ime</h3>
                        </button>
                      </NavLinkItem>

                      <NavLinkItem>
                        <FaUserCircle
                          className={`NavLinkItem__icon ${
                            isUserActive && "NavLinkItem__icon--red"
                          }`}
                          onClick={onClickUser}
                        />
                        <h3
                          className={`NavLinkItem__text ${
                            isUserActive && "NavLinkItem__text--red"
                          }`}
                          onClick={onClickUser}
                        >
                          {token ? username : "Login"}
                        </h3>
                      </NavLinkItem>
                    </ul>
                  </div>
                  <div
                    className="headerMobile__main__right__navLinks__bottom"
                    onClick={() => setMenuToggle(false)}
                  />
                </animated.div>
              )
          )}
        </div>
      </div>
      <div className="headerMobile__info">
        <div className="headerMobile__info__left">
          <a
            target={"_blank"}
            href={`mailto:${email}`}
            className="headerMobile__info__left__email"
          >
            {email}
          </a>
        </div>
        <div className="headerMobile__info__right">
          <h3 className="headerMobile__info__right__contact">
            <a target={"_blank"} href={`tel:${phone_number}`}>
              {phone_number}
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default withRouter(HeaderMobile);
