import actionCreator from "../../../../utils/action-creator";
import axios from "../../../../services/axios";
const { REACT_APP_WEB_API_URL } = process.env;

/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@app/user/userCategoriesThunk/REQUEST",
  SUCCESS: "@app/user/userCategoriesThunk/SUCCESS",
  ERROR: "@app/user/userCategoriesThunk/ERROR",
  SELECT_CATEGORY: "@app/user/userCategoriesThunk/SELECT_CATEGORY",
};

/**
 * Initial state
 */
const initialState = {
  list: [],
  loading: false,
  error: null,
  selected: null,
};

/**
 * Selectors
 */
export const selectors = {
  getCategories: (state) => state.app.user.categories.list,
  getCategoriesLoading: (state) => state.app.user.categories.loading,
  getCategoriesError: (state) => state.app.user.categories.error,
  getSelectedCategory: (state) => state.app.user.categories.selected,
};

/**
 * Reducer
 */
const {
  REQUEST,
  ERROR,
  SUCCESS,
  SELECT_CATEGORY,
} = actionTypes;

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  fetchCategories: function () {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(REQUEST));
        const response = await axios.get('/customer/categories/');

        const res = response.map(x => ({
          ...x,
          thumbnail: REACT_APP_WEB_API_URL + x.thumbnail
        }));

        dispatch(actionCreator(SUCCESS, res));
      } catch (err) {
        dispatch(actionCreator(ERROR, err));
      }
    };
  },
  selectCategory: function (category) {
    return actionCreator(SELECT_CATEGORY, category);
  },
  resetCategory: function () {
    return actionCreator(SELECT_CATEGORY, null);
  },
};
