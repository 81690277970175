import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const FETCH_COURIERS_ADMIN_SUCCESS = "@admin/orders/FETCH_COURIERS_ADMIN_SUCCESS";
const FETCH_COURIERS_ADMIN_ERROR = "@admin/orders/FETCH_COURIERS_ADMIN_ERROR";
const FETCH_COURIERS_ADMIN_START = "@admin/orders/FETCH_COURIERS_ADMIN_START";

const initialState = {
    loading: false,
    error: null,
    status: null,
    couriers: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COURIERS_ADMIN_START:
            return {
                loading: true,
                error: null,
                status: null,
                couriers: null
            }
        case FETCH_COURIERS_ADMIN_SUCCESS:
            return {
                loading: false,
                error: null,
                status: null,
                couriers: action.payload
            }
        case FETCH_COURIERS_ADMIN_ERROR:
            return {
                loading: false,
                error: action.payload,
                status: null,
                couriers: null
            }

        default:
            return state;
    }
}

export default reducer;

export const getCouriersState = state => state.app.admin.orders.couriers;

export const fetchCouriers = () => {
    return async dispatch => {
        dispatch(actionCreator(FETCH_COURIERS_ADMIN_START));

        await axios.get("/shop/couriers/").then(res => {
            dispatch(actionCreator(FETCH_COURIERS_ADMIN_SUCCESS, res))
        }).catch(err => {
            dispatch(actionCreator(FETCH_COURIERS_ADMIN_ERROR, err))
        })
    }
}