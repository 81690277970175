import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import {euroFormatter} from "../../../../../utils/number-formatters";
import {getFormattedDate} from "../../../../../utils/utility-functions";
import * as toast from "../../../../../components/common/alerts/Toast";
import {order_statuses} from "../../../../../utils/constants";
import {actions as orderAction} from "../../user/orders/userOrdersThunk";


/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/admin/orders/orderDetailsThunk/REQUEST",
    SUCCESS: "@app/admin/orders/orderDetailsThunk/SUCCESS",
    FAILURE: "@app/admin/orders/orderDetailsThunk/FAILURE",

    DELETE_PRODUCT_REQUEST: "@app/admin/orders/orderDetailsThunk/DELETE_PRODUCT_REQUEST",
    DELETE_PRODUCT_SUCCESS: "@app/admin/orders/orderDetailsThunk/DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILURE: "@app/admin/orders/orderDetailsThunk/DELETE_PRODUCT_FAILURE",
    SET_DELETE_STATUS: "@app/admin/orders/orderDetailsThunk/SET_DELETE_STATUS",

    EDIT_PRODUCT_IN_ORDER: "@app/admin/orderDetailsThunk/EDIT_PRODUCT_IN_ORDER",

    PACK_ORDER_REQUEST: "@app/admin/orderDetailsThunk/PACK_ORDER_REQUEST",
    PACK_ORDER_SUCCESS: "@app/admin/orderDetailsThunk/PACK_ORDER_SUCCESS",
    PACK_ORDER_FAILURE: "@app/admin/orderDetailsThunk/PACK_ORDER_FAILURE",
    SET_PACK_ORDER_STATUS: "@app/admin/orderDetailsThunk/SET_PACK_ORDER_STATUS",
    CANCEL_ORDER_REQUEST: "@app/admin/orderDetailsThunk/CANCEL_ORDER_REQUEST",
    CANCEL_ORDER_SUCCESS: "@app/admin/orderDetailsThunk/CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILURE: "@app/admin/orderDetailsThunk/CANCEL_ORDER_FAILURE",
    RESTORE_ORDER_REQUEST: "@app/admin/orderDetailsThunk/RESTORE_ORDER_REQUEST",
    RESTORE_ORDER_SUCCESS: "@app/admin/orderDetailsThunk/RESTORE_ORDER_SUCCESS",
    RESTORE_ORDER_FAILURE: "@app/admin/orderDetailsThunk/RESTORE_ORDER_FAILURE",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    details: {},

    isDeleteProductLoading: false,
    deleteProductError: '',
    deleteStatus: 0,

    isPackOrderLoading: false,
    packOrderError: '',
    packOrderStatus: 0,
    OrderStatus: 0,
    restoreOrderStatus: 0,
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.orders.adminOrderDetails.isLoading,
    getErrorMessage: state => state.app.admin.orders.adminOrderDetails.errorMessage,
    getData: state => state.app.admin.orders.adminOrderDetails.details,
    getDataNotDeleted: state => state.app.admin.orders.adminOrderDetails.details?.product?.filter(e => e?.status !== "DELETED_PRODUCT"),
    getIsDeleteProductLoading: state => state.app.admin.orders.adminOrderDetails.isDeleteProductLoading,
    getDeleteStatus: state => state.app.admin.orders.adminOrderDetails.deleteStatus,
    getIsPackOrderLoading: state => state.app.admin.orders.adminOrderDetails.isPackOrderLoading,
    getPackOrderStatus: state => state.app.admin.orders.adminOrderDetails.packOrderStatus,
    getOrderStatus: state => state.app.admin.orders.adminOrderDetails.OrderStatus,
    getRestoreOrderStatus: state => state.app.admin.orders.adminOrderDetails.restoreOrderStatus,
};

/**
 * Reducer
 */
const {
    REQUEST,
    SUCCESS,
    FAILURE,

    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    SET_DELETE_STATUS,

    EDIT_PRODUCT_IN_ORDER,

    PACK_ORDER_SUCCESS,
    PACK_ORDER_FAILURE,
    PACK_ORDER_REQUEST,
    SET_PACK_ORDER_STATUS,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    RESTORE_ORDER_REQUEST,
    RESTORE_ORDER_SUCCESS,
    RESTORE_ORDER_FAILURE,
} = actionTypes;

const Reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                details: payload,
            };
        case FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };

        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                isDeleteProductLoading: true,
                deleteProductError: '',
            };
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isDeleteProductLoading: false,
                details: payload,
                deleteStatus: 200
            };
        case DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isDeleteProductLoading: false,
                deleteProductError: payload,
                deleteStatus: 404
            };
        case SET_DELETE_STATUS:
            return {
                ...state,
                deleteStatus: payload
            }

        case EDIT_PRODUCT_IN_ORDER:
            return {
                ...state,
                details: {
                    ...state.details,
                    product: payload
                }
            };

        case PACK_ORDER_REQUEST:
            return {
                ...state,
                isPackOrderLoading: true,
                packOrderError: '',
            };
        case PACK_ORDER_SUCCESS:
            return {
                ...state,
                isPackOrderLoading: false,
                details: payload,
                packOrderStatus: 200,
            };
        case PACK_ORDER_FAILURE:
            return {
                ...state,
                isPackOrderLoading: false,
                packOrderError: payload,
                packOrderStatus: 404
            };
        case CANCEL_ORDER_REQUEST:
            return {
                ...state,
                packOrderError: '',
            }
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                details: payload,
                OrderStatus: 200,
                restoreOrderStatus: 0,

            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                packOrderError: payload,
                OrderStatus: 404,
                restoreOrderStatus: 0,

            };
        case RESTORE_ORDER_REQUEST:
            return {
                ...state,
                packOrderError: '',
            }
        case RESTORE_ORDER_SUCCESS:
            return {
                ...state,
                details: payload,
                restoreOrderStatus: 200,
                OrderStatus: 0,

            };
        case RESTORE_ORDER_FAILURE:
            return {
                ...state,
                packOrderError: payload,
                restoreOrderStatus: 404,
                OrderStatus: 0,
            };
        case SET_PACK_ORDER_STATUS:
            return {
                ...state,
                packOrderStatus: payload
            }
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (order_id) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));

                const res = await axios.get(`/shop/order/${order_id}/`);


                // {
                //     "id": 44,
                //     "order_id": "K2V30W6HI9ZW",
                //     "customer": {
                //         "id": 5,
                //         "first_name": "Donald",
                //         "last_name": "Isufi"
                //     },
                //     "total_amount": 369.6,
                //     "paid_status": true,
                //     "description": "Here is a message",
                //     "status": "Pranuar",
                //     "created_at": "2021-02-25T08:46:50.822758",
                //     "shipping_address": {
                //         "id": 28,
                //         "name": "Test",
                //         "first_name": "Donald",
                //         "last_name": "Isufi",
                //         "phone_number": "+38345834560",
                //         "address": "Rr.anton arapi",
                //         "city": "Ferizaj"
                //     },
                //     "product": [
                //         {
                //             "product": {
                //                 "product_id": "FWUG76LE",
                //                 "description": "Iphone 12",
                //                 "thumbnail": [
                //                     {
                //                         "thumbnail": "/images/products/iphone2_gvq7fQW.png"
                //                     }
                //                 ]
                //             },
                //             "quantity": 2,
                //             "amount": 462.0,
                //             "price": 231.0
                //         }
                //     ]
                // }

                const product = res.product.map(el => ({
                    ...el,
                    amount: euroFormatter(el.amount),
                    price: euroFormatter(el.price),
                    product_id: el?.product?.product_id,
                    description: el?.product?.description,
                    thumbnail: el?.product?.thumbnail[0]?.thumbnail,
                    isProductFound: false,
                }));


                const response = {
                    ...res,
                    product,
                    discount_total: euroFormatter(0),
                    total_amount: euroFormatter(res.total_amount),
                    customer_fullname: `${res.customer.first_name || res.shipping_address.first_name} ${res.customer.last_name || res.shipping_address.last_name}`,
                    address: `${res.shipping_address.address} ${res.shipping_address.city}`,
                    created_at: getFormattedDate(res.created_at, "dd-MM-yyyy, HH:mm"),
                };

                dispatch(actionCreator(SUCCESS, response))
            } catch (err) {

                dispatch(actionCreator(FAILURE, err?.response?.detail || err.message));

            }
        }
    },

    deleteProductFromOrder: function (product_id) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(DELETE_PRODUCT_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;

                const product = details.product.map(el => ({
                    id: el.id,
                    product: el.product_id,
                    quantity: el.quantity,
                    status: el.product_id === product_id ? "DELETED_PRODUCT" : ''
                }));

                await axios.put(`/shop/order/${details?.order_id}/`, {product});

                const payload = {
                    ...details,
                    product: details.product.map(el => el.product_id === product_id ? {
                        ...el,
                        status: "DELETED_PRODUCT",
                        quantity: 0,
                        amount: euroFormatter(0)
                    } : el),
                }
                toast.successClickableMessage("produkti u fshi me sukses!")
                dispatch(actionCreator(DELETE_PRODUCT_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(DELETE_PRODUCT_FAILURE, err.message))
            }
        }
    },
    addProductsOnOrder: function (addedProducts) {
        return async (dispatch, getState) => {
            try {
                const {details} = getState().app.admin.orders.adminOrderDetails;
                const product = details.product.map(el => ({
                    id: el.id,
                    product: el.product_id,
                    quantity: el.quantity
                })).concat(addedProducts.map(el => ({
                    product: el.product_id,
                    quantity: el.addedQuantity,
                    status: 'ADDED_PRODUCT',
                })));

                await axios.put(`/shop/order/${details?.order_id}/`, {product});
                const payload = {
                    ...details,
                    product: details.product.concat(addedProducts.map(e => ({
                        ...e, quantity: e.addedQuantity, status: 'ADDED_PRODUCT',
                        amount: euroFormatter(e.price * e.addedQuantity),
                        thumbnail: e?.thumbnail[0]?.thumbnail,
                    }))),
                }
                toast.successClickableMessage("produktet u shtuan me sukses!")
                dispatch(actionCreator(DELETE_PRODUCT_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(DELETE_PRODUCT_FAILURE, err.message))
            }
        }
    },
    packOrder: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(PACK_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;


                await axios.put(`/shop/order/${details?.order_id}/`, {status: order_statuses.packed});

                const payload = {
                    ...details,
                    status: order_statuses.packed
                };

                toast.successClickableMessage("Porosia u paketua me sukses!");
                dispatch(actionCreator(PACK_ORDER_SUCCESS, payload));
            } catch (err) {
                toast.error(err?.response?.data?.message);
                dispatch(actionCreator(PACK_ORDER_FAILURE, err.message));
            }
        };
    },
    cancelOrder: function (id) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(CANCEL_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;
                await axios.put(`/shop/order/${id}/`, {status: order_statuses.canceled});

                const payload = {
                    ...details,
                    status: order_statuses.canceled
                };
                dispatch(orderAction.request({
                    "page": 1,
                    "shipping_address": "",
                    "status": "",
                    "start_date": "",
                    "end_date": ""
                }));
                toast.successClickableMessage("Porosia u anulua!");
                dispatch(actionCreator(CANCEL_ORDER_SUCCESS, payload));
            } catch (err) {
                if (err.response.status === 403) {
                    toast.error(err?.response?.data?.non_field_errors[0]);
                    return;
                }
                toast.error(err.message);
                dispatch(actionCreator(CANCEL_ORDER_FAILURE, err.message));
            }
        };
    },
    restoreOrder: function (id) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(RESTORE_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;
                await axios.put(`/shop/order/${id}/`, {status: order_statuses.accepted});

                const payload = {
                    ...details,
                    status: order_statuses.accepted
                };
                toast.successClickableMessage("Porosia u rikthye!");
                dispatch(actionCreator(RESTORE_ORDER_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(RESTORE_ORDER_FAILURE, err.message));
            }
        };
    },
    confirmOrder: function (pickerId) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(PACK_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;

                await axios.put(`/shop/order/${details?.order_id}/`, {
                    status: order_statuses.confirmed,
                    picker: pickerId
                });

                const payload = {
                    ...details,
                    status: order_statuses.confirmed
                };

                toast.successClickableMessage("Porosia u konfirmua me sukses!");
                dispatch(actionCreator(PACK_ORDER_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(PACK_ORDER_FAILURE, err.message));
            }
        };
    },
    sendOrder: function (courierId) {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(PACK_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;

                await axios.put(`/shop/order/${details?.order_id}/`, {
                    status: order_statuses.being_sent,
                    courier: courierId
                });

                const payload = {
                    ...details,
                    status: order_statuses.being_sent
                };

                toast.successClickableMessage("Porosia eshte duke u derguar!");
                dispatch(actionCreator(PACK_ORDER_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(PACK_ORDER_FAILURE, err.message));
            }
        };
    },
    finishOrder: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(PACK_ORDER_REQUEST));
                const {details} = getState().app.admin.orders.adminOrderDetails;

                await axios.put(`/shop/order/${details?.order_id}/`, {status: order_statuses.completed});

                const payload = {
                    ...details,
                    status: order_statuses.completed
                };

                toast.successClickableMessage("Porosia u kompletua me sukses!");
                dispatch(actionCreator(PACK_ORDER_SUCCESS, payload));
            } catch (err) {
                toast.error(err.message);
                dispatch(actionCreator(PACK_ORDER_FAILURE, err.message));
            }
        };
    },
    moveProduct: function (product_id) {
        return (dispatch, getState) => {
            try {
                const {product} = getState().app.admin.orders.adminOrderDetails.details;

                const productPayload = product.map(el => el.product_id === product_id ? {
                    ...el,
                    isProductFound: !el.isProductFound
                } : el);

                dispatch(actionCreator(EDIT_PRODUCT_IN_ORDER, productPayload));
            } catch (err) {
                // console.log({ err });
                // debugger;
            }
        }
    },

    resetDeleteStatus: () => actionCreator(SET_DELETE_STATUS, 0),
    resetPackOrderStatus: () => actionCreator(SET_PACK_ORDER_STATUS, 0)
};
