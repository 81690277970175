import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import { actionTypes as getWishListTypes } from './getWishListThunk';
/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/wish-list/deleteWishListThunk/REQUEST",
    SUCCESS: "@app/user/wish-list/deleteWishListThunk/SUCCESS",
    ERROR: "@app/user/wish-list/deleteWishListThunk/ERROR",

    SET_STATUS: "@app/user/wish-list/deleteWishListThunk/SET_STATUS",
    SET_MODAL_VISIBILITY: "@app/user/wish-list/deleteWishListThunk/SET_MODAL_VISIBILITY",
    SET_SELECTED_ID: "@app/user/wish-list/deleteWishListThunk/SET_SELECTED_ID",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    status: 0,
    isModalVisible: false,
    productIdSelected: -1,
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state) => state.app.user.wishList.deleteWishList.isLoading,
    getErrorMessage: (state) => state.app.user.wishList.deleteWishList.errorMessage,
    getStatus: (state) => state.app.user.wishList.deleteWishList.status,
    getModalVisibility: (state) => state.app.user.wishList.deleteWishList.isModalVisible,
    getProductIdSelected: (state) => state.app.user.wishList.deleteWishList.productIdSelected,
};

/**
 * Reducer
 */
const {
    REQUEST,
    ERROR,
    SUCCESS,
    SET_MODAL_VISIBILITY,
    SET_SELECTED_ID,
    SET_STATUS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_MODAL_VISIBILITY:
            return {
                ...state,
                isModalVisible: payload,
            };
        case SET_SELECTED_ID:
            return {
                ...state,
                productIdSelected: payload,
            };
        case SET_STATUS:
            return {
                ...state,
                status: payload,
            };
        case REQUEST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
                isModalVisible: false,
            };
        case SUCCESS:
            return {
                ...state,
                status: 200,
                isLoading: false,
                isModalVisible: false,
            };
        case ERROR:
            return {
                ...state,
                status: 404,
                errorMessage: payload,
                isLoading: false,
            };

        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    removeProductFromList: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));
                const product_id = getState().app.user.wishList.deleteWishList.productIdSelected;
                const res = await axios.delete(`/customer/wishlist/${product_id}/`);
                const wishListProducts = getState().app.user.wishList.getWishList.list.filter(el => el.product_id !== product_id);
                dispatch(actionCreator(getWishListTypes.SUCCESS, wishListProducts));
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message));
            }
        };
    },
    setModalVisibility: (payload) => actionCreator(SET_MODAL_VISIBILITY, payload),
    setProductIdSelected: (id) => actionCreator(SET_SELECTED_ID, id),
    setStatus: (status) => actionCreator(SET_STATUS, status),
};