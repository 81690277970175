import axios from "../services/axios";

const TRANSPORT_KEY = 'WDAS23-RWQ-WE-123ER23-E23E312'
export const getTransportPrice = (totalPrice = 0) => {
    totalPrice = parseFloat(totalPrice)
    const transport = 1.95
    if (localStorage.getItem(`${TRANSPORT_KEY}_t`) !== String(new Date().getHours())) {
        axios.get(`/customer/product/OQTO0RKEMUDN/`).then(({product_detail}) => {
            localStorage.setItem(TRANSPORT_KEY, product_detail.price)
            localStorage.setItem(`${TRANSPORT_KEY}_t`, new Date().getHours())
        })
    }
    if (totalPrice < 30) {
        return parseFloat(localStorage.getItem(TRANSPORT_KEY)|| transport)
    }
    return 0
}
