import React, { Component, useEffect } from 'react';

const ChatBot = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//eu.fw-cdn.com/10551353/355483.js';
    script.setAttribute('chat', true);
    document.body.appendChild(script);
  }, []);

  return (
    <div className="baba">
      <div id="fw-chat-container"></div>
    </div>
  );
};

// export class ChatBot extends Component {

//   componentDidMount(){

// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/64e8a25ea91e863a5c0fcaec/1h8mbkgqv';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
//   }
//   render() {

//   }
// }

export default ChatBot;
