import actionCreator from "../../../../../utils/action-creator";
import { actionTypes as userAddressesTypes } from "./userAddressesThunk";
import axios from "../../../../../services/axios";
/**
 * Types
 */
export const actionTypes = {
  ADD_NEW_ADDRESS: "@app/user/addresses/useAddNewAddressThunk/ADD_NEW_ADDRESS",
  ADD_NEW_ADDRESS_SUCCESS: "@app/user/addresses/useAddNewAddressThunk/ADD_NEW_ADDRESS_SUCCESS",
  ADD_NEW_ADDRESS_ERROR: "@app/user/addresses/useAddNewAddressThunk/ADD_NEW_ADDRESS_ERROR",
  SET_STATUS: "@app/user/addresses/userAddNewAddressThunk/SET_STATUS",
  SET_MODAL_VISIBILITY: "@app/user/addresses/userAddNewAddressThunk/SET_MODAL_VISIBILITY",
};

/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  errorMessage: "",
  status: 0,
  modalVisibility: false,
  addressCreated: null
};

/**
 * Selectors
 */
export const selectors = {
  getIsLoading: (state) => state.app.user.addresses.userAddNewAddress.isLoading,
  getErrorMessage: (state) => state.app.user.addresses.userAddNewAddress.errorMessage,
  getStatus: (state) => state.app.user.addresses.userAddNewAddress.status,
  getModalVisibility: (state) => state.app.user.addresses.userAddNewAddress.modalVisibility,
  getAddressCreated: state => state.app.user.addresses.userAddNewAddress.addressCreated,
};

/**
 * Reducer
 */
const {
  ADD_NEW_ADDRESS,
  ADD_NEW_ADDRESS_ERROR,
  ADD_NEW_ADDRESS_SUCCESS,
  SET_STATUS,
  SET_MODAL_VISIBILITY,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: payload,
      };
    case ADD_NEW_ADDRESS:
      return {
        ...state,
        isLoading: true,
        modalVisibility: false,
        errorMessage: "",
        addressCreated: null
      };
    case ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addressCreated: payload,
        status: 200,
      };
    case ADD_NEW_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404,
        modalVisibility: true,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  addNewAddress: function ({
    first_name,
    last_name,
    phone_number,
    phone_number_2,
    city,
    name,
    address,
    email,
  }) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(ADD_NEW_ADDRESS));
        const list = [...getState().app.user.addresses.userAddresses.list];
        const response = await axios.post("/customer/addresses/", {
          first_name,
          last_name,
          phone_number,
          phone_number_2,
          city,
          email,
          name,
          address,
        });

        if (response) {
          dispatch(actionCreator(ADD_NEW_ADDRESS_SUCCESS, response));
          dispatch(
            actionCreator(userAddressesTypes.FETCH_USER_ADDRESSES_SUCCESS, [
              ...list,
              response,
            ])
          );
        }
      } catch (err) {
        dispatch(actionCreator(ADD_NEW_ADDRESS_ERROR, err));
      }
    };
  },
  setStatus: (status) => actionCreator(SET_STATUS, status),
  setModalVisibility: (payload) => actionCreator(SET_MODAL_VISIBILITY, payload),
};
