const user_dashboard_data = {
  orders: {
    last_orders: 4,
    delivering_products: 6,
    price: 24,
  },
  offers: {
    offers_number: 6,
    exploited: [
      "20% zbritje",
      "70% zbritje",
      "70% zbritje",
      "70% zbritje",
      "70% zbritje",
    ],
  },
  bonus_card: {
    last_payment_date: "6 Tetor, 2020, 12:40",
    last_payment_price: 23,
    total_bonus: 87.5,
  },
};

export default user_dashboard_data;
