import actionCreator from "../../../../../utils/action-creator";
import { ADMIN_PRODUCTS_PATH } from "../../../../../utils/constants";
import * as toast from "../../../../../components/common/alerts/Toast";
import axios from '../../../../../services/axios';

export const actionTypes = {
  ADD_PRODUCT: "@app/admin/adminAddProductThunk/ADD_PRODUCT",
  ADD_PRODUCT_ERROR: "@app/admin/adminAddProductThunk/ADD_PRODUCT_ERROR",
  ADD_PRODUCT_SUCCESS: "@app/admin/adminAddProductThunk/ADD_PRODUCT_SUCCESS",
  RESET_STATUS: "@app/admin/adminAddProductThunk/RESET_STATUS",

  SET_PRODUCTS_LIST: "@app/admin/adminAddProductThunk/SET_PRODUCTS_LIST",
};
/**
 * Initial state
 */
const initialState = {
  products: [],
  isLoading: false,
  errorMessage: null,
  status: 0,
};

/**
 * selectors
 */
export const selectors = {
  getIsLoadingAddProduct: (state) => state.app.admin.products.adminAddProduct.isLoading,
  getErrorMessageAddProduct: (state) => state.app.admin.products.adminAddProduct.errorMessage,
  getAddProductStatus: (state) => state.app.admin.products.adminAddProduct.status,
};

/**
 * Reducer
 */
const {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  RESET_STATUS,
  ADD_PRODUCT_ERROR,
  SET_PRODUCTS_LIST,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_PRODUCT:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        status: 200,
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404
      };
    case SET_PRODUCTS_LIST:
      return {
        ...state,
        products: payload,
      };
    case RESET_STATUS:
      return {
        ...state,
        status: 0,
      };
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  addNewProduct: function (product) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(ADD_PRODUCT));
        let data = new FormData();

        product?.productImages.forEach(i => {
          data.append('thumbnail', i);
        });
        data.append('category', product.category);
        data.append('description', product.description);
        data.append('price', product.price);
        data.append('quantity', product.quantity);
        product.discount_percentage !== 0 && data.append('discount_percentage', product.discount_percentage);
        data.append('unit', product.unit);
        data.append('subcategory_id', product?.subcategory_id);
        data.append('longDescription', product.longDescription);
        const res = await axios({
          method: 'post',
          url: '/shop/products/',
          data,
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        toast.successClickableMessage(`'${product.description}' u shtua me sukses!`, null, ADMIN_PRODUCTS_PATH);
        dispatch(actionCreator(ADD_PRODUCT_SUCCESS));
      } catch (error) {
        dispatch(actionCreator(ADD_PRODUCT_ERROR, error));
        toast.error(error?.response?.statusText, error.message);
      }
    };
  },
  resetStatus: function () {
    return actionCreator(RESET_STATUS);
  },
};
