import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";

/**
 * Types
 */
export const actionTypes = {
    REQUEST: "@app/user/wish-list/getWishListThunk/REQUEST",
    SUCCESS: "@app/user/wish-list/getWishListThunk/SUCCESS",
    ERROR: "@app/user/wish-list/getWishListThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    list: []
};

/**
 * selectors
 */
export const selectors = {
    getList: state => state.app.user.wishList.getWishList.list,
    getIsLoading: (state) => state.app.user.wishList.getWishList.isLoading,
    getErrorMessage: (state) => state.app.user.wishList.getWishList.errorMessage,
};

/**
 * Reducer
 */
const {
    REQUEST,
    ERROR,
    SUCCESS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
            };
        case SUCCESS:
            return {
                ...state,
                list: payload,
                isLoading: false,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };

        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));

                const response = await axios.get('/customer/wishlist/');

                const list = response[0].wishlist.map(el => ({
                    ...el,
                    thumbnail: addBaseUrlToThumbnails(el.thumbnail)[0]?.thumbnail
                }));

                dispatch(actionCreator(SUCCESS, list));
            } catch (err) {
                dispatch(actionCreator(ERROR, err.message))
            }
        };
    }
};