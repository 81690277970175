import * as toast from "../../../../components/common/alerts/Toast";
import actionCreator from "../../../../utils/action-creator";
import timeout from '../../../../utils/async-timeout';
import axios from "../../../../services/axios";
import {payment_methods} from "../../../../utils/constants";

/**
 * Types
 */
export const actionTypes = {
    REQUEST_PAYMENT: '@app/user/cartPaymentThunk/REQUEST_PAYMENT',
    REQUEST_PAYMENT_SUCCESS: '@app/user/cartPaymentThunk/REQUEST_PAYMENT_SUCCESS',
    REQUEST_PAYMENT_ERROR: '@app/user/cartPaymentThunk/REQUEST_PAYMENT_ERROR',
    SET_PAYMENT_STATUS: '@app/user/cartPaymentThunk/SET_PAYMENT_STATUS'
}
/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    status: 0
};

/**
 * Selectors
 */
export const selectors = {
    getIsLoading: state => state.app.user.cartPayment.isLoading,
    getErrorMessage: state => state.app.user.cartPayment.errorMessage,
    getPaymentStatus: state => state.app.user.cartPayment.status
};

/**
 * Reducer
 */
const {REQUEST_PAYMENT, REQUEST_PAYMENT_ERROR, REQUEST_PAYMENT_SUCCESS, SET_PAYMENT_STATUS} = actionTypes;

const Reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case REQUEST_PAYMENT:
            return {
                ...state,
                isLoading: true,
                errorMessage: ''
            };
        case REQUEST_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                status: 200,
            };
        case REQUEST_PAYMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
                status: 404,
            };
        case SET_PAYMENT_STATUS:
            return {
                ...state,
                status: payload
            }
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    requestPayment: function (payload) {
        return async dispatch => {
            try {
                dispatch(actionCreator(REQUEST_PAYMENT));
                const res = await axios.post('/customer/orders/', {...payload,payment_type:payload.payment_method.toUpperCase()});
                dispatch(actionCreator(REQUEST_PAYMENT_SUCCESS));
                if (payload.payment_method === payment_methods.online) {
                    const request = await axios.get(`/shop/online-payment/${res?.order_id}/`)
                    try {
                        if (request?.TKKPG?.Response?.Order) {
                            const {OrderID, SessionID, URL} = request?.TKKPG?.Response?.Order
                            window.location.replace(`${URL}?ORDERID=${OrderID}&SESSIONID=${SessionID}&RPAN=&Send=Submit`);
                        } else {
                            toast.error("Pagesa online nuk mundet te realizohet momentalisht.")
                        }
                    } catch (e) {
                        toast.error("Pagesa online nuk mundet te realizohet momentalisht.")
                    }
                }
            } catch (err) {
                dispatch(actionCreator(REQUEST_PAYMENT_ERROR, err.message));
            }
        };
    },
    resetStatus: () => actionCreator(SET_PAYMENT_STATUS, 0)
};
