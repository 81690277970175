import { Route, Switch } from "react-router-dom";
import { lazy } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import {
    user_routes,
    USER_ADDRESSES_PATH,
    USER_BASE_PATH,
    USER_DASHBOARD_PATH,
    USER_ORDERS_PATH,
    USER_PAYMENTS_PATH,
    USER_WISH_LIST_PATH,
    USER_SETTINGS_PATH,
    USER_ORDERS_PATH_URL
} from "../utils/constants";

import UserProtectedRoute from "../utils/UserProtectedRoute";
import UserLayout from "../layouts/UserLayout";

const UserAddresses = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/UserAddresses"));
const UserDashboard = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/UserDashboard"));
const UserOrders = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/UserOrders"));
const UserPayments = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/UserPayments"));
const UserWishList = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/UserWishList"));
const UserSettings = lazy(() => import(/* webpackChunkName:"user" */"./../pages/user-pages/protected/Settings"));

function UserRoutes() {
    return (
        <Route
            exact
            path={user_routes}
            render={(props) => {
                const { location } = props;

                return (
                    <UserLayout>
                        <TransitionGroup className="transition-group">
                            <CSSTransition
                                key={location.key}
                                timeout={{ enter: 300, exit: 300 }}
                                classNames={"fade"}
                            >
                                <section className="route-section">
                                    <Switch location={location}>
                                        <UserProtectedRoute
                                            exact
                                            path={USER_ADDRESSES_PATH}
                                            component={UserAddresses}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={[USER_BASE_PATH, USER_DASHBOARD_PATH]}
                                            component={UserDashboard}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={USER_ORDERS_PATH_URL}
                                            component={UserOrders}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={USER_PAYMENTS_PATH}
                                            component={UserPayments}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={USER_WISH_LIST_PATH}
                                            component={UserWishList}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={USER_SETTINGS_PATH}
                                            component={UserSettings}
                                        />
                                    </Switch>
                                </section>
                            </CSSTransition>
                        </TransitionGroup>
                    </UserLayout>
                );
            }}
        />
    )
};

export default UserRoutes;
