import actionCreator from "../../../utils/action-creator";
import { auth } from "../../../services/auth";
import { actionTypes as loginTypes } from "./loginThunk";
import axios from "../../../services/axios";
import { getRoles } from "@testing-library/react";
import { getRole } from "../../../utils/utility-functions";
import * as toast from '../../../components/common/alerts/Toast';
/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@auth/authenticatedUser/REQUEST",
  SUCCESS: "@auth/authenticatedUser/SUCCESS",
  ERROR: "@auth/authenticatedUser/ERROR",
  SET_USER: "@auth/authenticatedUser/SET_USER",
  SET_ROLE: "@auth/authenticatedUser/SET_ROLE",
  RESET: "@auth/authenticatedUser/RESET",
  RESET_REDUX: "@auth/authenticatedUser/RESET_REDUX",
};
/**
 * Initial state
 */
const initialState = {
  errorMessage: '',
  isLoading: false,
  role: '',
  user: {}
};

/**
 * selectors
 */
export const selectors = {
  getErrorMessage: (state) => state.auth.authenticatedUser.errorMessage,
  getIsLoading: state => state.auth.authenticatedUser.isLoading,
  getRole: (state) => state.auth.authenticatedUser.role,
  getUser: state => state.auth.authenticatedUser.user,
  getUserName: state => {
    const user = state.auth.authenticatedUser.user;
    if (user.first_name && user.last_name) {
      return user.first_name + " " + user.last_name
    }

    if (state.auth.authenticatedUser.role === "admin") {
      return "admin"
    }
    if (state.auth.authenticatedUser.role === "picker") {
      return "picker"
    }
    if (state.auth.authenticatedUser.role === "courier") {
      return "courier"
    }
    return "";
  }
};

/**
 * Reducer
 */
const {
  REQUEST,
  SUCCESS,
  ERROR,
  SET_ROLE,
  SET_USER,
  RESET,
  RESET_REDUX,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ROLE:
      return {
        ...state,
        role: payload,
        isLoading: false
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
        isLoading: false
      };
    case REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS:
      return {
        ...state,
        role: payload.role,
        user: payload.user,
        isLoading: false
      };
    case ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false
      }
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
  request: function () {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(REQUEST));
        const response = await axios.get('/auth/me/');
        const role = getRole(response?.group);

        dispatch(actionCreator(SUCCESS, { user: response.user[0], role }));
      }
      catch (err) {
        dispatch(actionCreator(ERROR, err.message));
        toast.error(err.message);
      }
    };
  },
  setUser: user => actionCreator(SET_USER, user),
  setRole: role => actionCreator(SET_ROLE, role),
  reset: () => actionCreator(RESET),
  resetAllRedux: () => actionCreator(RESET_REDUX)
};
