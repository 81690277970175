import * as yup from "yup";
import card_validator from "card-validator";


// const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
/**
 * regex
 */
// const phoneRegex = /^[\+,\d]?[(]?[0-9]{2,4}[)]?[-\s\.]?[0-9]{2,4}[-\s\.]?[0-9]{3,10}$/im;
const phoneRegex = /^[\+]?[0-9]{2,5}[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{0,3}$/im;
const barcodeRegex = /^[0-9]*(?:\.\d{1,2})?$/;
const fullnameRegex = /^[a-z ,.'-]+$/i;

/**
 * yups
 */
const password = yup
    .string()
    .trim()
    .min(4, "Passwordi duhet te permbaje se paku 6 karaktere!")
    .max(50, "Passwordi nuk mund te kete me shum se 50 karaktere!")
    .required();
const name = yup
    .string()
    .trim()
    .min(2, "emri duhet te permbaje se paku 2 karaktere!")
    .max(40, "emri nuk mund te ket me shume se 40 karaktere")
    .required();
const fullName = yup
    .string()
    .trim()
    .matches(/^[a-z ,.'-]+$/i, "Emri nuk eshte korrekt")
    .min(3)
    .typeError("shkruaj se paku 3 karaktere!")
    .required();
const lastName = yup
    .string()
    .trim()
    .min(2, "Mbiemri duhet te permbaje se paku 2 karaktere")
    .max(50, "Mbiemri nuk mund te ket me shume se 50 karaktere")
    .required();
const phoneNumber = yup
    .string()
    .trim()
    .matches(phoneRegex, "Nurmi nuk eshte valid")
    .min(9, "Shkruaj se paku 9 karaktere!");
const email = yup.string().email("Email-i nuk eshte korrekt!").required();

export const RegisterSchema = yup.object().shape({
    firstName: yup.string().required("plotesoni emrin"),
    lastName: yup.string().required("plotesoni mbiemrin"),
    password: password.min(8, "se paku 8 karaktere!"),
    confirmPassword: password.oneOf(
        [yup.ref("password"), null],
        "Fjalekalimi nuk perputhet!"
    ),
    email,
    phoneNumber: phoneNumber.required(),
});

export const LoginSchema = yup.object().shape({
    name: name.email("Emaili duhet te jete valid!"),
    password,
});

export const AddPaymentsSchema = yup.object().shape({
    fullName,
    creditCardNumber: yup
        .string()
        .test(
            "test-number",
            "Shkruaj nje numer valid,psh: 4111 1111 1111 1111",
            (value) => card_validator.number(value).isValid
        )
        .required(),
    monthExpiration: yup
        .string()
        .test(
            "test-month",
            "muaji nuk eshte valid, psh: 07",
            (value) => card_validator.expirationMonth(value).isValid
        )
        .required(),
    yearExpiration: yup
        .string()
        .test(
            "test-year",
            "Viti nuk eshte valid!",
            (value) => card_validator.expirationYear(value).isValid
        )
        .required(),
    CVC: yup
        .string()
        .test(
            "test-cvc",
            "CVC nuk eshte valide!",
            (value) => card_validator.cvv(value).isValid
        )
        .required(),
    amount: yup.number().typeError("Shkruaj nje shume valide!").required(),
});

export const AddNewProductSchema = yup.object().shape({
    barcode: yup
        .string()
        .matches(barcodeRegex, "barkodi nuk eshte korrekt")
        .required(),
    description: yup
        .string()
        .trim()
        .min(2, "emri duhet te permbaje se paku 2 karaktere!")
        .required(),
    price: yup.number().typeError("Cmimi nuk eshte valid!").required(),
    longDescription: yup.string().trim().required(),
});

export const PaymentSchema = yup.object().shape({
    first_name: name,
    last_name: lastName,
    phone_number: phoneNumber.typeError("Numri nuk eshte korrekt!"),
    phone_number_2: yup.string().optional(),
    address: yup.string().trim(),
    city: yup.string().required("Zgjidhni qytetin"),
    name: yup.string(),
});
// /^ [\+] ? [(] ? [0-9]{3} [)]? [-\s\.] ? [0-9]{3} [-\s\.] ? [0-9]{4,6} $/im
export const AddNewAddressSchema = yup.object().shape({
    first_name: name,
    last_name: name,
    phone_number: phoneNumber.required(),
    phone_number_2: yup.string().optional(),
    address: yup.string().required().typeError("Address is required!"),
    city: yup.string().required().typeError("City is required!"),
    name: yup
        .string()
        .min(3, "Shkruaj se paku 3 karaktere!")
        .max(100, "Adresa nuk mund te kete me shume se 100 karaktere!")
        .required()
        .typeError("Address Name is required"),
});

export const ContactFormSchema = yup.object().shape({
    fullName: fullName.trim().required('Ju lutemi plotësoni emrin tuaj të plotë'),
    email: email.required('Ju lutemi shkruani një email të vlefshëm'),
    subject: yup.string().trim().required('Ju lutemi shkruani një subjekt!').typeError("shkruani nje subjekt!"),
    phoneNumber: yup.string().trim().label('Numri Telefonit').matches(phoneRegex, "Numri nuk eshte valid").min(9, "Shkruaj se paku 9 karaktere!").required("Ju lutemi shkruani një numer telefoni të vlefshëm"),
    message: yup
        .string()
        .trim()
        .required('Ju lutemi shkruani një mesazh')
        .typeError("ju lutem na lini ne mesazh"),
});

export const AddNewCategorySchema = yup.object().shape({
    category: yup.string().required('Shkruani emrin e kategorise!'),
    description: yup.string().required('Shkruani pershkrimin e kategorise!'),
});

export const ResetPasswordSchema = yup.object().shape({
    password: password.min(8, "se paku 8 karaktere!"),
    confirmPassword: password.oneOf(
        [yup.ref("password"), null],
        "Fjalekalimi nuk perputhet!"
    ),
})
