import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from '../../../../../components/common/alerts/Toast';
import { actionTypes as adminBannerTypes } from './adminBannerThunk';

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/banner/adminDeleteBanner/REQUEST",
    SUCCESS: "@app/admin/banner/adminDeleteBanner/SUCCESS",
    ERROR: "@app/admin/banner/adminDeleteBanner/ERROR",

    TOGGLE_DELETE_MODAL: "@app/admin/banner/adminDeleteBanner/TOGGLE_DELETE_MODAL",
    SET_BANNER_TO_DELETE: "@app/admin/banner/adminDeleteBanner/SET_BANNER_TO_DELETE",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    isModalVisible: false,
    bannersSelected: {},
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.banner.adminDeleteBanner.isLoading,
    getErrorMessage: state => state.app.admin.banner.adminDeleteBanner.errorMessage,
    getBannerSelected: state => state.app.admin.banner.adminDeleteBanner.bannersSelected,
    getModalVisibility: state => state.app.admin.banner.adminDeleteBanner.isModalVisible
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS, TOGGLE_DELETE_MODAL, SET_BANNER_TO_DELETE } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_DELETE_MODAL:
            return {
                ...state,
                isModalVisible: payload
            };
        case SET_BANNER_TO_DELETE:
            return {
                ...state,
                bannersSelected: payload
            }
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                isModalVisible: false,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                bannersSelected: {}
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));
                const { bannersSelected } = getState().app.admin.banner.adminDeleteBanner;
                const { images } = getState().app.admin.banner.adminBanner;
                await axios.delete(`/shop/banner/${bannersSelected.banner_id}/`);
                toast.successClickableMessage(`Baneri : "${bannersSelected.description}" u fshi me sukses!`);
                let results = images.filter(el => el.id !== bannersSelected?.banner_id);
                dispatch(actionCreator(adminBannerTypes.SUCCESS, { response: results }));
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                toast.error(err?.response?.data || err.message);
                dispatch(actionCreator(ERROR, err.response.data.detail));
            };
        }
    },
    toggleModal: (payload) => actionCreator(TOGGLE_DELETE_MODAL, payload),
    setBannerToDelete: (banner) => actionCreator(SET_BANNER_TO_DELETE, banner),
};

