import actionCreator from "../../../../../utils/action-creator";
import { addBaseUrlToThumbnails } from "../../../../../utils/utility-functions";
import axios from "../../../../../services/axios";

/**
 * Types
 */
export const actionTypes = {
  REQUEST: "@app/user/products/userProductDetailsThunk/REQUEST",
  SUCCESS: "@app/user/products/userProductDetailsThunk/SUCCESS",
  ERROR: "@app/user/products/userProductDetailsThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
  data: {
    product_detail: {},
    latest_products: [],
    similar_products: [],
  },
  isLoading: false,
  errorMessage: null,
  status: 0
};

/**
 * Selectors
 */
export const selectors = {
  getStatus: state => state.app.user.products.userProductDetails.status,
  getData: state => state.app.user.products.userProductDetails.data,
  getErrorMessage: state => state.app.user.products.userProductDetails.errorMessage,
  getIsLoading: state => state.app.user.products.userProductDetails.isLoading,
};

/**
 * Reducer
 */
const {
  REQUEST,
  SUCCESS,
  ERROR,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case SUCCESS:
      return {
        ...state,
        data: payload,
        errorMessage: null,
        isLoading: false,
        status: 200
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        status: 404,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  request: function (id) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(REQUEST));

        const response = await axios.get(`/customer/product/${id}/`);
        const { product_detail, latest_products, similar_products } = response;

        const payload = {
          product_detail: {
            ...product_detail,
            thumbnail: addBaseUrlToThumbnails(product_detail.thumbnail)
          },
          latest_products: latest_products.map(x => ({
            ...x,
            thumbnail: addBaseUrlToThumbnails(x.thumbnail)
          })),
          similar_products: similar_products.map(x => ({
            ...x,
            thumbnail: addBaseUrlToThumbnails(x.thumbnail)
          }))
        };

        dispatch(actionCreator(SUCCESS, payload));
      } catch (err) {
        dispatch(actionCreator(ERROR, err));
      }
    };
  },
  likeProductById: function (id) {
    return async (dispatch, getState) => {
      try {
        // const p = getState().app.user.products.productDetails.item;
        // dispatch(allProductsActions.likeProduct(id));
        // dispatch(fetchProductByIdSuccess({ ...p, isLiked: !p.isLiked }));
      } catch (err) {
        // console.log("Error like products", err);
      }
    };
  },
};