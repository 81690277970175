import "./style.scss";
import loader from "../../assets/images/loader_gif.gif";
import { Fade } from "react-awesome-reveal";

function Loading(props) {
  return (
    <Fade>
      <div className="loadingPage" style={{ height: window.innerHeight }}>
        <img alt="loader" src={loader} />
      </div>
    </Fade>
  );
}

export default Loading;
