import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
const { REACT_APP_WEB_API_URL } = process.env;

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/categories/adminCategoriesThunk/REQUEST",
    SUCCESS: "@app/admin/categories/adminCategoriesThunk/SUCCESS",
    ERROR: "@app/admin/categories/adminCategoriesThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    data: {
        count: 0,
        results: [],
        links: {
            next: null,
            previous: null
        },
        total_pages: 0
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.categories.adminCategories.isLoading,
    getErrorMessage: state => state.app.admin.categories.adminCategories.errorMessage,
    getData: state => state.app.admin.categories.adminCategories.data,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function (query) {
        return async (dispatch, getState) => {
            try {
                if (query?.page === 1) {
                    dispatch(actionCreator(REQUEST));
                }

                const response = await axios.get(`/shop/categories/?${new URLSearchParams(query)}`);

                if (query?.page > 1) {
                    const { results } = getState().app.admin.categories.adminCategories.data;
                    dispatch(actionCreator(SUCCESS, {
                        ...response,
                        results: [
                            ...results,
                            ...response.results.map(x => ({
                                ...x,
                                thumbnail: REACT_APP_WEB_API_URL + x.thumbnail
                            }))
                        ]
                    }));
                    return;
                }

                dispatch(actionCreator(SUCCESS, {
                    ...response,
                    results: response.results.map(x => ({
                        ...x,
                        thumbnail: REACT_APP_WEB_API_URL + x.thumbnail
                    })),
                }));

            } catch (err) {
                dispatch(actionCreator(ERROR, err?.response?.data?.detail || err.message));
            }
        }
    },
    resetStatus: () => actionCreator(RESET_STATUS)
};

