import Axios from "axios";
import { TOKEN_NAME } from "../utils/constants";
import { auth } from "./auth";
const { REACT_APP_WEB_API_URL } = process.env;

const axios = Axios.create({
  baseURL: REACT_APP_WEB_API_URL,
});

axios.defaults.baseURL = REACT_APP_WEB_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";


// Request interceptor for API calls
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token && auth.getDecodedJWT()) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
    }
    return config;
  },
  async (error) => {
    if (error.response && error.response.status === 400) {
    } else if (error.response && error.response.status === 401) {
      // Unauthorized Request
    } else if (error.response && error.response.status === 403) {
      // Forbiden Request
      // store.dispatch(forbidenRequest());
    } else if (error.response && error.response.status === 404) {
      // route not found
      // store.dispatch(erroredRequest());

    }
    Promise.reject(error.response.data);
  },
);
axios.interceptors.response.use(
  async (response) => response.data,
  async function (error) {

    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      auth.removeToken();
      originalRequest._retry = true;
      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      // return axiosApiInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axios;
