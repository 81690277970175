import "./style.scss";
/**
 * libraries
 */
import { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
/**
 * constants - data
 */
import {
  //   USER_PAYMENTS_PATH,
  USER_DASHBOARD_PATH,
  USER_ORDERS_PATH,
  USER_WISH_LIST_PATH,
  USER_ADDRESSES_PATH,
  USER_BASE_PATH,
  USER_SETTINGS_PATH,
} from "../../../utils/constants";

const UserDashboardNavigator = (props) => {
  const [linkHovered, setLinkHovered] = useState(null);

  const currentPath = props.match.path;

  const checkIsLinkActive = (activeArray = []) => {
    let isActive = false;
    activeArray.forEach((i) => {
      if (i === currentPath) {
        isActive = true;
      }
    });
    return isActive;
  };

  const dashboardLinks = [
    {
      to: USER_DASHBOARD_PATH,
      text: "Pasqyra",
      activePaths: [USER_DASHBOARD_PATH, USER_BASE_PATH],
    },
    {
      to: USER_ORDERS_PATH,
      text: "Porosite",
      activePaths: [USER_ORDERS_PATH],
    },
    // {
    //     to: USER_PAYMENTS_PATH,
    //     text: "Pagesat",
    //     activePaths: [USER_PAYMENTS_PATH]
    // },
    {
      to: USER_WISH_LIST_PATH,
      text: "Lista e deshirave",
      activePaths: [USER_WISH_LIST_PATH],
    },
    {
      to: USER_ADDRESSES_PATH,
      text: "Adresat",
      activePaths: [USER_ADDRESSES_PATH],
    },
    {
      to: USER_SETTINGS_PATH,
      text: "Preferencat",
      activePaths: [USER_SETTINGS_PATH],
    },
  ];

  const renderLinks = () => {
    return dashboardLinks.map(({ text, to, activePaths }, i) => {
      const isActive = checkIsLinkActive(activePaths);
      return (
        <div className=" m-right-32 userDashboardNavigator__linkContainers__linkDiv">
          <NavLink
            onMouseEnter={() => setLinkHovered(to)}
            onMouseLeave={() => setLinkHovered(null)}
            key={i}
            to={to}
            className={`userDashboardNavigator__linkContainers__link ${
              isActive && "userDashboardNavigator__linkContainers__link--active"
              }`}
          >
            {text}
          </NavLink>

          {isActive || linkHovered === to ? (
            <Zoom>
              <div className="userDashboardNavigator__linkContainers__line userDashboardNavigator__linkContainers__line--active" />
            </Zoom>
          ) : (
              <div className={`userDashboardNavigator__linkContainers__line`} />
            )}
        </div>
      );
    });
  };
  return (
    <div className="userDashboardNavigator">
      <ul className="userDashboardNavigator__linkContainers">
        {renderLinks()}
      </ul>
    </div>
  );
};

export default withRouter(UserDashboardNavigator);
