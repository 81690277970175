import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from '../../../../../components/common/alerts/Toast';
import { actionTypes as adminCategoryTypes } from './adminCategoriesThunk';

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/categories/adminDeleteCategory/REQUEST",
    SUCCESS: "@app/admin/categories/adminDeleteCategory/SUCCESS",
    ERROR: "@app/admin/categories/adminDeleteCategory/ERROR",

    TOGGLE_DELETE_MODAL: "@app/admin/categories/adminDeleteCategory/TOGGLE_DELETE_MODAL",
    SET_CATEGORY_TO_DELETE: "@app/admin/categories/adminDeleteCategory/SET_CATEGORY_TO_DELETE",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    isModalVisible: false,
    categorySelected: {},
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.categories.adminDeleteCategory.isLoading,
    getErrorMessage: state => state.app.admin.categories.adminDeleteCategory.errorMessage,
    getCategorySelected: state => state.app.admin.categories.adminDeleteCategory.categorySelected,
    getModalVisibility: state => state.app.admin.categories.adminDeleteCategory.isModalVisible
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS, TOGGLE_DELETE_MODAL, SET_CATEGORY_TO_DELETE } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_DELETE_MODAL:
            return {
                ...state,
                isModalVisible: payload
            };
        case SET_CATEGORY_TO_DELETE:
            return {
                ...state,
                categorySelected: payload
            }
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                isModalVisible: false,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                categorySelected: {}
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));
                const { categorySelected } = getState().app.admin.categories.adminDeleteCategory;
                const { data } = getState().app.admin.categories.adminCategories;

                const res = await axios.delete(`/shop/category/${categorySelected.id}/`);

                toast.successClickableMessage(`Kategoria : "${categorySelected.description}" u fshi me sukses!`);
                let results = data.results.filter(el => el.id !== categorySelected?.id);
                dispatch(actionCreator(adminCategoryTypes.SUCCESS, { ...data, results }));
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                toast.error(err?.response?.data || err.message);
                dispatch(actionCreator(ERROR, err.response.data.detail));
            };
        }
    },
    toggleModal: (payload) => actionCreator(TOGGLE_DELETE_MODAL, payload),
    setCategoryToDelete: (category) => actionCreator(SET_CATEGORY_TO_DELETE, category),
};

