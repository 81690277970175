
/**
 * 
 * @param {double} price 
 * @param {int} fixed => how much 0 to be added after price 
 * 
 * returns => a formatted number with € symbol ex : 20.00€
*/
export const euroFormatter = (price, fixed = 2) => {
  return Number(price).toFixed(fixed) + "€";
};