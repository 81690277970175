import "./style.scss";
/**
 * icons
 */
import { GrFormClose } from "react-icons/gr";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {addBaseUrlToThumbnails} from "../../../utils/utility-functions";

/**
 * components
 */
import PlusMinusQuantity from "../../common/PlusMinusQuantity";

/**
 * redux
 */
import { useDispatch } from "react-redux";
import { actions as deleteCartActions } from "../../../redux/thunk/app/user/cart/userDeleteCartThunk";
import { actions as addToCartActions } from "../../../redux/thunk/app/user/cart/userAddToCartThunk";
import ProductCard from "../../common/ProductCard";

// "id":142,
// "category":"E re",
// "product_id":"DH81IAIM",
// "description":"Coca cola 2L - 10% zbritje",
// "price":1.25,
// "quantity":1,
// "created_at":"2021-02-03T12:43:11.040419Z",
// "available_status":true,
// "rating":0,
// "discount_percentage":5,
// "thumbnail":[
// {
// "thumbnail":"https://development.tframe.de/images/products/cocacola-2l.jpg"
// }
// ],
// "unit":"COPE",
// "longDescription":"asdasdasd",
// "tags":[
// ],
// "quantityInCart":1

const ProductInCart = ({ id, product_id, thumbnail, description, price, quantity, quantityInCart, style }) => {
  const dispatch = useDispatch();

  const incrementQuantity = (e) => {
    dispatch(addToCartActions.changeQuantity({ product_id, quantity, description }, quantityInCart + 1));

  }
  const decrementQuantity = (e) => {
    if (quantityInCart <= 1) {
      return;
    }
    dispatch(addToCartActions.changeQuantity({ product_id, quantity, description }, quantityInCart - 1));
  }

  const onChangeQuantity = (quantity1) => {
    if (quantity1 < 1) {
      return;
    }
    dispatch(addToCartActions.changeQuantity({ product_id, quantity, description }, quantity1));
  }

  const onDelete = () => {
    dispatch(deleteCartActions.request(product_id));
  }

  return (
    <ProductCard
        style={style}
        thumbnail={thumbnail}
        description={description}
        decrementQuantity={decrementQuantity}
        incrementQuantity={incrementQuantity}
        onChangeQuantity={onChangeQuantity}
        quantityInCart={quantityInCart}
        onDelete={onDelete}
    />
  );
};

export default ProductInCart;
