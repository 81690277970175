import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";
/**
 * Types
 */
export const actionTypes = {
  FETCH_USER_ADDRESSES: "@app/user/addresses/userAddressesThunk/FETCH_USER_ADDRESSES",
  FETCH_USER_ADDRESSES_SUCCESS: "@app/user/addresses/userAddressesThunk/FETCH_USER_ADDRESSES_SUCCESS",
  FETCH_USER_ADDRESSES_ERROR: "@app/user/addresses/userAddressesThunk/FETCH_USER_ADDRESSES_ERROR",
};
/**
 * Initial state
 */
const initialState = {
  isLoading: false,
  list: [],
  errorMessage: "",
};

/**
 * Selectors
 */
export const selectors = {
  getIsLoading: (state) => state.app.user.addresses.userAddresses.isLoading,
  getErrorMessage: (state) => state.app.user.addresses.userAddresses.errorMessage,
  getAddressList: (state) => state.app.user.addresses.userAddresses.list,
};

/**
 * Reducer
 */
const {
  FETCH_USER_ADDRESSES,
  FETCH_USER_ADDRESSES_ERROR,
  FETCH_USER_ADDRESSES_SUCCESS,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_ADDRESSES:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case FETCH_USER_ADDRESSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      };
    case FETCH_USER_ADDRESSES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
  fetchUserAddresses: function () {
    return async (dispatch) => {
      try {
        dispatch(actionCreator(FETCH_USER_ADDRESSES));
        const addresses = await axios.get("/customer/addresses/");
        dispatch(actionCreator(FETCH_USER_ADDRESSES_SUCCESS, [...addresses]));
      } catch (err) {
        dispatch(actionCreator(FETCH_USER_ADDRESSES_ERROR, err));
      }
    };
  },
};
