import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import * as toast from '../../../../../components/common/alerts/Toast';
import { actionTypes as adminProductsTypes } from './adminProductsThunk';

/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/products/adminDeleteProduct/REQUEST",
    SUCCESS: "@app/admin/products/adminDeleteProduct/SUCCESS",
    ERROR: "@app/admin/products/adminDeleteProduct/ERROR",

    TOGGLE_DELETE_MODAL: "@app/admin/products/adminDeleteProduct/TOGGLE_DELETE_MODAL",
    SET_PRODUCT_TO_DELETE: "@app/admin/products/adminDeleteProduct/SET_PRODUCT_TO_DELETE",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: "",
    isModalVisible: false,
    productSelected: {},
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.products.adminDeleteProduct.isLoading,
    getErrorMessage: state => state.app.admin.products.adminDeleteProduct.errorMessage,
    getProductSelected: state => state.app.admin.products.adminDeleteProduct.productSelected,
    getModalVisibility: state => state.app.admin.products.adminDeleteProduct.isModalVisible
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS, TOGGLE_DELETE_MODAL, SET_PRODUCT_TO_DELETE } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_DELETE_MODAL:
            return {
                ...state,
                isModalVisible: payload
            };
        case SET_PRODUCT_TO_DELETE:
            return {
                ...state,
                productSelected: payload
            }
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                isModalVisible: false,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                productSelected: {}
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch, getState) => {
            try {
                dispatch(actionCreator(REQUEST));
                const { productSelected } = getState().app.admin.products.adminDeleteProduct;
                const { data } = getState().app.admin.products.adminProducts;

                const res = await axios.delete(`/shop/product/${productSelected.product_id}/`);

                toast.successClickableMessage(`Produkti : "${productSelected.description}" u fshi me sukses!`);
                let results = data.results.filter(el => el.product_id !== productSelected?.product_id);
                dispatch(actionCreator(adminProductsTypes.SUCCESS, { ...data, results }));
                dispatch(actionCreator(SUCCESS));
            } catch (err) {
                toast.error(err?.response?.data || err.message);
                dispatch(actionCreator(ERROR, err.response.data.detail));
            };
        }
    },
    toggleModal: (payload) => actionCreator(TOGGLE_DELETE_MODAL, payload),
    setProductToDelete: (product) => actionCreator(SET_PRODUCT_TO_DELETE, product),
};

