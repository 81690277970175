import actionCreator from "../../../../../utils/action-creator";
import axios from '../../../../../services/axios';
import { format } from 'date-fns';
import { euroFormatter } from "../../../../../utils/number-formatters";


/**
 * action types
 */
export const actionTypes = {
    REQUEST: "@app/admin/customers/adminCustomersThunk/REQUEST",
    SUCCESS: "@app/admin/customers/adminCustomersThunk/SUCCESS",
    ERROR: "@app/admin/customers/adminCustomersThunk/ERROR",
};

/**
 * Initial state
 */
const initialState = {
    isLoading: false,
    errorMessage: '',
    data: {
        count: 0,
        results: [],
        links: {
            next: null,
            previous: null
        },
        total_pages: 0
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: state => state.app.admin.customers.adminCustomers.isLoading,
    getErrorMessage: state => state.app.admin.customers.adminCustomers.errorMessage,
    getData: state => state.app.admin.customers.adminCustomers.data,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, ERROR, RESET_STATUS } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload,
            };
        default:
            return state;
    }
};
export default Reducer;
/**
 * Actions
 */
export const actions = {
    request: function (query) {
        return async (dispatch, getState) => {
            try {
                if (query?.page === 1) {
                    dispatch(actionCreator(REQUEST));
                }

                const res = await axios.get(`/shop/customers/?${new URLSearchParams(query)}`);
                const response = {
                    ...res,
                    results: res.results.map(el => ({
                        ...el,
                        date_joined: format(new Date(el.date_joined), "PP - HH:mm"),
                        amount: euroFormatter(el.amount)
                    }))
                }

                if (query?.page > 1) {
                    const { results } = getState().app.admin.customers.adminCustomers.data;
                    dispatch(actionCreator(SUCCESS, {
                        ...response,
                        results: [
                            ...results,
                            ...response.results
                        ]
                    }));
                    return;
                }

                dispatch(actionCreator(SUCCESS, response));
            } catch (err) {
                dispatch(actionCreator(ERROR, err?.response?.data?.detail || err.message));
            };
        }
    },
    resetStatus: () => actionCreator(RESET_STATUS)
};

