import actionCreator from "../../../../../utils/action-creator";
import axios from "../../../../../services/axios";

const ADMIN_START_GET_STAFF_DETAILS =
  "@admin/staff/ADMIN_START_GET_STAFF_DETAILS";
const ADMIN_ERROR_GET_STAFF_DETAILS =
  "@admin/staff/ADMIN_ERROR_GET_STAFF_DETAILS";
const ADMIN_SUCCESS_GET_STAFF_DETAILS =
  "@admin/staff/ADMIN_SUCCESS_GET_STAFF_DETAILS";

const initialState = {
  loading: false,
  error: null,
  status: null,
  staffDetials: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_GET_STAFF_DETAILS:
      return {
        loading: true,
        error: null,
        status: null,
        staffDetials: null,
      };
    case ADMIN_ERROR_GET_STAFF_DETAILS:
      return {
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ADMIN_SUCCESS_GET_STAFF_DETAILS:
      return {
        loading: false,
        error: null,
        status: 200,
        staffDetials: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export const getStaffDetailsState = (state) =>
  state.app.admin.staff.getAdminStaffDetails;

export const getStaffDetails = (id) => {
  return async (dispatch) => {
    dispatch(actionCreator(ADMIN_START_GET_STAFF_DETAILS));

    await axios
      .get(`/shop/staff-user/${id}/`)
      .then((res) => {
        dispatch(actionCreator(ADMIN_SUCCESS_GET_STAFF_DETAILS, res));
      })
      .catch((err) => {
        dispatch(
          actionCreator(ADMIN_ERROR_GET_STAFF_DETAILS, {
            status: 404,
            error: "could not get staff detials",
          })
        );
      });
  };
};
