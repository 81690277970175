import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}


/**
 * @param {IntersectionObserver's options} options 
 * 
 * returns => 
 *      1. ref - a ref object that should pass to an element,
 *      2. isVisible - a state that changes when element is visible on viewport
 */
export function useOnScreen(options) {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallBack, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  const observerCallBack = ([entry]) => {
    setIsVisible(entry.isIntersecting);
  };

  return [ref, isVisible];
}
